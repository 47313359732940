const filter = {
  data() {
    return {
      value: {
        module: {},
        learnline: {}
      },
      more: false,
    }
  },

  methods: {
    onClickFilter() {
      this.isShowMobileFilter = !this.isShowMobileFilter;
    },
    onClearFilter() {
      this.value = {
        module: {},
        learnline: {}
      };
      this.$emit("change", this.checked);
    },
    onClickMore() {
      this.more = !this.more;
    },
    onCheckFilter(name, checked) {
      if (!this.value[name]) {
        this.value[name] = true;
      }

      this.value[name] = checked;
      this.value = { ...this.value };
      this.$emit("change", this.checked);
    },
  },

  computed: {
    checked() {
      let activeFilters = {
        module: [],
        learnline: []
      };

      for (let [id, checked] of Object.entries(this.value['learnline'])) {
        if(checked) {
          activeFilters.learnline.push(id);
        }
      }
      for (let [id, checked] of Object.entries(this.value['module'])) {
        if(checked) {
          activeFilters.module.push(id);
        }
      }

      return activeFilters;
    },
    isChangedFilter() {
      return Object.values(this.value).some((item) => item);
    },
  }
}

export default filter
