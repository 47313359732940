<template>
<div class="page contact">
    <div class="main header">
        <div class="container">
            <default-header />
        </div>
    </div>

    <section class="section title">
        <div class="container">
            <div class="row">
                <div class="col">
                    <div>
                        <back-btn />
                    </div>
                    <div class="title">
                        <h1>{{ data.info.title }}</h1>
                        <p>
                            <a :href="data.info.callurl">
                                <span class="d-flex justify-content-between align-items-center">
                                    <span class="phone">
                                        <i class="fas fa-phone-alt"></i>
                                    </span>
                                    {{ data.info.call }}
                                </span>
                            </a>
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </section>

    <section class="section paragraph">
        <div class="container">
            <div class="row">
                <div class="col" v-html="data.info.content" />
            </div>
        </div>
    </section>

    <section class="section video" v-if="data.info.youtube_link">
        <div class="container">
            <div class="row">
                <div class="col-12">
                    <div class="embed-responsive embed-responsive-16by9">
                        <iframe class="embed-responsive-item" :src="'https://www.youtube-nocookie.com/embed/' + data.info.youtube_link" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                    </div>
                </div>
            </div>
        </div>
    </section>

    <section class="section form">
        <nf-form :confirm="data.info.form_confirm" :form-id="formId"> </nf-form>
    </section>

    <section>
        <default-footer />
    </section>
</div>
</template>

<script>
import {
    DefaultHeader,
    DefaultFooter,
    BackBtn,
    NfForm
} from "@/components";
import {
    mapState,
    mapActions
} from "vuex";

export default {
    name: "form-page",
    props: ['formId', 'apiUrl'],

    components: {
        DefaultHeader,
        DefaultFooter,
        BackBtn,
        NfForm,
    },

    i18n: {
        messages: {
            nl: {
                naam: "Naam instelling / bedrijfsnaam",
                voornaam: "Voornaam",
                achternaam: "Achternaam",
                adres: "Adres",
                postcode: "Postcode",
                plaats: "Plaats",
                telefoonnummer: "Telefoonnummer",
                vraag: "Vraag",
                rest: "Wat ik verder kwijt wil",
                send: "Verzend",
            },
            en: {
                naam: "Name institution / company",
                voornaam: "Name",
                achternaam: "Surname",
                adres: "Address",
                postcode: "Zip code",
                plaats: "place",
                telefoonnummer: "Phone number",
                vraag: "Question",
                rest: "What else I want to say",
                send: "Send",
            },
        },
    },

    metaInfo() {
        return {
            title: "Contact",
            description: "Neem direct contact met ons op!",
        };
    },

    data() {
        return {
            submited: false,
            contactOptions: [{
                name: "Neem contact met mij op voor een gratis proef",
                value: "Neem contact met mij op voor een gratis proef",
            }, ],
            contact: {
                company: "",
                fname: "",
                lname: "",
                address: "",
                postcode: "",
                plaats: "",
                phone: "",
                question: "",
                text: "",
            },
        };
    },

    created() {
        this.getDataByUrl(`/${this.apiUrl}/`);
    },

    computed: {
        ...mapState("data", ["data"]),
        valid() {
            let fields = Object.keys(this.contact);
            return fields.reduce((a, n) => {
                a[n] = true;
                if (this.submited) {
                    a[n] = false;
                }
                return a;
            }, {});
        },
    },

    methods: {
        ...mapActions({
            getDataByUrl: "data/getDataByUrl",
        }),
        onSubmit() {
            this.submited = true;
        },
    },
};
</script>

<style lang="scss" scoped>
.contact {
    .header {
        background: #f4f4f4;
    }

    .section.title {
        .title {
            margin-bottom: 2rem;
            display: flex;
            align-items: center;
            justify-content: space-between;

            &>p {
                width: 100%;
            }
        }

        h1,
        p {
            color: #40a780;
        }

        h1 {
            font-size: $medium-title-font-size;
        }

        span {
            font-size: $inline-font-size;

            color: #40a780;
        }

        .phone {
            margin-right: 15px;
            width: 35px;
            height: 35px;
            border-radius: 50%;
            border: 2px solid #40a780;
            color: #40a780;
            font-size: 14px;
            display: flex;
            align-items: center;
            justify-content: center;
            margin-left: auto;
        }
    }

    .section.paragraph {
        p {
            color: #bebdbd;
            margin-bottom: 20px;

            &:first-child {
                margin-top: 50px;
            }
        }
    }

    .section.video {
        .embed-responsive-item {
            border-radius: 0.5rem;
        }
    }

    .section.checkboxes {
        margin-top: 50px;
    }

    .section.form {
        margin-top: 20px;
    }

    .btn-contact {
        background-color: #40a780;
        color: #fff;
        padding: 10px 90px;
        border: none;
        border-radius: 5px;
        font-size: $inline-font-size;
        margin: 50px 0;
        margin-left: auto;
        cursor: pointer;
        box-shadow: 0px 2px 2px #0000003d;

        &:focus {
            outline: none;
        }
    }
}

@media (max-width: 768px) {
    .container {
        padding-left: 30px;
        padding-right: 30px;
    }

    .contact {
        .section.title {
            margin-bottom: -25px;

            h1 {
                margin-top: 20px;
                font-size: $small-title-font-size;
            }

            span {
                // font-size: 1rem;
                background-color: #cce5d8;
                padding: 8px 10px;
            }

            .phone {
                width: 30px;
                height: 30px;
            }

            .title {
                flex-direction: column-reverse;
                align-items: flex-start;
                justify-content: flex-start;
            }
        }

        .btn-contact {
            width: 100%;
            font-size: $navigation-font-size;
        }
    }
}

.checkbox {
    @media (max-width: 768px) {
        padding-bottom: 0.5rem !important;
    }
}
</style>
