<template>
  <router-link
    class="box-item"
    :disabled="pending"
    :event="!pending ? 'click' : ''"
    :style="'background-color:' + fgcolor + ';'"
    :to="{ name: 'LearningLinesEducation', params: { education: slug } }"
    :class="{ 'disable-cursor': pending }"
  >
    <div class="image">
      <div class="" v-bind:class="{ 'rounded-image': rounded }">
        <img :src="image" alt="" />
      </div>
    </div>
    <h2 class="title" :style="'color:' + color + ';'">{{ title }}</h2>
    <h3 class="sub-title">{{ subTitle }}</h3>
  </router-link>
</template>

<script>
export default {
  name: "box-item",

  props: {
    url: {
      type: String,
    },
    title: {
      type: String,
    },
    color: {
      type: String,
    },
    fgcolor: {
      type: String,
    },
    subTitle: {
      type: String,
    },
    image: {
      type: String,
    },
    slug: {
      type: String,
    },
    rounded: {
      type: Boolean,
      required: false,
      default: true,
    },
    pending: {
      type: Boolean,
      required: false,
      default: true,
    },
  },

  computed: {},
};
</script>

<style lang="scss" scoped>
.disable-cursor {
  cursor: default !important;
}

.box-item {
  height: 100%;
  padding: 40px 0;
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: center;

  .image {
    display: flex;
    height: 320px;
    justify-content: center;
    position: relative;
    z-index: 1;

    img {
      max-width: 100%;
      max-height: 100%;

      display: block;
      margin: auto auto 0px auto;
    }
  }

  .title,
  .sub-title {
    text-align: center;
    display: flex;
    align-self: center;
    // flex: 1;
    font-size: $small-title-font-size;
    font-weight: normal;
    padding: 5px;
  }
  .title {
    // flex: 1;
  }

  .sub-title {
    // flex: 1;
    color: #4f4e4e;
  }
}

@media (max-width: 1024px) {
  .box-item {
    .sub-title {
      // font-size: 25px;
    }
  }
}

@media (max-width: 768px) {
  .box-item {
    min-height: 457px;
    padding: 20px 5px;

    .title,
    .sub-title {
      //   font-size: $small-title-font-size;
      padding: 5px;
      // flex: 1;
    }

    .title {
      // flex: 1;
      padding-top: 1.5rem;
    }

    .sub-title {
      // font-size: 28px;
    }

    .image {
      width: 234px;
      height: 245px;
      object-fit: cover;
      margin: 0 auto;

      img {
        width: 100%;
        height: 100%;
      }
    }
  }
}
</style>
