<template>
  <div class="fancy-blocks">
    <div
      class="block-container responsive-table"
      v-for="(item, index) in content"
      v-bind:key="index"
    >
      <img
        v-if="item.type == 'image'"
        :src="`https://tgear.eu/${item.data.file.url}`"
        :alt="item.data.caption"
      />
      <div
        class="row mx-n4"
        v-bind:class="{
          'flex-column-reverse flex-md-row': item.data.imageposition == 'right',
        }"
        v-else-if="item.type == 'block'"
      >
        <div
          class="col-12 col-md-6 px-4 block-item responsive-indenting"
          v-if="item.data.imageposition == 'left'"
        >
          <img
            v-if="item.data.image.file"
            :src="`https://tgear.eu/${item.data.image.file.url}`"
            :alt="item.data.image.caption"
          />
        </div>

        <div class="col-12 col-md-6 px-4 block-item">
          <fancy-blocks
            :content="item.data.text"
            :topLevel="false"
          ></fancy-blocks>
        </div>

        <div
          class="col-12 col-md-6 px-4 block-item responsive-indenting"
          v-if="item.data.imageposition == 'right'"
        >
          <img
            v-if="item.data.image.file"
            :src="`https://tgear.eu/${item.data.image.file.url}`"
            :alt="item.data.image.file.caption"
          />
        </div>
      </div>
      <h2
        v-bind:class="{ 'header-title': topLevel }"
        v-else-if="item.type == 'header'"
        v-html="item.data.text"
      />
      <ul v-else-if="item.type == 'list'">
        <li
          v-for="(listitem, listitemindex) in item.data.items"
          v-bind:key="listitemindex"
        >
          {{ listitem }}
        </li>
      </ul>
      <div
        v-bind:class="{ 'header-title': topLevel }"
        v-else-if="item.type == 'paragraph'"
        v-html="item.data.text"
        class="display-block"
      ></div>
      <table v-else-if="item.type == 'table'">
        <tr v-for="(row, rowindex) in item.data.content" v-bind:key="rowindex">
          <td
            v-for="(col, colindex) in row"
            v-bind:key="colindex"
            v-html="col"
          />
        </tr>
      </table>

      <div v-else>Onbekend type content: {{ item.type }}</div>
    </div>
  </div>
</template>

<script>
export default {
  name: "FancyBlocks",
  props: {
    content: {
      type: Array,
      default: () => [],
    },
    topLevel: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {};
  },
};
</script>

<style lang="scss" scoped>
.fancy-blocks {
  // font-size: 24px;

  h2 {
    // font-size: 2.25rem;
    line-height: 1.7rem;
    margin-bottom: 0.5rem;
  }
  line-height: 1.5rem;
}

img {
  max-width: 100%;
}

.blockshow {
  padding: 1rem;
  margin: 1rem;
  border: 1px solid red;
}

.blockshow2 {
  padding: 1rem;
  margin: 1rem;
  border: 1px solid green;
}

ul {
  li {
    list-style-type: disc;
    list-style-position: inside;
  }
}

.block-container {
  // margin-top: 20px;
  // margin-bottom: 20px;
  // margin-bottom: 8.4rem;

  .header-title {
    margin-left: auto;
    margin-right: auto;
    text-align: center;
    padding: 0rem;
  }
  h2.header-title {
    margin-bottom: 1rem;
  }

  .header-title {
    margin-top: -0.24rem;
    margin-bottom: 8.4rem;
    // font-size: 1.3rem;
  }

  .block-item {
    display: flex;
    margin: 4.2rem 0;

    div {
      display: flex;
      flex-direction: column;
      justify-content: center;
      margin-top: unset;
      margin-bottom: unset;

      @media (max-width: 768px) {
        margin-bottom: 10px;
      }

      ul {
        padding-left: 20px;
        padding-top: 1rem;
        margin-left: 0.5rem;
        li {
          list-style-type: circle;
          list-style-position: outside;
          padding-left: 1rem;
        }
      }
    }
    img {
      display: flex;
      align-self: center;
    }
  }

  .responsive-indenting {
    @media (max-width: 768px) {
      margin-bottom: 3rem;
    }
  }
}

.display-block {
  display: block !important;
}
// @media (max-width: 768px) {
//   .fancy-blocks {
//     h2 {
//       font-size: $smallest-title-font-size;
//     }
//     p {
//       font-size: $navigation-font-size;
//     }
//     ul {
//       font-size: $navigation-font-size;
//     }
//   }
// }
</style>
