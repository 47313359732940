<template>
  <div>
    <a href="#" class="back-btn" @click.prevent="onClickBack">
      <i class="fas fa-chevron-left"></i>
      {{ $tc("back") }}
    </a>
  </div>
</template>

<script>
export default {
  name: "back-btn",

  props: {
    label: {
      type: String,
      default: "terug",
    },
  },

  methods: {
    onClickBack() {
      this.$router.go(-1);
    },
  },
};
</script>

<style lang="scss" scoped>
.back-btn {
  padding: 37px 0;
  color: #bebdbd;
  font-size: 1rem;
  font-weight: 300;
  display: inline-block;

  .fas {
    margin-right: 5px;
  }
}

@media (max-width: 768px) {
  .back-btn {
    padding: 15px 0;
  }
}
</style>
