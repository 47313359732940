<template>
<form-page api-url="contact" :form-id="1" />
</template>

<script>
import {
    FormPage
} from "@/pages";
export default {
    name: "contact",

    components: {
        FormPage
    },

    data() {
        return {};
    },

};
</script>
