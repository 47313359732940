<template>
  <section class="section video">
    <div class="container">
      <div class="row">
        <div class="col">
          <div class="video" v-if="!play" ref="video">
            <span class="play" @click.prevent.stop="onClick">
              <a href="#" class="fas fa-play"></a>
            </span>
          </div>
          <iframe
            v-if="play"
            :width="width"
            :height="height"
            :src="src"
            id="player"
            frameborder="0"
            allow="autoplay; encrypted-media"
            allowfullscreen
          ></iframe>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "section-video",

  props: {
    link: {
      type: String,
      default: "",
    },
  },

  data() {
    return {
      src: this.link,
      play: false,
      width: 640,
      height: 360,
    };
  },

  mounted() {
    this.readWithHeight();
    document.body.addEventListener("resize", () => {
      this.play = false;
      this.readWithHeight();
    });
  },

  methods: {
    readWithHeight() {
      this.width = this.$refs.video.offsetWidth;
      this.height = this.$refs.video.offsetHeight;
    },
    onClick() {
      this.play = true;
      // setTimeout(() => {
      //   this.src = `${this.src}?autoplay=1`;
      // }, 1000);
    },
  },
};
</script>

<style lang="scss" scoped>
.section.video {
  .video {
    min-height: 643px;
    background: #000;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 100px;

    .play {
      background-color: #fda922;
      width: 95px;
      height: 96px;
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 44px;
      a {
        transform: translateX(2.5px);
        padding: 25px;
        color: #fff;
      }
    }
  }
}

@media (max-width: 768px) {
  .container {
    padding-left: 30px;
    padding-right: 30px;
  }

  .section.video {
    .video {
      min-height: 203px;
      margin-bottom: 50px;

      .play {
        width: 50px;
        height: 50px;
        // font-size: 18px;
      }
    }
  }
}
</style>