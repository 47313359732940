<template>
  <div class="page home" v-if="data && data.info">
    <div
      class="header home-header"
      :class="{ sticky: scrollTop >= mainHeight }"
    >
      <div class="container">
        <default-header />
      </div>
    </div>

    <div class="main main-section" ref="main">
      <div class="stack">
        <div class="videocontainer">
          
          <video class="vid" video autobuffer autoplay mute loop :v-if="data.headervideo">
            <source id="mp4" :src="data.headervideo" type="video/mp4" />
          </video>
        </div>
      </div>
      <div class="container wrapper-landing-info">
        <div class="landing-info">
          <h1 class="title">
            {{ data.info.header_title }}
          </h1>
          <p class="sub-title" v-html="data.info.header_text"></p>

          <router-link
            v-if="data.info.header_buttonurl"
            :to="{ path: data.info.header_buttonurl }"
            class="green-btn"
            >{{ data.info.header_buttontext }}</router-link
          >
          <router-link
            v-if="data.info.header_subtexturl"
            :to="{
              path: data.info.header_subtexturl,
              query: { level: $route.query.level },
            }"
            class="more-btn"
          >
            &nbsp; {{ data.info.header_subtext }}
            <i class="fas fa-chevron-right"></i>
          </router-link>
        </div>
        <a
          href="#"
          class="mouse-down scroll-action"
          @click.stop.prevent="onClickDown"
        >
          <div class="icon"></div>
          <div class="down-icon"></div>
        </a>
      </div>
    </div>

    <section class="section video">
      <div class="container">
        <div class="row">
          <div class="col-12">
            <div class="embed-responsive embed-responsive-16by9">
              <iframe
                class="embed-responsive-item"
                :src="`https://www.youtube-nocookie.com/embed/${data.info.youtubevideo}`"
                title="YouTube video player"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowfullscreen
              ></iframe>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section class="section help" ref="help">
      <div class="container">
        <div class="row">
          <div class="col">
            <h2 class="section-title">{{ data.info.advantage_title }}</h2>
          </div>
        </div>
        <div class="row">
          <div class="col">
            <fancy-blocks
              :content="data.info.advantage_content"
              :topLevel="false"
            ></fancy-blocks>
          </div>
        </div>
        <div class="row">
          <div class="col">
            <router-link :to="{ name: 'voordelen' }">
              <div class="d-flex justify-content-center mt-5">
                <span v-if="data.info.advantage_title2url" class="link"
                  >{{ data.info.advantage_title2 }} &rsaquo;</span
                >
              </div>
            </router-link>
          </div>
        </div>
      </div>
    </section>

    <section class="section gray step-by-step">
      <div class="container">
        <div class="row">
          <div class="col">
            <h2 class="section-title">{{ data.info.learn_title }}</h2>
          </div>
        </div>
        <div class="row">
          <div
            class="col-xl-4 col-lg-4 item-step"
            v-for="(item, index) in data.eduction"
            :key="index"
          >
            <box-item
              :sub-title="item.title"
              title="Leerlijn"
              :image="item.image.image"
              :color="item.color"
              :fgcolor="item.fgcolor"
              :url="item.url"
              :slug="item.slug"
              :rounded="false"
              :pending="item.pending == '1'"
            />
          </div>
        </div>
      </div>
    </section>

    <section class="section together">
      <router-link :to="{ name: 'over-t-gear' }">
        <div class="container">
          <div class="row">
            <div class="col">
              <h2 class="section-title">{{ data.info.about_title }}</h2>
            </div>
          </div>
          <div class="row">
            <div class="col">
              <div
                class="d-flex justify-content-center align-items-start mb-5 image rounded-image"
              >
                <img :src="data.info.about_image" alt="together" />
              </div>
              <p class="description" v-html="data.info.about_text"></p>
            </div>
          </div>
        </div>
      </router-link>
    </section>

    <section class="section gray clear-payments">
      <div class="container">
        <div class="row">
          <div class="col">
            <h2 class="section-title">{{ data.info.product_title }}</h2>
            <p class="description" v-html="data.info.product_text"></p>
          </div>
        </div>
        <div class="row">
          <div class="col-xl-4 col-lg-4">
            <router-link to="/what-do-i-need?tab=book">
              <div class="step one">
                <div class="image-wrap">
                  <div class="image">
                    <img
                      src="https://tgear.eu/data/images/home/overview_book_image2.png"
                      alt="automatic"
                    />
                  </div>
                </div>
                <h2 class="title">{{ data.info.book_title }}</h2>
                <h3 class="sub-title">{{ data.info.book_subtitle }}</h3>
              </div>
            </router-link>
          </div>
          <div class="col-xl-4 col-lg-4">
            <router-link to="/what-do-i-need?tab=gear">
              <div class="step two">
                <div class="image-wrap">
                  <div class="image">
                    <img
                      src="https://tgear.eu/data/images/home/overview_gear_image2.png"
                      alt="automatic"
                    />
                  </div>
                </div>
                <h2 class="title">{{ data.info.gear_title }}</h2>
                <h3 class="sub-title">{{ data.info.gear_subtitle }}</h3>
              </div>
            </router-link>
          </div>
          <div class="col-xl-4 col-lg-4">
            <router-link to="/what-do-i-need?tab=software">
              <div class="step three gratis">
                <div class="image-wrap">
                  <div class="image">
                    <img
                      src="https://tgear.eu/data/images/home/overview_software_image2.png"
                      alt="automatic"
                    />
                  </div>
                </div>
                <h2 class="title">{{ data.info.software_title }}</h2>
                <h3 class="sub-title">{{ data.info.software_subtitle }}</h3>
              </div>
            </router-link>
          </div>
        </div>
      </div>
    </section>

    <section
      class="section reviews"
      v-if="data.testimonial && data.testimonial.length > 0"
    >
      <router-link :to="{ name: 'voordelen' }">
        <div class="container">
          <div class="row">
            <div class="col">
              <h2 class="section-title">{{ data.info.story_title }}</h2>
            </div>
          </div>
          <div class="row">
            <div
              class="col-xl-4 col-lg-4"
              v-for="(review, index) in data.testimonial"
              :key="index"
            >
              <div class="review">
                <div class="avatar">
                  <img :src="review.image" alt="alt" />
                </div>
                <p class="review-description" v-html="review.intro"></p>
                <span class="author">{{ review.title }}</span>
              </div>
            </div>
          </div>
        </div>
      </router-link>
    </section>

    <section class="convinced-section" style="padding-bottom: 60px">
      <div class="container">
        <div class="row">
          <div class="col offset-xl-1">
            <convinced-section />
          </div>
        </div>
      </div>
    </section>

    <default-footer />
  </div>
</template>

<script>
import {
  DefaultHeader,
  DefaultFooter,
  BoxItem,
  ConvincedSection,
  FancyBlocks,
} from "@/components";

import { common as commonMixin } from "@/mixins";

import { mapState, mapActions } from "vuex";

export default {
  name: "Home",

  metaInfo() {
    return {
      title: this.data.header_title,
      description: this.data.header_text,
    };
  },

  components: {
    ConvincedSection,
    DefaultHeader,
    DefaultFooter,
    BoxItem,
    FancyBlocks,
  },

  created() {
    this.getDataByUrl("/home/");
  },

  mounted() {
    window.onscroll = () => {
      let body = window.document.body;
      let document = window.document.documentElement;
      document = document.clientHeight ? document : body;
      this.scrollTop = document.scrollTop;
    };
  },

  mixins: [commonMixin],

  data() {
    return {
      scrollTop: 0,
    };
  },

  methods: {
    ...mapActions({
      getDataByUrl: "data/getDataByUrl",
    }),
    getHeightWindow() {
      const documentElement = document.documentElement;
      return (
        window.innerHeight ||
        documentElement.clientHeight ||
        document.body.clientHeight
      );
    },
    scrollTo(element, duration) {
      let e = document.documentElement;
      if (e.scrollTop === 0) {
        let t = e.scrollTop;
        ++e.scrollTop;
        e = t + 1 === e.scrollTop-- ? e : document.body;
      }
      this.scrollToC(e, e.scrollTop, element, duration);
    },
    scrollToC(element, from, to, duration) {
      if (duration <= 0) return;
      if (typeof from === "object") from = from.offsetTop;
      if (typeof to === "object") to = to.offsetTop;

      this.scrollToX(element, from, to, 0, 1 / duration, 20, this.linearTween);
    },

    scrollToX(element, xFrom, xTo, t01, speed, step, motion) {
      if (t01 < 0 || t01 > 1 || speed <= 0) {
        element.scrollTop = xTo;
        return;
      }
      element.scrollTop = xFrom - (xFrom - xTo) * motion(t01);
      t01 += speed * step;

      setTimeout(() => {
        this.scrollToX(element, xFrom, xTo, t01, speed, step, motion);
      }, step);
    },

    linearTween(t) {
      return t;
    },

    onClickDown() {
      let el = this.$refs.help;
      this.scrollTo(el, 600);
    },
  },

  computed: {
    ...mapState("data", ["data"]),

    mainHeight() {
      return this.$refs && this.$refs.main ? this.$refs.main.clientHeight : 850;
    },
  },
};
</script>

<style lang="scss" scoped>
.home {
  display: flex;
  flex-direction: column;

  /* background image */
  .main {
    background-image: url("https://tgear.eu/data/images/header/desk/desktopHeader2.jpg");
    background-color: #4f4e4e;
    background-size: cover;
    background-position: center center;
    transform: translateY(-54px);
    height: calc(100vh);
    max-height: 1000px;
    display: flex;
    margin-top: -0.5px;

    .wrapper-landing-info {
      display: flex;
      flex-direction: column;
      padding-top: 100px;
      padding-bottom: 60px;
      @media (max-height: 500px) {
        padding-top: 0;
      }
    }

    .landing-info {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      max-width: 572px;
      margin: auto auto auto 100px;
      position: relative;

      .green-btn,
      .sub-title,
      .more-btn {
        font-family: $main-font;
        color: #ffffff;
      }

      .title {
        color: #fdaa20;
        font-size: $big-title-font-size;
        font-weight: bold;
      }

      .sub-title {
        margin: 30px 0;
        font-size: $inline-font-size;
        line-height: $inline-line-height;
        font-weight: normal;
      }

      .green-btn,
      .more-btn {
        font-weight: normal;
        font-size: $inline-font-size;
        padding: 5px 20px;
      }

      .green-btn {
        background-color: #40a780;
        border-radius: 3px;
        margin-bottom: 15px;
      }

      .more-btn {
        display: flex;
        align-items: center;

        i {
          margin-left: 10px;
          font-size: 15px;
        }
      }
    }

    .mouse-down {
      width: fit-content;
      margin-left: auto;
      margin-right: auto;
      justify-content: center;
      display: flex;
      flex-direction: column;
      align-items: center;
      transition: all 0.5s;
      position: relative;
    }

    .mouse-down:hover > .icon {
      border: 2px solid #fff;
    }

    .mouse-down:hover > .icon:after,
    .mouse-down:hover > .down-icon:after,
    .mouse-down:hover > .down-icon:before {
      background: #fff;
    }

    .mouse-down:hover > .icon:after {
      top: 27px;
    }

    .mouse-down:hover > .down-icon {
      bottom: -60px;
    }

    .mouse-down .icon {
      width: 36px;
      height: 51px;
      border-radius: 2rem;
      border: 2px solid #8c847c;
      position: relative;
      transition: all 0.5s;
    }

    .mouse-down .icon:after {
      content: "";
      position: absolute;
      top: 10px;
      width: 6px;
      height: 11px;
      background: #8c847c;
      left: 50%;
      transform: translate(-50%);
      border-radius: 9px;
      transition: all 0.5s;
    }

    .mouse-down .down-icon {
      position: absolute;
      bottom: -50px;
      transition: all 0.5s;
      min-height: 50px;
      width: 19px;
      min-height: 90px;
      min-width: 35px;
    }

    .mouse-down .down-icon:after,
    .mouse-down .down-icon:before {
      position: absolute;
      transition: all 0.5s;
      content: "";
      display: block;
      width: 2px;
      height: 25px;
      border-radius: 2px;
      background: #8c847c;
      top: 50%;
      transform: translateY(-50%);
    }

    .mouse-down .down-icon:after {
      transform: rotate(-45deg);
      left: 8px;
    }

    .mouse-down .down-icon:before {
      transform: rotate(45deg);
      right: 8px;
    }
  }

  .section {
    background-color: #fdfdfd;
    padding: 50px 0;

    &.gray {
      background-color: #f4f4f4;
    }

    .section-title {
      color: #4f4e4e;
      font-family: Apercu, Open Sans, Arial, Helvetica, sans-serif;
      font-size: $medium-title-font-size;
      font-weight: bold;
      margin-bottom: 40px;
    }
  }

  .section.help {
    margin-top: -50px;
    padding: 100px 0 60px 0;

    .section-title {
      // margin-bottom: 80px;
      margin-bottom: -2.1rem;
      font-size: $big-title-font-size;
      @media (max-width: 768px) {
        font-size: $small-title-font-size;
      }
    }

    .help-item {
      text-align: center;

      .image {
        margin-bottom: 70px;

        img {
          border-radius: 50%;
        }
      }

      .name {
        font-size: $small-title-font-size;
        font-weight: normal;
        color: #40a780;
        margin-bottom: 20px;
      }

      p {
        color: #4f4e4e;
        font-size: $inline-font-size;
        text-align: center;
        font-family: $main-font;
        font-weight: normal;
        line-height: $inline-line-height;
      }
    }

    .link {
      color: #0088ce;
      font-family: $main-font;
      font-size: $inline-font-size;
      font-weight: normal;
    }
  }

  .section.step-by-step {
    .section-title {
      font-size: $big-title-font-size;
      @media (max-width: 768px) {
        font-size: $small-title-font-size;
      }
    }
    .step {
      min-height: 531px;
      padding: 30px 15px;
      display: flex;
      flex-direction: column;
      justify-content: center;

      .image {
        display: flex;
        justify-content: center;
        position: relative;
        z-index: 1;
      }

      .title,
      .sub-title {
        text-align: center;
        font-size: 2rem;
        font-weight: normal;
      }

      .sub-title {
        color: #222221;
      }

      &.one {
        color: #fdaa29;
        background-color: #ffe5bd;
      }

      &.two {
        color: #40a780;
        background-color: #cce5d8;
      }

      &.three {
        color: #0088ce;
        background-color: #b8d8f2;
      }
    }
  }

  .section.together {
    padding-bottom: 100px;

    .section-title {
      font-size: $big-title-font-size;
      @media (max-width: 768px) {
        font-size: $small-title-font-size;
      }
    }

    .description {
      font-family: $main-font;
      font-weight: normal;
      font-size: $inline-font-size;
      line-height: $inline-line-height;
      color: #4f4e4e;
    }

    .image img {
      border-radius: 50%;
    }
  }
  .section.video {
    padding-top: 50px;
    padding-bottom: 50px;
    .embed-responsive-item {
      border-radius: 0.5rem;
    }
  }

  .section.clear-payments {
    .section-title {
      font-size: $big-title-font-size;
      @media (max-width: 768px) {
        font-size: $small-title-font-size;
      }
    }

    .description {
      font-size: $inline-font-size;
      font-family: $main-font;
      font-weight: normal;
      color: #4f4e4e;
    }

    .step {
      min-height: 531px;
      padding: 30px 0;
      display: flex;
      flex-direction: column;
      justify-content: center;

      .image-wrap {
        background-color: #ffffff;
        min-height: 376px;
        margin-bottom: 30px;
        padding-top: 20px;
        img {
          max-width: 100%;

          max-height: 100%;
        }
      }

      .image {
        display: flex;
        justify-content: center;
        align-items: flex-start;
        position: relative;
        z-index: 1;
      }

      .title,
      .sub-title {
        text-align: center;
        font-size: 2rem;
        font-weight: normal;
      }

      .sub-title {
        color: #4f4e4e;
        font-family: $main-font;
        font-size: $inline-font-size;
      }

      &.gratis {
        .image {
          position: relative;

          &:after {
            content: "gratis";
            text-transform: uppercase;
            color: #ffffff;
            font-family: $main-font;
            font-weight: bold;
            font-style: italic;
            font-size: $inline-font-size;
            position: absolute;
            right: 20px;
            top: 0.25rem;
          }
        }
      }

      &.one {
        color: #40a780;
      }

      &.two {
        color: #d16419;
      }

      &.three {
        color: #0088ce;
      }
    }
  }

  .section.reviews {
    padding-bottom: 100px;

    .review {
      display: flex;
      flex-direction: column;
      align-items: center;

      .review-description {
        font-family: $main-font;
        font-size: $inline-font-size;
        color: #4f4e4e;
        font-weight: normal;
        font-style: italic;
        line-height: $inline-line-height;
        margin-top: 25px;
      }

      .author {
        font-family: $main-font;
        font-size: $inline-font-size;
        color: #4f4e4e;
        margin-top: 15px;
        display: inline-block;
      }
    }
  }
}

@media screen and (min-width: 1400px) {
  .home {
    .main {
      .landing-info {
        margin: auto auto auto 100px;
      }
    }
  }
}

@media (max-width: 1024px) {
  .home {
    .header {
      position: sticky;
      top: 0;
      z-index: 2;
    }

    .main {
      margin-top: 7.2px;
    }

    .section.clear-payments {
      .step {
        .image-wrap {
          .image {
            &:after {
              // font-size: 22px;
              right: 0px;
              top: 7px;
            }
          }
        }
      }
    }

    .section.help {
      p {
        margin-bottom: 70px;
      }

      .name {
        margin-bottom: 5px !important;
      }

      .image {
        margin-bottom: 30px !important;
      }
    }

    .section.step-by-step {
      .item-step {
        margin-bottom: 26px;
      }
    }
  }
}

@media (max-width: 768px) {
  .header {
    background-color: #f4f4f4;
    position: sticky;
    top: 0;
    background: #f4f4f4 0% 0% no-repeat padding-box;
    z-index: 2;
  }

  .container {
    padding-left: 30px;
    padding-right: 30px;
  }

  .home {
    .section {
      .section-title {
        font-size: $smallest-title-font-size;
      }
    }

    .main {
      .scroll-action {
        display: none;
      }

      .landing-info {
        margin: 86px auto 56px 0;

        .mobiel-btn {
          display: none;
        }

        .title {
          font-size: $small-title-font-size;
        }

        .sub-title,
        .green-btn,
        .more-btn {
          font-size: $navigation-font-size;
          font-family: $main-font;
        }
      }
    }

    .section.help {
      padding-top: 65px;

      .help-item {
        .name {
          font-size: $smallest-title-font-size;
          margin-bottom: 0 !important;
        }

        .image {
          width: 200px;
          height: 200px;
          object-fit: cover;
          margin-left: auto;
          margin-right: auto;
          margin-top: 46px;

          img {
            width: 100%;
            height: 100%;
          }
        }

        p {
          font-size: $navigation-font-size;
          margin-bottom: 10px;
        }
      }

      .link {
        font-size: $navigation-font-size;
      }
    }

    .section.step-by-step {
      .item-step {
        margin-bottom: 26px;
      }
    }

    .section.together {
      .image {
        width: 200px;
        height: 200px;
        object-fit: cover;
        margin: 0 auto;

        img {
          width: 100%;
          height: 100%;
        }
      }

      .description {
        line-height: 26px;
        font-size: $navigation-font-size;
      }
    }

    .section.clear-payments {
      .description {
        font-size: $navigation-font-size;
        line-height: 26px;
      }

      .step {
        padding-top: 0;
        padding-bottom: 0;
        min-height: 460px;

        .title {
          font-size: $smallest-title-font-size;
        }

        .sub-title {
          font-size: $navigation-font-size;
        }

        .image-wrap {
          min-height: 320px;

          .image {
            width: 290px;
            height: 290px;
            object-fit: cover;
            margin: 0 auto;

            &:after {
              font-size: $navigation-font-size;
              top: 3px;
              right: 10px;
            }

            img {
              width: 100%;
              height: 100%;
            }
          }
        }
      }
    }

    .section.reviews {
      padding-bottom: 50px;

      .review {
        margin-bottom: 57px;

        .avatar {
          width: 240px;
          height: 220px;
          object-fit: cover;
          margin: 0 auto;

          img {
            width: 100%;
            height: 100%;
          }
        }

        .review-description,
        .author {
          font-size: $navigation-font-size;
        }
      }
    }
  }
}

@media (max-width: 425px) {
  .home {
    .main {
      .landing-info {
        margin: 86px auto;
        transform: translateY(50px);

        .title {
          // font-size: 26px;
        }

        .sub-title,
        .green-btn,
        .more-btn {
          // font-size: 18px;
          line-height: 23px;
        }
      }
    }
  }
}

@media (max-width: 320px) {
  .container {
    padding-left: 15px;
    padding-right: 15px;
  }
}
</style>
