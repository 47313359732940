<template>
  <div class="learning-accordion-wrapper">
    <div class="accordion">
      <div
        class="accordion-item"
        :class="{
          'rock-star': accordion.rock_star,
          active: toggles[index],
          complete: progessIsOrange(index),
        }"
        v-for="(accordion, index) in items"
        :key="index"
      >
        <div class="container">
          <div class="row item" @click="onClickToggle(index)">
            <div class="col-2 d-flex justify-content-between heandler">
              <div class="time desktop">
                <i class="far fa-clock"></i>
                <!-- TODO in the last item of this loop there may be no content pasted into here(line below)  -->
                {{ $tc("reusable.months", accordion.standardduration) }}
              </div>
              <span class="progress-bar">
                <span class="fill"></span>
              </span>
              <a
                href="#"
                @click.prevent="() => {}"
                class="btn-toggle desktop"
                :class="{ down: toggles[index] }"
              >
                <img
                  :src="`/static/images/arrow-${
                    toggles[index] ? 'down' : 'up'
                  }.png`"
                  alt="alt"
                />
              </a>
            </div>
            <div class="col-xl-7 col-lg-7 col-10">
              <a
                href="#"
                @click.prevent="() => {}"
                class="btn-toggle mobile"
                :class="{ down: toggles[index] }"
              >
                <img src="/static/images/arrow-up.png" alt="alt" />
              </a>
              <div class="title-accordion">
                <div class="title">
                  <h2>{{ accordion.title }}</h2>
                  <p>{{ accordion.subtitle }}</p>
                </div>
                <div class="icons"></div>
              </div>
              <div
                class="body-accordion"
                :class="{ show: toggles[index] }"
                :style="{
                  'max-height': toggles[index] ? `${maxHeight}px` : `0px`,
                }"
              >
                <accordion-body
                  :ref="`accordion_body_${index}`"
                  :text="accordion.content"
                  :data="accordion"
                  :id="accordion.id"
                  @show-slider="onClickSlide"
                />
              </div>
            </div>

            <div
              class="col-xl-3 col-lg-3 image-wrapper"
              style="height: 100%; width: 100%"
            >
              <div class="justify-content-end d-flex">
                <img
                  v-for="(icon, index) in accordion.icon"
                  :key="'a' + index"
                  :src="`${icon.image}`"
                  :alt="icon.title"
                />
              </div>

              <div class="time mobile">
                <i class="far fa-clock"></i>
                {{ $tc("reusable.months", accordion.standardduration) }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <slider-popup
      v-if="isShowSlider"
      :index="indexSlider"
      :show="isShowSlider"
      :url="sliderUrl"
      @close="onCloseSlider"
    />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import AccordionBody from "./AccordionBody";
import SliderPopup from "./SliderPopup";

export default {
  name: "learning-accordion",

  components: {
    AccordionBody,
    SliderPopup,
  },

  props: ["items"],

  data() {
    return {
      maxHeight: 0,
      isShowSlider: false,
      indexSlider: 0,
      toggles: [],
      sliderUrl: "",
      accordionBody: [
        {
          img: "https://tgear.eu/data/images/learn/overview_book_image5.jpg",
          title: "Lesmateriaal",
          color: "green",
          url: "/slider/book/",
        },
        {
          img: "https://tgear.eu/data/images/learn/overview_gear_image5.jpg",
          title: "T GEAR hardware",
          color: "orange",
          url: "/slider/gear/",
        },
        {
          img:
            "https://tgear.eu/data/images/learn/overview_software_image5.jpg",
          title: "software",
          color: "blue",
          url: "/slider/software/",
        },
      ],
    };
  },

  mounted() {
    this.reset();
    this.reRead();
    document.body.addEventListener("resize", () => {
      this.reRead();
    });
  },

  methods: {
    reRead() {
      if (this.$refs.accordion_body_0) {
        this.maxHeight = this.$refs.accordion_body_0[0].$el.clientHeight;
        this.maxHeight += 100;
      }
    },
    onCloseSlider() {
      this.isShowSlider = false;
    },
    onClickSlide(item, id) {
      this.sliderUrl = `${item.url}${id}/`;
      // this.isShowSlider = true;
      //  if (slideIndex || slideIndex === 0) {
      //    this.indexSlider = slideIndex;
      //  }
    },
    reset() {
      this.toggles = [...this.items.map(() => false)];
    },
    onClickToggle(index) {
      if (typeof this.toggles[index] === "undefined" || !this.toggles[index]) {
        this.reset();
      }

      if (index === this.toggles.length - 1) return;

      this.toggles[index] = !this.toggles[index];
      this.toggles = [...this.toggles];
    },
    progessIsOrange(index) {
      return (
        index < this.currentActiveIndex ||
        this.currentActiveIndex == this.toggles.length - 2
      );
    },
  },

  computed: {
    ...mapGetters(["currentTag", "tags"]),
    currentActiveIndex() {
      const activeIndex = this.toggles.lastIndexOf(true);
      return activeIndex;
    },
  },
};
</script>

<style lang="scss" scoped>
.learning-accordion-wrapper {
  .accordion-item {
    transition: background-color 0.3s;

    &:hover {
      background-color: #eaeaea !important;
    }

    &:last-child:hover {
      background-color: unset !important;
      cursor: default;
    }

    .heandler {
      position: relative;
    }

    .item {
      padding-top: 30px;
      padding-bottom: 30px;
      cursor: pointer;
    }

    .time.mobile,
    .btn-toggle.mobile {
      display: none;
    }

    .btn-toggle {
      transition: all 0.3s;
      transform: rotate(0deg);

      &.down {
        transform: rotate(180deg);
      }
    }

    &.active {
      .title-accordion {
        .title {
          h2 {
            color: #d16419;
          }
        }
      }

      .progress-bar {
        &:after {
          border-color: #d16419;
          background-color: #f4ceaf;
        }

        .fill {
          height: 30px;
        }
      }

      .btn-toggle {
        color: #f4ceaf;
      }

      .time {
        color: #d16419;

        .far {
          position: relative;
          z-index: 1;

          &:after {
            content: "";
            z-index: -1;
            position: absolute;
            width: 13px;
            height: 13px;
            left: 33%;
            top: 50%;
            transform: translate(-50%, -50%);
            border-radius: 50%;
            background-color: #f4ceaf;
          }
        }
      }
    }

    &.complete {
      .progress-bar {
        .fill {
          height: 100%;
        }

        &:after {
          border-color: #d16419;
        }
      }
    }

    &.rock-star {
      .title {
        h2 {
          color: #fdaa29 !important;
          font-size: 40px !important;
          font-weight: 600 !important;
        }
      }
    }

    &:nth-child(2n + 1) {
      background-color: #f4f4f4;

      .progress {
        .progress-bar {
          &:after {
            background-color: #f4f4f4;
          }
        }
      }
    }

    &:last-child {
      .progress-bar {
        height: 50%;

        &:after {
          z-index: 1;
          content: "";
          display: block;
          width: 77px;
          height: 77px;
          border-radius: 50%;
          border: 5px solid #4f4e4e;
          position: absolute;
          left: 50%;
          transform: translateX(-50%);
          top: 20px;
          background: #fff;
        }

        &:before {
          font-family: "Font Awesome 5 Free";
          z-index: 2;
          content: " ";
          display: inline-block;
          height: 44px;
          width: 44px;
          background-image: url("/static/images/kroontje.svg");
          background-size: 44px 44px;
          background-repeat: no-repeat;
          position: absolute;
          left: 50%;
          transform: translateX(-50%);
          top: 36px;
          @media (max-width: 768px) {
            // enjoy TODO:
          }
        }
      }

      h2 {
        font-weight: 700 !important;
        color: #fdaa29 !important;
      }

      &.complete {
        &:last-child .progress-bar:after {
          border-color: #d16419;
        }
      }

      .btn-toggle {
        display: none !important;
      }
    }

    .progress-bar {
      position: absolute;
      z-index: 2;
      top: -30px;
      bottom: -30px;
      width: 5px;
      left: 70%;
      transform: translateX(-50%);
      background-color: #4f4e4e;
      transform: all 0.3s;

      .fill {
        display: inline-block;
        z-index: 2;
        width: 100%;
        background-color: #d16419;
        transition: all 0.5s;
      }

      &:after {
        content: "";
        display: block;
        width: 25px;
        height: 25px;
        border-radius: 50%;
        border: 5px solid #4f4e4e;
        position: absolute;
        top: 30px;
        left: -10px;
        background: #fff;
      }
    }

    .btn-toggle {
      color: #bebdbd;
      font-size: $button-font-size;
      margin-left: 30px;
      position: absolute;
      top: 5px;
      right: 0;
    }

    .progress {
      padding: 0 15px;
      align-self: flex-start;
    }

    .time {
      color: #4f4e4e;
      font-weight: 500;
      margin-right: 35px;
    }

    .title-accordion {
      display: flex;

      .title {
        margin-left: 5px;

        h2 {
          color: #4f4e4e;
          font-size: $small-title-font-size;
          font-weight: 400;
          transform: translateY(-5px);
        }

        .short-description {
          font-family: $main-font;
          font-weight: 400;
          color: #4f4e4e;
        }
      }
    }

    .body-accordion {
      min-height: 0;
      max-height: 0;
      transition: all 0.3s ease-in-out;
      transition-delay: 0.1s;
      opacity: 0;
      margin-left: 5px;
      overflow: hidden;

      &.show {
        padding: 30px 0;
        min-height: auto;
        max-height: 3000px !important;
        opacity: 1;
        overflow: unset;
      }
    }

    .image-wrapper {
      position: relative;
      height: fit-content;

      img {
        max-height: 40px;
        padding-left: 1rem;

        @media (max-width: 1200px) {
          max-height: 30px;
          margin-top: 0.3rem;
        }

        @media (max-width: 992px) {
          max-height: 20px;
          margin-top: 1rem;
        }

        @media (max-width: 768px) {
          padding-left: 0.5rem;
        }
      }
    }
  }
}

@media (max-width: 1200px) {
  .learning-accordion-wrapper {
    .accordion-item {
      .progress-bar {
        bottom: -70px;
      }
    }
  }
}

@media (max-width: 1024px) {
  .learning-accordion-wrapper {
    .image {
      display: flex;

      img {
        margin-top: 5px;
        margin-left: auto;
        height: 50px;
        object-fit: contain;
        width: 80%;
      }
    }

    .accordion-item {
      .progress-bar {
        bottom: -90px;
      }
    }
  }
}

@media (max-width: 990px) {
  .learning-accordion-wrapper {
    .accordion-item {
      .btn-toggle.desktop {
        display: none;
      }

      .btn-toggle.mobile {
        display: block;
        position: absolute;
        right: 0;
      }
      &:last-child {
        .progress-bar {
          &:after {
            width: 50px;
            height: 50px;
          }

          &:before {
            width: 24px;
            height: 24px;

            background-size: 24px 24px;

            left: 50%;
            top: 33px;
          }
        }
      }
    }
  }
}

@media (max-width: 768px) {
  .container {
    padding-left: 30px;
    padding-right: 30px;
  }

  .learning-accordion-wrapper {
    .accordion-item {
      position: relative;

      .image-wrapper {
        display: flex;
        flex-direction: row-reverse;
        margin-left: 17.8%;
      }

      .time.desktop {
        display: none;
      }

      .time.mobile {
        left: 20%;
        bottom: -22px;
        display: flex;
        width: 100%;
        align-self: flex-end;
        align-items: center;

        i {
          padding-right: 7px;
        }
      }

      &:last-child {
        .progress-bar {
          &:after {
            width: 40px;
            height: 40px;
            top: 65%;
          }

          &:before {
            top: 80%;
            // font-size: 18px;
          }
        }
      }

      .title-accordion {
        .title {
          h2 {
            // font-size: 30px !important;
          }

          p {
            // font-size: $navigation-font-size;
          }
        }
      }

      &.rock-star {
        .title-accordion {
          h2 {
            // font-size: 25px !important;
          }
        }
      }
    }
  }
}

@media (max-width: 425px) {
  .learning-accordion-wrapper {
    .accordion-item {
      .time.mobile {
        left: 22%;
      }

      &:last-child {
        .progress-bar {
          &:before {
            top: 78%;
            // font-size: 1rem;
          }
        }
      }
    }
  }
}
.accordion-item:last-of-type {
  // .time.desktop {
  //   display: none;
  // }
  .item {
    cursor: unset;
  }
}
</style>
