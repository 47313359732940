import router from '@/router'

const app = {
  state() {
    return {
      tags: [],
      currentTag: {},
      isShowHavoVwo: false,
      lang: 'nl',
      menu: [],
      education: [],
      currentTagChanged: false
    }
  },

  getters: {
    lang: state => state.lang,
    tags: state => state.tags,
    currentTag: state => state.currentTag,
    isShowHavoVwo: state => state.isShowHavoVwo,
    menu: state => state.menu,
    education: state => state.education,
  },

  actions: {
    changeCurrentTag({ commit }, tag) {
      commit('setCurrentTag', tag)
    },
    changeShowHavoVwo({ commit }, value) {
      commit('setShowHavoVwo', value)
    },
    autoShowHavoVwo({ commit }) {
      commit('autoShowShowHavoVwo')
    },
    changeLang({ commit }, value) {
      commit('setLang', value)
    },
    setTags({ commit }, value) {
      commit('setTags', value)
    },
    setMenu({ commit }, value) {
      commit('setMenu', value)
    },
    setEducation({ commit }, value) {
      commit('setEducation', value)
    }
  },

  mutations: {
    setCurrentTag(state, tag) {
      state.currentTagChanged = true;
      state.currentTag = tag;
    },
    setLang(state, lang) {
      state.lang = lang
    },
    setShowHavoVwo(state, value) {
        state.isShowHavoVwo = value
    },
    autoShowShowHavoVwo(state) {
      if(router.currentRoute.query && !router.currentRoute.query.level && state.currentTagChanged === false) {
        state.isShowHavoVwo = true
      } else {
        for(const tag of state.tags) {
          if(tag.slug == router.currentRoute.query.level) {
            state.currentTag = tag;
          }
        }
      }
    },
    setTags(state, value) {
      state.tags = value
    },
    setMenu(state, value) {
      state.menu = value
    },
    setEducation(state, value) {
      state.education = value
    }
  }
}

export default app
