<template>
  <div class="page software-product">
    <section class="product-info">
      <div class="container">
        <div class="row">
          <div class="col">
            <back-btn />
            <h1>{{ product.title }}</h1>

            <span class="desc"
              >{{ $tc("learnline") }} {{ product.learnlineName }}</span
            >
          </div>
        </div>
        <div class="row">
          <div class="col">
            <div class="img">
              <img :src="product.image" alt="alt" />
            </div>
          </div>
        </div>
        <div class="row margin-content-blocks">
          <div class="col">
            <div>
              <h2
                v-html="product.title2.replace(/(?:\r\n|\r|\n)/g, '<br />')"
              />
              <p>{{ product.intro }}</p>
            </div>
            <div class="d-flex justify-content-center">
              <router-link
                href="#"
                class="download"
                :to="{ name: 'downloads' }"
                >{{ $tc("download") }}</router-link
              >
            </div>
          </div>
        </div>
        <div class="row product-content" v-if="product.content">
          <fancy-blocks class="col" :content="product.content" />
        </div>
      </div>
    </section>

    <!-- <video-section :link="product.vimeo" v-if="product.vimeo" /> -->

    <!-- TODO connect :src with cms and fill cms with youtube link  -->
    <!-- TODO set v-if condition to check if there is content from cms  -->
    <section class="section video" v-if="false">
      <div class="container">
        <div class="row">
          <div class="col-12">
            <div class="embed-responsive embed-responsive-16by9">
              <iframe
                class="embed-responsive-item"
                :src="`https://www.youtube-nocookie.com/embed/FImv0rlWfqA`"
                title="YouTube video player"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowfullscreen
              ></iframe>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section class="section specifications mt-5">
      <div class="wrapper-specifications">
        <section class="section systems">
          <div class="container">
            <div class="row">
              <div class="col">
                <h1 class="section-title">{{ $tc("specifications") }}</h1>
              </div>
            </div>
            <div class="row">
              <div class="col-12">
                <div class="systems">
                  <div class="responsive-table" v-html="product.specs" />
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </section>

    <section class="section downloads" v-if="product.modules">
      <div class="container">
        <div class="row">
          <div class="col">
            <h1 class="section-title">Gebruikt in de modules</h1>
            <div class="modules-parts">
              {{ usedInModules }}
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import { BackBtn } from "@/components";

import FancyBlocks from "@/components/FancyBlocks";

export default {
  name: "software-product",

  components: {
    BackBtn,
    "fancy-blocks": FancyBlocks,
  },

  props: {
    product: {
      type: Object,
      default: () => ({}),
    },
  },

  data() {
    return {};
  },

  computed: {
    usedInModules() {
      var list = "";
      this.product.modules.forEach((module) => {
        list += module.title + ", ";
      });
      return list.substring(0, list.length - 2);
    },
  },

  methods: {},

  i18n: {
    messages: {
      nl: {
        download: "Download Gratis",
        modules: "Gebruikt in de modules",
      },
      en: {
        download: "Download for free",
        modules: "Used in modules",
      },
    },
  },

  metaInfo() {
    return {
      title: this.product.title,
      description: this.product.intro,
      meta: [
        // Twitter Card
        {
          name: "twitter:card",
          content: "summary",
        },
        {
          name: "twitter:title",
          content: this.product.title,
        },
        {
          name: "twitter:description",
          content: this.product.intro,
        },
        // image must be an absolute path
        {
          name: "twitter:image",
          content: this.product.image,
        },
        // Facebook OpenGraph
        {
          property: "og:title",
          content: this.product.title,
        },
        {
          property: "og:site_name",
          content: "T Gear",
        },
        {
          property: "og:type",
          content: "website",
        },
        {
          property: "og:image",
          content: this.product.image,
        },
        {
          property: "og:description",
          content: this.product.intro,
        },
      ],
    };
  },
};
</script>

<style lang="scss" scoped>
.software-product {
  .section-title {
    color: #4f4e4e;
    font-family: Apercu, Open Sans, Arial, Helvetica, sans-serif;
    font-size: $medium-title-font-size;
    margin-bottom: 20px;
    display: flex;
    align-items: center;
  }

  .product-content {
    margin: 50px 0;
  }

  .product-info {
    .back-btn {
      padding: 37px 0;
      color: #bebdbd;
      font-size: 1rem;
      font-weight: 300;
      display: inline-block;

      .fas {
        margin-right: 5px;
      }
    }

    .main-info-area {
      margin: 100px 0;
    }

    .img {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      max-width: 749px;
      margin: 0 auto;
    }

    h1 {
      color: #0088ce;
      font-size: $big-title-font-size;
    }

    .desc {
      color: #4f4e4e;
      font-size: $inline-font-size;
      display: inline-block;
      margin-bottom: 39px;
    }

    p {
      font-size: $small-title-font-size;
      color: #4f4e4e;
      margin-bottom: 20px;
      text-align: center;
      max-width: 734px;
      margin: 0 auto;
      margin-bottom: 50px;

      &.proberen {
        margin: 0 0 15px 0;
        font-size: $inline-font-size;
        text-align: left;
      }
    }

    h2 {
      color: #4f4e4e;
      font-size: $medium-title-font-size;
      text-align: center;
      max-width: 572px;
      margin: 0 auto;
      margin-bottom: 15px;
    }

    .download {
      background-color: #40a780;
      font-size: $inline-font-size;
      color: #fff;
      padding: 5px 27px;
      border-radius: 0.25rem;
      box-shadow: 0px 2px 2px #0000003d;
    }

    .price {
      color: #0088ce;
      font-size: $small-title-font-size;
      font-weight: bold;
    }

    .hr {
      border-bottom: 1px solid #bebdbd61;
    }

    .btn-more {
      color: #0088ce;
      font-size: $inline-font-size;
    }

    .margin-content-blocks {
      margin-bottom: 4.2rem;
    }
  }

  .section.video {
    .embed-responsive {
      margin-bottom: 50px;
    }
  }

  .specifications {
    margin-bottom: 150px;
  }

  .section.help {
    padding: 100px 0 100px 0;

    .section-title {
      margin-bottom: 80px;
      font-size: $medium-title-font-size;
      color: #4f4e4e;
    }

    .help-item {
      text-align: center;

      .image {
        margin-bottom: 70px;
      }

      .name {
        font-size: $small-title-font-size;
        font-weight: normal;
        color: #40a780;
        margin-bottom: 20px;
      }

      p {
        color: #4f4e4e;
        font-size: $inline-font-size;
        text-align: center;
        font-family: $main-font;
        font-weight: normal;
        line-height: $inline-line-height;
      }
    }

    .link {
      color: #0088ce;
      font-family: $main-font;
      font-size: $inline-font-size;
      font-weight: normal;
    }
  }

  .photo-slider {
    display: none;
  }

  .section.downloads {
    margin-bottom: 100px;

    .section-title {
      color: #4f4e4e;
      font-family: Apercu, Open Sans, Arial, Helvetica, sans-serif;
      font-size: $medium-title-font-size;
      font-weight: bold;
      margin-bottom: 20px;
      display: flex;
      align-items: center;

      img {
        margin-left: 15px;
      }
    }

    .modules-parts {
      font-size: $inline-font-size;
      color: #4f4e4e;
    }

    .downloads {
      color: #0088ce;
      font-size: $inline-font-size;
    }
  }

  .section.in-the-box {
    padding-bottom: 50px;

    ul {
      li {
        font-size: $inline-font-size;
        color: #4f4e4e;
        line-height: $inline-line-height;
      }
    }
  }

  .section.photo-gallery {
    padding-top: 100px;
  }

  .img {
    width: 100%;
    object-fit: cover;

    img {
      width: 100%;
    }
  }
}

@media (max-width: 768px) {
  .container {
    padding-left: 30px;
    padding-right: 30px;
  }

  .software-product {
    .product-info {
      .desc {
        margin-bottom: 0;
      }

      .back-btn {
        padding: 15px 0;
      }
      h1 {
        font-size: $small-title-font-size;
      }

      .price {
        font-size: $inline-font-size;
      }

      .price-wrapper {
        align-items: center;
        display: flex;
        flex-direction: column;
      }

      .download {
        font-size: $navigation-font-size;
      }

      h2 {
        font-size: $smallest-title-font-size;
        margin-bottom: 35px;
        margin-top: 35px;
        font-weight: 800;
      }

      p {
        font-size: $navigation-font-size;
        margin-bottom: 35px;
        margin-top: 35px;
      }

      .yellow-title {
        color: #fdaa29;
      }

      .main-info-area {
        justify-items: center;
        align-items: center;
        display: flex;
        margin-bottom: 0;
        margin-top: 50px;
      }
    }

    .section-title {
      // font-size: 30px !important;
    }

    .section.help {
      padding-top: 65px;
      padding-bottom: 50px;

      .section-title {
        margin-bottom: 0;
      }

      .help-item {
        .name {
          font-size: $smallest-title-font-size;
        }

        .image {
          width: 200px;
          height: 200px;
          object-fit: cover;
          margin-left: auto;
          margin-right: auto;
          margin-top: 46px;

          img {
            width: 100%;
            height: 100%;
          }
        }

        p {
          font-size: $navigation-font-size;
        }
      }

      .link {
        font-size: $navigation-font-size;
      }
    }

    .section.downloads {
      margin-bottom: 50px;

      .downloads-wrapper {
        margin-bottom: 50px;
      }
    }

    .photo-gallery {
      display: none;
    }

    .photo-slider {
      display: flex;
    }

    .section.downloads .downloads,
    .section.downloads .modules-parts,
    .section.in-the-box ul li {
      font-size: $navigation-font-size;
    }

    .section.in-the-box {
      padding-bottom: 0;
    }

    .specifications {
      margin-bottom: 0;
    }
  }
}

.wrapper-specifications {
  .section-title {
    font-size: $small-title-font-size;
    color: #4f4e4e;
    margin: 50px 0;
  }

  .systems {
    display: flex;
    flex-direction: column;

    display: flex;
    line-height: $inline-line-height;
    color: #4f4e4e;
    font-size: $inline-font-size;
  }
}

@media (max-width: 768px) {
  .wrapper-specifications {
    .section-title {
      font-size: $smallest-title-font-size;
    }

    .systems {
      margin-bottom: 50px;
      margin-top: 0;

      line-height: 1.5rem;
      // font-size: 1rem;
    }
  }
}
</style>
