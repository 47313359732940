<template>
  <div class="page wrapper-over-t-gear" v-if="data && data.info">
    <div class="main header">
      <div class="container">
        <default-header />
      </div>
    </div>

    <section class="section title">
      <div class="container">
        <div class="row">
          <div class="col">
            <back-btn />
            <h1>{{ data.info.title }}</h1>
          </div>
        </div>
      </div>
    </section>

    <section class="section content">
      <div class="container">
        <div class="row">
          <div class="col">
            <div v-html="data.info.content"></div>
          </div>
        </div>
      </div>
    </section>

    <section class="section video">
      <div class="container">
        <div class="row">
          <div class="col-12">
            <div class="embed-responsive embed-responsive-16by9">
              <iframe
                class="embed-responsive-item"
                :src="
                  'https://www.youtube-nocookie.com/embed/' +
                  data.info.youtube_link
                "
                title="YouTube video player"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowfullscreen
              ></iframe>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section class="section content">
      <div class="container">
        <div class="row">
          <div class="col">
            <div v-html="data.info.content2"></div>
          </div>
        </div>
      </div>
    </section>

    <section class="section reviews">
      <div class="container">
        <div class="row">
          <div
            class="col-xl-4 col-lg-4 col-6 item"
            v-for="(review, index) in data.employee"
            :key="index"
          >
            <div class="review">
              <img :src="`${review.image}`" alt="alt" />
              <p>{{ review.title }}</p>
              <div>
                <b>{{ review.intro }}</b>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section class="section made-in-holland">
      <div class="container">
        <div class="row">
          <div class="col">
            <h1>
              <img src="/static/images/flower-icon.png" alt="alt" />
              {{ data.info.title2 }}
            </h1>
            <div v-html="data.info.quote"></div>
          </div>
        </div>
      </div>
    </section>

    <section>
      <default-footer />
    </section>
  </div>
</template>

<script>
import { BackBtn, DefaultHeader, DefaultFooter } from "@/components";
import { mapActions, mapState } from "vuex";

export default {
  name: "over-t-gear",

  components: {
    BackBtn,
    DefaultHeader,
    DefaultFooter,
  },

  metaInfo() {
    return {
      title: this.data.title,
      description: this.data.title2,
    };
  },

  data() {
    return {};
  },

  created() {
    this.getDataByUrl("/over-t-gear/");
  },

  computed: {
    ...mapState("data", ["data"]),
  },

  methods: {
    ...mapActions({
      getDataByUrl: "data/getDataByUrl",
    }),
  },
};
</script>

<style lang="scss">
.wrapper-over-t-gear {
  .section.content {
    h2 {
      font-size: $medium-title-font-size;
      color: #40a780;
      margin-top: 74px;
      margin-bottom: 15px;

      &:first-child {
        margin-top: 0;
      }
    }

    p {
      display: flex;
      color: #4f4e4e;
      line-height: $inline-line-height;
      font-size: $inline-font-size;

      img {
        margin: 30px auto;
        justify-self: center;
      }
    }
  }

  .section.video {
    .embed-responsive-item {
      border-radius: 0.5rem;
    }
  }

  .made-in-holland {
    background-color: #fcefe4;
    padding: 40px 0 100px 0;

    p {
      font-size: $inline-font-size;
      color: #4f4e4e;
      margin-bottom: 20px;

      img {
        margin-right: 50px;
      }
    }

    h1 {
      color: #d16419;
      font-weight: bold;
      font-size: 1.6rem !important;
      margin-bottom: 15px;
    }
  }
}

@media (max-width: 768px) {
  .wrapper-over-t-gear {
    .section.title {
      h1 {
        // font-size: 36px !important;
        margin-bottom: 20px;
      }
    }

    .section.content {
      h2 {
        margin-top: 15px !important;
        font-size: $small-title-font-size;
      }
    }

    .reviews {
      margin-bottom: 0 !important;

      .item {
        .review {
          img {
            width: 134px;
          }

          b,
          p {
            // font-size: 1rem !important;
            line-height: 24px !important;
          }
        }
      }
    }

    .made-in-holland {
      padding: 40px 0 50px 0;

      p {
        width: 100%;

        img {
          max-width: 100%;
          margin-top: 50px;
        }
      }
    }
  }
}
</style>

<style lang="scss" scoped>
.container {
  padding-left: 30px;
  padding-right: 30px;
}

.wrapper-over-t-gear {
  .header {
    background: #f4f4f4;
  }

  .section.title {
    h1 {
      color: #fdaa20;
      font-size: $big-title-font-size;
      @media (max-width: 768px) {
        font-size: $medium-title-font-size;
      }
    }
  }

  .reviews {
    margin-bottom: 100px;

    .item {
      margin: 3.125rem 0;
      text-align: center;
      @media (max-width: 576px) {
        margin: 2.5rem 0;
      }
      @media (max-height: 500px) {
        margin: 2.5rem 0;
      }

      .review {
        color: #4f4e4e;
        font-size: $inline-font-size;

        img {
          max-width: 15rem;
        }

        p {
          font-size: $inline-font-size;
          line-height: $inline-line-height;
          margin-top: 20px;
          font-weight: 700;
        }

        b {
          font-size: $inline-font-size;
          line-height: $inline-line-height;
          font-weight: 500;
        }
      }
    }
  }

  h1 {
    font-size: $smallest-title-font-size;
    display: flex;
    align-items: center;

    img {
      margin-right: 15px;
    }
  }

  p {
    font-size: $navigation-font-size;
  }
}
</style>
