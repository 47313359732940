<template>
  <div :class="{'overflow-hidden': hidden}">
    <transition :name="transitionName" @leave="startTransition">
      <router-view :key="$root.$i18n.locale"></router-view>
    </transition>
  </div>
</template>
<script>
import api from "../api/index";
import { mapActions } from "vuex";

export default {
  name: "lang",

  mounted() {
    this.loadPagewideData()
  },

  data() {
    return {
      hidden: false,
      transitionName: "slide-right",
    };
  },

  watch: {
    $route(to, from) {
      if (to.meta.index > from.meta.index) {
        this.transitionName = "slide-left";
      } else {
        this.transitionName = "slide-right";
      }
    },
    '$i18n.locale'() {
      this.loadPagewideData()
    }
  },

  methods: {
    ...mapActions({
      setTags: "setTags",
      setMenu: "setMenu",
      setEducation: "setEducation",
    }),

    startTransition() {
      this.hidden = true;
      setTimeout(() => {
        this.hidden = false;
      }, 500);
    },

    loadPagewideData() {
      api.getDataByUrl("/level/").then(
        function (thing) {
          this.setTags(thing.data.tags);
          this.setEducation(thing.data.education);
          this.setMenu(thing.data.menu);
        }.bind(this)
      );
    }
  },
};
</script>
