<template>
  <router-link :to="{ name: url, params: { slug: data.slug } }">
    <div class="product-item-wrapper">
      <div class="image">
        <span class="price" v-if="data.price">€{{ data.price }}</span>
        <span class="learn" v-if="data.learn">
          <img src="/static/images/learn-icon.png" alt="learn" />
        </span>
        <img :src="data.image" alt="image" />
      </div>
      <div class="info">
        <h1 :style="{ color: color }">{{ data.title }}</h1>
        <!-- <p> -->
        <!--   {{ data.intro }} -->
        <!-- </p> -->
        <!-- <div class="d-flex"> -->
        <!--   <span class="more-btn" :style="{ color: color }"> -->
        <!--     {{ $tc("more") }} -->
        <!--     <i class="fas fa-chevron-right"></i> -->
        <!--   </span> -->
        <!-- </div> -->
      </div>
    </div>
  </router-link>
</template>

<script>
export default {
  name: "product-item",

  props: {
    data: {
      type: Object,
      default: () => ({}),
    },
    color: {
      type: String,
      default: "",
    },
    productType: {
      type: String,
      default: "",
    },
  },

  i18n: {
    messages: {
      nl: {
        more: "Lees meer",
      },
      en: {
        more: "Read more",
      },
    },
  },

  data() {
    return {
      more: false,
    };
  },

  methods: {
    onClickMore() {
      this.more = !this.more;
    },
  },

  computed: {
    url() {
      return this.productType + "-details";
    },
  },
};
</script>

<style lang="scss" scoped>
.product-item-wrapper {
  margin-top: 70px;
  display: flex;
  flex-direction: column;

  .image {
    position: relative;
    background-color: #e9e9e9;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    width: 356.65px;
    height: 356.65px;

    img {
      // object-fit: cover;
      object-fit: contain;
      width: 100%;
      height: 100%;
    }

    .price {
      position: absolute;
      right: 15px;
      top: 15px;
      background: #ffffff;
      border-radius: 5px;
      padding: 7px 20px;
      color: #4f4e4e;
      font-size: $inline-font-size;
      font-weight: 300;
    }

    .learn {
      position: absolute;
      right: 15px;
      bottom: 15px;
    }
  }

  .info {
    span {
      margin-left: auto;
    }
  }

  h1 {
    font-size: $smallest-title-font-size;
    font-weight: 400;
    padding: 10px 0;
    height: 3em; /* 1em for each visible line */
  }

  p {
    color: #bebdbd;
    font-size: $inline-font-size;
    padding-bottom: 15px;
    height: 6em; /* 1em for each visible line */
    overflow: hidden;
  }

  .more-btn {
    font-size: $inline-font-size;
  }
}

@media (max-width: 1200px) {
  .product-item-wrapper {
    .image {
      width: 289.983px;
      height: 289.983px;
    }
  }
}

@media (max-width: 1024px) {
  .product-item-wrapper {
    .image {
      width: 256px;
      min-width: 256px;
      height: 257px;
      // background-color: transparent;

      width: 289.983px;
      min-width: 289.983px;
      height: 289.983px;

      img {
        width: 100%;
        height: 100%;
      }
    }

    .info {
      h1 {
        // font-size: 26px;
      }

      .more-btn,
      p {
        // font-size: 23px;
      }
    }
  }
}

@media (max-width: 992px) {
  .product-item-wrapper {
    flex-direction: row;
    align-items: center;
    padding-bottom: 50px;
    padding-top: 50px;
    border-bottom: 1px solid #bebdbd;

    .image {
      background-color: transparent;
      .price {
        // font-size: 1rem;
        // background-color: transparent;
        right: 5px;
        top: 0.25rem;
        padding: 5px;
      }

      .learn {
        width: 25px;
        height: 25px;
        right: 7px;
        bottom: 5px;
      }
    }

    .info {
      align-self: center;
      margin-left: 25px;

      span {
        margin-left: 0;
      }

      h1 {
        font-size: $navigation-font-size;
        padding-top: 0;
      }

      .more-btn,
      p {
        // font-size: 1rem;
      }
    }
  }
}

@media (max-width: 425px) {
  .product-item-wrapper {
    padding-bottom: 25px;
    padding-top: 25px;

    .image {
      width: 102px;
      min-width: 102px;
      height: 102px;

      .price {
        padding: 3px;
        // font-size: 14px;
      }
    }

    .info {
      h1 {
        // font-size: 1rem;
      }

      p {
        // font-size: 14px;
      }
    }
  }
}
</style>
