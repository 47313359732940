<template>
  <div class="page gear-product">
    <LightBox
      :media="product.gallery"
      :showLightBox="false"
      ref="lightbox"
    ></LightBox>

    <section class="product-info">
      <div class="container">
        <div class="row">
          <div class="col">
            <a href="#" class="back-btn" @click.prevent="$router.go(-1)">
              <i class="fas fa-chevron-left"></i>
              {{ $tc("back") }}
            </a>
            <h1>{{ product.title }}</h1>
            <span class="desc"
              >{{ $t("learnline") }} {{ product.learnlineName }}</span
            >
          </div>
        </div>
        <div class="row main-info-area d-flex justify-content-between">
          <div class="col-xl-9 col-lg-9">
            <div class="img ratio ratio-1x1">
              <img :src="product.image" alt="alt" />
            </div>
          </div>
          <div class="col-xl-3 col-gl-3 price-wrapper">
            <div>
              <div class="price">€ {{ product.price }}</div>
              <div>
                <p class="proberen">{{ $tc("try") }}</p>
              </div>
              <div>
                <router-link :to="{ name: 'contact' }" class="download">{{
                  $tc("contact")
                }}</router-link>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <fancy-blocks class="col" :content="product.content" />
        </div>
      </div>
    </section>

    <section class="section video" v-if="product.vimeo !==''">
      <div class="container">
        <div class="row">
          <div class="col-12">
            <div class="embed-responsive embed-responsive-16by9">
              <iframe
                class="embed-responsive-item"
                :src="`https://www.youtube-nocookie.com/embed/${product.vimeo}`"
                title="YouTube video player"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowfullscreen
              ></iframe>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section class="section photo-gallery">
      <div class="container">
        <div class="grid-layout grid5items">
          <div
            v-for="(item, index) in product.gallery"
            :key="index"
            href="/"
            class="grid-item d-flex align-items-end justify-content-center"
            :style="`background-image: url('${whichImage(
              item,
              index
            )}');cursor: pointer;`"
            @click="showLightBox(index)"
          >
            <a>
              <img
                src="/static/images/magnify-icon.svg"
                alt="magnify"
              />
            </a>
          </div>
        </div>
      </div>
    </section>

    <section
      class="section slider photo-slider"
      v-if="product.gallery && product.gallery.length"
    >
      <div class="container">
        <div class="row">
          <div class="col">
            <slider :slides="product.gallery" :show="true" :minimize="true" />
          </div>
        </div>
      </div>
    </section>

    <section class="section specifications mt-5">
      <div class="container">
        <div class="row">
          <div class="col">
            <div class="row">
              <div class="col">
                <h1 class="section-title">{{ $t("specifications") }}</h1>
              </div>
            </div>
            <div class="row">
              <div class="col-12">
                <div class="responsive-table" v-html="product.specs" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section class="section downloads">
      <div class="container">
        <div class="row">
          <!-- downloads -->
          <div
            class="col-xl-6 col-lg-6 downloads-wrapper"
            v-if="product.downloads"
          >
            <h1 class="section-title">
              Downloads
              <img src="/static/images/download-icon.png" alt="img" />
            </h1>
            <p v-for="download in product.downloads" :key="download.link">
              <a :href="download.link" class="downloads">{{ download.name }}</a>
            </p>
          </div>
          <!-- gebruikt in modules -->
          <div class="col-xl-6 col-lg-6">
            <h1 class="section-title">{{ $tc("usedmodules") }}</h1>
            <div class="modules-parts">
              {{ usedInModules }}
            </div>
          </div>
        </div>
      </div>
    </section>

    <!-- in the box -->
    <section class="section in-the-box">
      <div class="container">
        <div class="row">
          <div class="col">
            <h1 class="section-title">In the box</h1>
          </div>
        </div>
        <div class="row">
          <div class="col" v-html="product.inthebox" />
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { FancyBlocks } from "@/components";
import LightBox from "vue-image-lightbox";
import Slider from "../components/Slider";
require("../lightbox.css");

export default {
  name: "gear-product",

  components: {
    LightBox,
    FancyBlocks,
    Slider,
  },

  props: {
    product: {
      type: Object,
      default: () => ({}),
    },
  },

  data() {
    return {
      imagesloaded: 0,
    };
  },

  computed: {
    usedInModules() {
      var list = "";
      this.product.modules.forEach((module) => {
        list += module.title + ", ";
      });
      return list.substring(0, list.length - 2);
    },
    ...mapState("data", ["data"]),
  },

  methods: {
    imageLoaded() {
      this.imagesloaded += 1;
      if (this.imagesloaded === this.product.gallery.length) {
        this.$redrawVueMasonry("photos");
      }
    },

    whichImage(item, index) {
      var indexThingy = [
        "img_slide",
        "thumb",
        "thumb",
        "thumb",
        "img_slide",
        "thumb",
        "img_slide",
        "thumb",
        "thumb",
        "thumb",
      ];

      if (indexThingy[index]) {
        return item[indexThingy[index]];
      }

      return item.small;
    },

    showLightBox(index) {
      this.$refs.lightbox.showImage(index);
    },
  },

  i18n: {
    messages: {
      nl: {
        contact: "Neem contact op",
        usedmodules: "Gebruikt in de vakken",
        try: "Proberen?",
      },
      en: {
        contact: "Contact us",
        usedmodules: "Used in modules",
        try: "Try it out?",
      },
    },
  },

  metaInfo() {
    return {
      title: this.product.title,
      description: this.product.intro,
      meta: [
        // Twitter Card
        { name: "twitter:card", content: "summary" },
        { name: "twitter:title", content: this.product.title },
        { name: "twitter:description", content: this.product.intro },
        // image must be an absolute path
        { name: "twitter:image", content: this.product.image },
        // Facebook OpenGraph
        { property: "og:title", content: this.product.title },
        { property: "og:site_name", content: "T Gear" },
        { property: "og:type", content: "website" },
        { property: "og:image", content: this.product.image },
        { property: "og:description", content: this.product.intro },
      ],
    };
  },
};
</script>

<style lang="scss" scoped>
.gear-product {
  table {
    td {
      vertical-align: top;
    }
  }

  .description {
    h2 {
      color: #d16419;
      font-size: $big-title-font-size;
    }
  }

  .section-title {
    font-family: Apercu, Open Sans, Arial, Helvetica, sans-serif;
    font-size: $small-title-font-size;
    color: #4f4e4e;
    margin: 50px 0;
  }

  p {
    // font-size: $inline-font-size;
    color: #4f4e4e;
    font-family: $main-font;
    font-weight: normal;
    line-height: $inline-line-height;
  }

  .price-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .product-info {
    .back-btn {
      padding: 37px 0;
      color: #bebdbd;
      font-size: 1rem;
      font-weight: 300;
      display: inline-block;

      .fas {
        margin-right: 5px;
      }
    }

    .main-info-area {
      margin: 50px 0;
    }

    .img {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
    }

    h1 {
      color: #d16419;
      font-size: $big-title-font-size;
    }

    .desc {
      color: #4f4e4e;
      font-size: $inline-font-size;
      display: inline-block;
      margin-bottom: 39px;
    }

    p {
      // font-size: $small-title-font-size;
      margin-bottom: 20px;
      text-align: center;
      max-width: 734px;
      margin: 0 auto;
      margin-bottom: 150px;

      &.proberen {
        margin: 0 0 15px 0;

        // font-size: $inline-font-size;
        text-align: left;
      }
    }

    // TODO change padding top and bottom from .download class the green button. This should probably been done overaal site
    .download {
      background-color: #40a780;
      // font-size: $inline-font-size;
      color: #fff;
      padding: 10px 27px;
      border-radius: 4px;
      box-shadow: 0px 2px 2px #0000003d;
    }

    .price {
      color: #0088ce;
      font-size: $small-title-font-size;
      font-weight: bold;
    }

    .hr {
      border-bottom: 1px solid #bebdbd61;
    }

    .btn-more {
      color: #0088ce;
      font-size: $inline-font-size;
    }
  }

  .specifications {
    margin-bottom: 150px;
  }

  .section.help {
    padding: 100px 0 100px 0;

    .help-item {
      text-align: center;

      .image {
        margin-bottom: 70px;
      }

      .name {
        font-size: $small-title-font-size;
        font-weight: normal;
        color: #40a780;
        margin-bottom: 20px;
      }

      p {
        text-align: center;
      }
    }

    .link {
      color: #0088ce;
      font-family: $main-font;
      font-size: $inline-font-size;
      font-weight: normal;
    }
  }

  .section.downloads {
    margin-bottom: 100px;

    .section-title {
      margin-bottom: 20px;
      display: flex;
      align-items: center;

      img {
        margin-left: 15px;
      }
    }

    .modules-parts {
      font-size: $inline-font-size;
      color: #4f4e4e;
    }

    .downloads {
      color: #0088ce;
      font-size: $inline-font-size;
    }
  }

  .section.in-the-box {
    // padding-bottom: 50px;

    color: #4f4e4e;
  }

  .section.video {
    .embed-responsive {
      margin-bottom: 100px;
      @media (max-width: 768px) {
        margin-bottom: 50px;
      }
    }
  }

  .section.photo-gallery {
    // padding-top: 100px;

    .grid-layout {
      display: grid;
      grid-template-columns: repeat(auto-fill, minmax(15rem, 1fr));
      grid-gap: 40px;
      grid-auto-rows: minmax(15rem, auto);
      grid-auto-flow: dense;
      margin-bottom: 5px;
    }
    .grid-item {
      padding: 1rem;
      font-size: 14px;
      font-weight: bold;
      color: white;
      background-color: $primary;
      background-size: cover;
      background-position: center;
      grid-row-end: span 1;
      grid-column-end: span 1;

      a {
        display: flex;
        justify-content: flex-end;
        align-items: flex-start;
        width: 100%;
        height: 100%;
        color: red;
        text-align: end;
      }
    }

    .grid5items {
      .grid-item:nth-child(1) {
        grid-row-end: span 2;
        grid-column-end: span 2;
      }
    }
  }

  .img {
    width: 100%;
    object-fit: cover;

    img {
      width: 100%;
    }
  }
}

.wrapper-specifications {
  .section-title {
    margin: 50px 0;
  }

  .systems {
    display: flex;
    flex-direction: column;

    display: flex;
    line-height: $inline-line-height;
    color: #4f4e4e;
    font-size: $inline-font-size;
    line-height: $inline-line-height;
  }
}

.photo-slider {
  display: none;
}

@media (max-width: 768px) {
  .photo-slider {
    display: block;
  }

  .wrapper-specifications {
    .section-title {
      font-size: $smallest-title-font-size;
    }

    .systems {
      margin-bottom: 50px;
      margin-top: 0;

      line-height: 1.5rem;
      // font-size: 1rem;
    }
  }
}

@media (max-width: 768px) {
  .container {
    padding-left: 30px;
    padding-right: 30px;
  }

  .gear-product {
    .product-info {
      .desc {
        margin-bottom: 0;
      }

      .back-btn {
        padding: 15px 0;
      }
      h1 {
        font-size: $small-title-font-size;
      }

      .price {
        font-size: $inline-font-size;
      }

      .price-wrapper {
        align-items: center;
        display: flex;
        flex-direction: column;
        margin-bottom: 4rem;
      }

      .download {
        font-size: $navigation-font-size;
      }

      h2 {
        font-size: $smallest-title-font-size;
        margin-bottom: 35px;
        margin-top: 35px;
        font-weight: 800;
      }

      p {
        font-size: $navigation-font-size;
        margin-bottom: 35px;
        margin-top: 35px;
      }

      .yellow-title {
        color: #fdaa29;
      }

      .main-info-area {
        justify-items: center;
        align-items: center;
        display: flex;
        margin-bottom: 0;
        margin-top: 50px;
      }
    }

    .section-title {
      // font-size: 30px !important;
    }

    .section.help {
      padding-top: 65px;
      padding-bottom: 50px;

      .section-title {
        margin-bottom: 0;
      }

      .help-item {
        .name {
          font-size: $smallest-title-font-size;
        }

        .image {
          width: 200px;
          height: 200px;
          object-fit: cover;
          margin-left: auto;
          margin-right: auto;
          margin-top: 46px;

          img {
            width: 100%;
            height: 100%;
          }
        }

        p {
          font-size: $navigation-font-size;
        }
      }

      .link {
        font-size: $navigation-font-size;
      }
    }

    .section.downloads {
      margin-bottom: 50px;

      .downloads-wrapper {
        margin-bottom: 50px;
      }
    }

    .photo-gallery {
      display: none;
    }

    .photo-slider {
      display: flex;
    }

    .section.downloads .downloads,
    .section.downloads .modules-parts,
    .section.in-the-box ul li {
      font-size: $navigation-font-size;
    }

    .section.in-the-box {
      padding-bottom: 0;
    }

    .specifications {
      margin-bottom: 0;
    }
  }
}
</style>
