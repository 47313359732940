<template>
  <div
    class="filter-mobile-wrapper"
    :class="{ show: show }"
    @click.prevent="onClose"
  >
    <a href="#" class="btn-close" @click.prevent="onClose">
      <img src="/static/images/times-icon.png" alt="alt" />
    </a>
    <div class="body" @click.stop="() => {}">
      <div class="title">
        <img src="/static/images/filter-icon.png" alt="filter-icon" />
        <span>{{
          isChangedFilter ? "Filter de resultaten" : "Resultaten gefilterd"
        }}</span>
      </div>
      <div class="mb-3"></div>
      <div class="container" v-if="data.module">
        <div class="row filters-row">
          <div class="col-12">
            <div class="filter-box">Leerlijn</div>
            <div class="filters">
              <div
                class="filter-item"
                v-for="(filter, index) in data.education"
                :key="index"
              >
                <checkbox
                  v-model="value['learnline'][filter.id]"
                  :id="`filter-${filter.title}-mobile`"
                />
                <label :for="`filter-${filter.title}-mobile`">{{
                  filter.title
                }}</label>
              </div>
            </div>
          </div>
          <div class="col-12">
            <div class="filter-box">Module</div>
            <div class="row">
              <div class="col">
                <div class="filters mb-5">
                  <div
                    class="filter-item"
                    v-for="(filter, index) in data.module.slice(
                      0,
                      !more ? 5 : data.module.length
                    )"
                    :key="index"
                  >
                    <checkbox
                      v-model="value['module'][filter.id]"
                      :id="`filter-${filter.title}-mobile-modules`"
                    />
                    <label :for="`filter-${filter.title}-mobile-modules`">{{
                      filter.title
                    }}</label>
                  </div>
                  <div class="filter-item" v-if="!more">
                    <a href="#" class="btn-more" @click.prevent="onClickMore"
                      >Meer</a
                    >
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-12">
            <button class="btn-filter" @click.prevent="onClickFilter">
              Filter
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { filter as filterMixin } from "@/mixins";
import CheckBox from "../inputs/CheckBox";
import { mapState } from "vuex";

export default {
  name: "filter-mobile-results",

  components: {
    checkbox: CheckBox,
  },

  props: {
    show: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      value: {},
    };
  },

  mixins: [filterMixin],

  methods: {
    clear() {
      this.value = {
        module: {},
        learnline: {},
      };
    },
    onClickFilter() {
      this.$emit("filter", this.value);
    },
    onClose() {
      this.$emit("close");
    },
  },

  computed: {
    ...mapState("data", ["data"]),
  },
};
</script>

<style lang="scss" scoped>
.filter-mobile-wrapper {
  position: fixed;
  background: rgba(0, 0, 0, 0.4);
  z-index: 999;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  transform: translateY(-100%);
  transition: transform 0.3s;
  display: flex;
  flex-direction: column;
  overflow: auto;

  &.show {
    transform: translateY(0);
  }

  .btn-close {
    width: 57px;
    min-height: 57px;
    height: 57px;
    margin: 14px 14px 14px auto;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    background: rgba(0, 0, 0, 0.6);
    color: #fff;
  }

  .body {
    width: 100%;
    margin-top: auto;
    margin-top: 80px;
    background-color: #fff;
    padding-bottom: 30px;

    .btn-more {
      color: #0088ce;
      font-size: $inline-font-size;
      margin-left: 40px;
    }

    .title {
      padding: 9px 20px;
      display: flex;
      align-items: center;
      box-shadow: 0px 0px 6px #00000029;
      font-size: $navigation-font-size;
      color: #4f4e4e;

      img {
        margin-right: 13px;
      }
    }

    .filter-box {
      background-color: #e9e9e9;
      border-radius: 5px;
      padding: 10px 38px;
      color: #4f4e4e;
      font-weight: 600;
    }

    .filters {
      display: flex;
      flex-direction: column;
      // margin: 20px 0;
      margin-top: 1rem;
      margin-bottom: 2rem;

      .filter-item {
        font-size: $inline-font-size;
        color: #4f4e4e;
        display: flex;
        align-items: center;
        user-select: none;
        padding-bottom: 0.5rem;

        input[type="checkbox"] {
          width: 25px;
          height: 25px;
          border-color: #4f4e4e;
          background-color: #f4f4f4;
          margin-right: 13px;
        }
      }
    }

    .btn-filter {
      width: 100%;
      background-color: #40a780;
      color: #fff;
      padding: 15px 100px;
      border: none;
      border-radius: 5px;
      font-size: $navigation-font-size;
    }
  }
}

@media (max-width: 768px) {
  .filter-mobile-wrapper {
    .container {
      padding-left: 30px;
      padding-right: 30px;
    }
  }
}

.checkbox {
  margin-top: 0.2rem;
  @media (max-width: 768px) {
    padding-bottom: 0;
  }
}
</style>
