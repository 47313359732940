<template>
  <div class="page advantages" v-if="data.info">
    <div class="main header">
      <div class="container">
        <default-header />
      </div>
    </div>

    <section class="section title">
      <div class="container">
        <div class="row">
          <div class="col">
            <h1>{{ data.info.title }}</h1>
            <p>{{ data.info.intro }}</p>
          </div>
        </div>
      </div>
    </section>

    <section class="section advantages">
      <div class="container">
        <div class="row">
          <div
            class="col-xl-4 col-lg-4 help-item"
            v-for="(advantage, index) in data.advantages"
            :key="index"
          >
            <div v-if="advantage.showimage">
              <div class="image rounded-image">
                <img :src="advantage.image.image" :alt="advantage.title" />
              </div>
              <h2 class="name">{{ advantage.title }}</h2>
              <p v-html="advantage.intro"></p>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section class="section video" v-if="data.info.youtube_link">
      <div class="container">
        <div class="row">
          <div class="col-12">
            <div class="embed-responsive embed-responsive-16by9">
              <iframe
                class="embed-responsive-item"
                :src="
                  'https://www.youtube-nocookie.com/embed/' +
                  data.info.youtube_link
                "
                title="YouTube video player"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowfullscreen
              ></iframe>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section class="section quote">
      <div class="container">
        <div class="row">
          <div class="col">
            <div class="quote">
              <div class="quote-icon">
                <img src="/static/images/quote-icon.png" alt="alt" />
              </div>
              <div v-html="data.info.quote" />
            </div>
          </div>
        </div>
      </div>
    </section>

    <section class="section more-advantages">
      <div class="container">
        <div class="row">
          <div class="col">
            <h1 class="section-title">{{ data.info.title2 }}</h1>
          </div>
        </div>
        <div class="row">
          <div
            v-for="(advantage, index) in data.advantages"
            :key="index"
            class="col-12"
          >
            <div v-if="!advantage.showimage" class="advantage-item">
              <div class="title">{{ advantage.title }}</div>
              <p>{{ advantage.intro }}</p>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section class="section convinced">
      <div class="container">
        <div class="row">
          <div class="col offset-xl-1">
            <convinced-section />
          </div>
        </div>
      </div>
    </section>

    <default-footer />
  </div>
</template>

<script>
import { DefaultHeader, DefaultFooter, ConvincedSection } from "@/components";
import { mapActions, mapState } from "vuex";

import { common as commonMixin } from "@/mixins";

export default {
  name: "advantages",

  components: {
    DefaultHeader,
    DefaultFooter,
    ConvincedSection,
  },

  metaInfo() {
    return {
      title: "Voordelen",
      description: this.data.info.intro,
    };
  },

  data() {
    return {};
  },

  created() {
    this.getDataByUrl("/voordelen/");
  },

  mixins: [commonMixin],

  methods: {
    ...mapActions({
      getDataByUrl: "data/getDataByUrl",
    }),
  },

  computed: {
    ...mapState("data", ["data"]),
  },
};
</script>

<style lang="scss" scoped>
.header {
  background: #f4f4f4 0% 0% no-repeat padding-box;
}

.section {
  .section-title {
    font-size: $medium-title-font-size;
    color: #4f4e4e;
    font-weight: bold;
  }
}

.section.title {
  margin-top: 106px;
  margin-bottom: 80px;

  h1 {
    font-size: $big-title-font-size;
    color: #ce1f27;
    // margin-bottom: 20px;
    @media (max-width: 768px) {
      font-size: $medium-title-font-size;
    }
  }

  p {
    color: #4f4e4e;
    font-size: $inline-font-size;
  }
}

.section.advantages {
  padding: 50px 0;

  .section-title {
    margin-bottom: 80px;
  }

  .help-item {
    text-align: center;

    .image {
      margin-bottom: 70px;
      @media (max-width: 992px) {
        margin-bottom: 30px;
      }
    }

    .name {
      font-size: $small-title-font-size;
      font-weight: normal;
      color: #40a780;
      margin-bottom: 20px;
    }

    p {
      color: #4f4e4e;
      font-size: $inline-font-size;
      text-align: center;
      font-family: $main-font;
      font-weight: normal;
      line-height: $inline-line-height;
      @media (max-width: 992px) {
        margin-bottom: 70px;
      }
    }
  }

  .link {
    color: #0088ce;
    font-family: $main-font;
    font-size: $inline-font-size;
    font-weight: normal;
  }
}

.section.video {
  padding: 50px 0;
  .embed-responsive iframe {
    border-radius: 0.5rem;
  }
}

.section.more-advantages {
  // margin-bottom: 100px;

  .section-title {
    margin-bottom: 50px;
  }

  .advantage-item {
    margin-left: 6.25rem;
    margin-bottom: 30px;
    position: relative;
    @media (max-width: 768px) {
      margin-left: 2.4rem;
    }
    @media (max-width: 576px) {
      margin-left: 2.2rem;
    }

    &:before {
      content: "";
      background-image: url("/static/images/check-advantages-icon.png");
      background-position: center center;
      background-repeat: no-repeat;
      background-size: contain;
      width: 1.3rem;
      height: 1.3rem;
      position: absolute;
      left: -2.3rem;
      top: 0.4rem;
      @media (max-width: 576px) {
        // width: 1.8rem;
        // height: 1.8rem;
        left: -2.1rem;
        top: 0.1rem;
      }
    }

    .title {
      font-size: $small-title-font-size;
      color: #40a780;
      @media (max-width: 576px) {
        font-size: $inline-font-size;
        font-weight: 300;
      }
    }

    p {
      color: #4f4e4e;
      font-size: $inline-font-size;
      line-height: $inline-line-height;
    }
  }
}

.section.quote {
  margin-bottom: 100px;

  .quote {
    background-color: #f7fbf9;
    padding: 2.5rem;
    color: #4f4e4e;
    font-size: $inline-font-size;

    .quote-icon {
      margin-bottom: 1rem;
    }
  }
}

.section.convinced {
  margin-bottom: 100px;

  .convinced {
    margin-left: 70px;
  }
}

/*
@media (max-width: 992px) {
    .section.section.advantages {
        .help-item {
            .image {
                margin-bottom: 30px;
            }

            p {
                margin-bottom: 70px;
            }
        }
    }
}

@media (max-width: 768px) {
    .container {
        padding-left: 30px;
        padding-right: 30px;
    }

    .section {
        .section-title {
            font-size: $smallest-title-font-size;
        }
    }

    .section.title {
        margin-top: 103px;
        margin-bottom: 33px;

        h1 {
            color: #fdaa20;
            font-size: $small-title-font-size;
        }

        p {
            font-size: $navigation-font-size;
        }
    }

    .section.advantages {
        padding-top: 33px;

        .section-title {
            margin-bottom: 0;
        }

        .help-item {
            .name {
                font-size: $smallest-title-font-size;
                margin-bottom: 0;
            }

            .image {
                width: 200px;
                height: 200px;
                object-fit: cover;
                margin-left: auto;
                margin-right: auto;
                margin-top: 46px;
                margin-bottom: 30px !important;

                img {
                    width: 100%;
                    height: 100%;
                }
            }

            p {
                font-size: $navigation-font-size;
                margin-bottom: 20px !important;
            }
        }

        .link {
            font-size: $navigation-font-size;
        }
    }

    .section.more-advantages {
        margin-bottom: 50px;

        .advantage-item {
            margin-left: 40px;

            &:before {
                top: 0;
            }

            p,
            .title {
                font-size: $navigation-font-size;
                line-height: 26px;
            }
        }
    }

    .section.quote {
        .quote {
            padding: 30px 20px;

            img {
                width: 35px;
                height: 35px;
            }

            b,
            p {
                font-size: $navigation-font-size;
                line-height: 26px;
            }
        }
    }

    .section.convinced {
        .convinced {
            margin-left: 0;
            font-size: $navigation-font-size;
            line-height: 26px;
        }
    }
}

*/
</style>
