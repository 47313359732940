<template>
  <div class="checkbox">
    <div
      class="checkbox-custom-input-wrapper"
      :class="{ 'checkbox-custom-input-wrapper--checked': value }"
    >
      <input
        :id="id"
        class="checkbox-hidden-input"
        type="checkbox"
        :checked="value"
        @change="onInput($event)"
      />
      <span
        v-if="value"
        class="checkbox-custom-input"
        :class="{ 'checkbox-custom-input--checked': value }"
      >
        &#x2713;
        <!-- tickmark -->
      </span>
    </div>
    <label class="label" :for="id">
      <slot />
    </label>
  </div>
</template>

<script>
export default {
  name: "checkbox",

  props: {
    id: {
      type: String,
      default: "",
    },
    value: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {};
  },

  methods: {
    onInput(event) {
      this.$emit("input", event.target.checked);
    },
  },
};
</script>

<style lang="scss">
.checkbox {
  display: flex;
}

.checkbox {
  margin-top: 0.2rem;
  @media (max-width: 768px) {
    padding-bottom: 0.5rem;
  }
}

input[type="checkbox"] {
  width: 20px;
  height: 20px;
  border-color: #4f4e4e;
  background-color: #f4f4f4;
  margin-right: 13px;
}

.checkbox-custom-input-wrapper {
  position: relative;
  border: 1px solid #4f4e4e;
  height: 20px;
  width: 20px;
  border-radius: 0.25rem;
  margin-top: 0.35rem;
  @media (min-width: 1280px) {
    height: 25px;
    width: 25px;
    margin-top: 0.25rem;
  }
  @media (max-width: 992px) {
    margin-top: 0.25rem;
  }
  @media (max-width: 768px) {
    height: 25px;
    width: 25px;
    margin-top: 0;
  }
}

.checkbox-custom-input-wrapper--checked {
  border-color: #fff;
}

.checkbox-custom-input {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  background-color: #0088ce;
  color: white;
  font-weight: 400;
}

.checkbox-custom-input--checked {
  background-color: #0088ce;
  width: 20px;
  height: 20px;
  border-radius: 0.25rem;
  // font-size: 1rem;
  @media (min-width: 1280px) {
    height: 25px;
    width: 25px;
  }
  @media (max-width: 768px) {
    height: 25px;
    width: 25px;
  }
}

.checkbox-hidden-input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  width: 100%;
  height: 100%;
  margin: 0;
}

.label {
  cursor: inherit;
  margin-left: 8px;
  font-size: 1.2rem;
  max-width: 90%;
  @media (max-width: 992px) {
    max-width: 80%;
  }
}
</style>
