import Vue from 'vue'
import VueI18n from 'vue-i18n'


Vue.use(VueI18n);

const messages = {
  en: {
    reusable: {
      months: 'fast | one month | {count} months'
    },
    learnline: "Learning pathway",
    specifications: "Specifications",
    description: "Description",
    back: 'back',
  },
  nl: {
    reusable: {
      months: 'snel | één maand | {count} mnd'
    },
    learnline: "Leerlijn",
    specifications: "Specificaties",
    description: "Omschrijving",
    back: 'terug',
  }
}

export default new VueI18n({
  locale: 'nl', // set locale
  messages, // set locale messages
  silentFallbackWarn: true,
})

