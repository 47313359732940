<template>
  <div class="input-wrapper" :class="{ value: payload, invalid: !localValid }">
    <span
      v-if="properties.label"
      class="label"
      :class="{ active: active || payload, value: payload }"
    >
      {{ properties.label[lang] }}
      <span v-if="properties.required">*</span>
    </span>
    <input
      type="text"
      v-model="payload"
      @focus="onFocus"
      @blur="onBlur"
      :placeholder="properties.placeholder[lang]"
      :name="id"
    />
  </div>
</template>

<script>
export default {
  name: "input-field",

  props: {
    id: {
      type: String,
    },
    properties: {
      type: Object,
    },
    value: {
      type: Boolean,
      default: true,
    },
  },

  data() {
    return {
      payload: '',
      active: false,
      localValid: true,
    };
  },

  watch: {
    'properties.validate': function () {
      this.validate();
    }
  },

  computed: {
    lang() {
      if(this.$i18n.locale == "nl") {
        return "nl";
      }
      return "gb"
    }
  },

  methods: {
    onFocus() {
      this.active = true;
    },
    onBlur() {
      this.active = false;
    },
    validate() {
      if(this.properties.required) {
        if(this.payload == "") {
          this.$emit("input", false);
          this.localValid = false;
        } else {
          this.$emit("input", true);
          this.localValid = true;
        }
      }
    }
  },
};
</script>

<style lang="scss" scoped>
.input-wrapper {
  position: relative;
  background-color: #e9e9e9;
  z-index: 1;
  margin-top: 30px;
  border-bottom: 3px solid #bebdbd;

  &.invalid {
    border-color: #ff1a00;
  }

  &.value {
    border-color: #fdaa29;
    background-color: #fffbf4;

    input {
      color: #4f4e4e;
    }
  }

  input {
    z-index: 2;
    width: 100%;
    border: none;
    background-color: transparent;
    color: #bebdbd;
    font-size: $inline-font-size;
    border-radius: 0;
    padding: 13px 20px;
    min-height: 58px;

    &:focus {
      outline: none;
    }
  }

  .label {
    z-index: -1;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 15px;
    color: #bebdbd;
    font-size: $inline-font-size;
    transition: 0.3s all;

    &.active {
      top: -15px;
      // font-size: 1rem;
    }

    &.value {
      color: #40a780;
    }
  }
}

@media (max-width: 768px) {
  .input-wrapper {
    input,
    .label {
      // font-size: 18px;
    }
  }
}
</style>
