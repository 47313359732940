<template>
  <div class="container">
    <form @submit="onSubmit" id="form" v-if="formSentCorrently == 'unsent'">
      <div class="row">
        <div v-for="field in fields" :key="field.id" :class="classWidth(field)">
          <checkbox-field
            v-if="field.type == 'checkbox' && field.properties"
            :properties="field.properties"
            :id="field.id"
            v-model="valid[field.id]"
          />

          <input-field
            v-if="field.type == 'text'"
            :properties="field.properties"
            :id="field.id"
            v-model="valid[field.id]"
          />

          <select-field
            v-if="field.type == 'dropdown'"
            :properties="field.properties"
            :id="field.id"
            v-model="valid[field.id]"
          />

          <textarea-field
            v-if="field.type == 'textarea'"
            :properties="field.properties"
            :id="field.id"
            v-model="valid[field.id]"
          />

          <email-field
            v-if="field.type == 'email'"
            :properties="field.properties"
            :id="field.id"
            v-model="valid[field.id]"
          />
        </div>
      </div>

      <div class="row">
        <div class="col d-flex">
          <button type="button" class="btn-contact" @click="onSubmit">
            {{ $tc("send") }}
          </button>
        </div>
      </div>
    </form>

    <div v-if="formSentCorrently == 'success'" style="margin-bottom: 150px">
      {{ $tc("succes") }}
    </div>

    <div v-if="formSentCorrently == 'fail'" style="margin-bottom: 150px">
      {{ $tc("fail") }}
    </div>
  </div>
</template>

<script>
import {
  SelectField,
  TextareaField,
  InputField,
  CheckboxField,
  EmailField,
} from "@/components";

import axios from "axios";
import Vue from "vue";

export default {
  components: {
    InputField,
    SelectField,
    TextareaField,
    CheckboxField,
    EmailField,
  },

  props: {
    confirm: {
      type: String,
      default: "",
    },
    formId: {
      type: Number,
    },
  },

  mounted() {
    this.populate();
  },

  data() {
    return {
      fields: [],
      submited: false,
      valid: {},
      formSentCorrently: "unsent",
    };
  },

  methods: {
    populate() {
      axios.get(`https://tgear.eu/admin/cms/api/fields/${this.formId}/`).then(
        function (response) {
          this.fields = response.data.data;
          for (let field of this.fields) {
            Vue.set(field.properties, "validate", false);
            this.valid[field.id] = true;
          }
        }.bind(this)
      );
    },
    classWidth(field) {
      return (
        "col-sm-" +
        field.properties.width +
        " col-" +
        field.properties.mobilewidth
      );
    },

    onSubmit(e) {
      e.preventDefault();

      for (let field of this.fields) {
        Vue.set(field.properties, "validate", true);
        field.properties.validate = true;
      }

      Vue.nextTick(
        function () {
          var form = document.getElementById("form");
          var formData = new FormData(form);

          let lesgo = true;
          for (let field of this.fields) {
            Vue.set(field.properties, "validate", false);
            if (this.valid[field.id] == false) {
              lesgo = false;
            }
          }

          if (lesgo) {
            let langurl = this.$i18n.locale;
            axios({
              method: "post",
              url:
                `https://tgear.eu/admin/cms/api/forms/${this.formId}/` +
                langurl,
              type: "POST",
              data: formData,
              async: false,
              cache: false,
              contentType: false,
              enctype: "multipart/form-data",
              processData: false,
            })
              .then(
                function (response) {
                  if (response.data.status == "ok") {
                    this.$gtm.trackEvent({
                      event: 'interaction',
                      category: "Formulieren",
                      action: "SendForm",
                      label: "Formulier verzonden",
                      value: this.formId,
                    });
                    this.formSentCorrently = "success";
                  } else {
                    this.formSentCorrently = "fail";
                  }
                }.bind(this)
              )
              .catch(
                function () {
                  this.formSentCorrently = "fail";
                }.bind(this)
              );
          }
        }.bind(this)
      );
    },
  },

  i18n: {
    messages: {
      nl: {
        fail:
          "Er is iets mis gegaan met het versturen van het formulier. Probeer het nog eens.",
        send: "Verzend",
        succes:
          "Bedankt voor uw aanvraag, we nemen zo snel mogelijk contact met u op.",
      },
      en: {
        fail: "Something went wrong... Please try again.",
        send: "Send",
        succes:
          "Thank you for your inquiry, we will contact you as soon as possible.",
      },
    },
  },
};
</script>

<style lang="scss" scoped>
.btn-contact {
  background-color: #40a780;
  color: #fff;
  padding: 10px 90px;
  border: none;
  border-radius: 5px;
  font-size: $inline-font-size;
  margin: 50px 0;
  margin-left: auto;
  cursor: pointer;
  box-shadow: 0px 2px 2px #0000003d;

  &:focus {
    outline: none;
  }
}
</style>
