import Vue from 'vue'
import Vuex from 'vuex'
import config from '@/config'
import VuexPersistence from 'vuex-persist'

Vue.use(Vuex)

import modules from './modules'

const vuexLocal = new VuexPersistence({
  storage: window.localStorage
})

export default new Vuex.Store({
  state: {
    apiUrl: config.apiUrl,
  },
  modules: {
    ...modules
  },
  plugins: [vuexLocal.plugin]
})
