<template>
  <div class="photo-gallery-wrapper">
    <div class="container">
      <span v-for="(photo, index) in gallery" :key="index">
          <img :src="photo.img" style="padding: 5px;"/>
      </span>
    </div>

    <slider-popup
      v-if="isShowSlider"
      :slides="[currentSlides]"
      :index="indexSlider"
      :show="isShowSlider"
      @close="onCloseSlider"
    />
  </div>
</template>

<script>
import SliderPopup from "../SliderPopup";

export default {
  name: "photo-gallery",

  components: {
    SliderPopup,
  },

  props: {
    gallery: {
      type: Array,
      default: () => {
        [];
      },
    },
  },

  data() {
    return {
      isShowSlider: false,
      indexSlider: 0,
      currentSlides: {},
    };
  },

  methods: {
    onZoom(slide) {
      this.isShowSlider = true;
      this.currentSlides = slide;
    },
    onCloseSlider() {
      this.isShowSlider = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.photo-gallery-wrapper {
  .item {
    background: #e9e9e9;
    padding: 50px;
    position: relative;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: contain;

    .label {
      color: #4f4e4e;
      font-size: $small-title-font-size;
    }

    .search-btn {
      position: absolute;
      right: 15px;
      top: 15px;
      color: #fff;
      background-color: #d5d5d5;
      border-radius: 50%;
      width: 30px;
      height: 30px;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    &.large {
      min-height: 572px;

      &.label {
        position: absolute;
        top: 15px;
        right: 15px;
      }
    }

    &.small {
      min-height: 278px;
    }

    &.medium {
      min-height: 376px;
      max-width: 376px;
    }
  }

  .col {
    margin-bottom: 30px;
  }
}
</style>
