<template>
  <div class="page gear-product">
    <div class="main header home-header">
      <div class="container">
        <default-header />
      </div>
    </div>

    <section class="product-info">
      <div class="container">
        <div class="row">
          <div class="col">
            <h1>{{ data.info.title }}</h1>
          </div>
        </div>
        <div class="row main-info-area d-flex justify-content-between">
          <div class="col-xl-9 col-lg-9">
            <div>
              <div v-if="data.info.go_youtube">
                <div class="embed-responsive embed-responsive-16by9">
                  <iframe
                    class="embed-responsive-item"
                    :src="`https://www.youtube-nocookie.com/embed/${data.info.go_youtube}`"
                    title="YouTube video player"
                    frameborder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowfullscreen
                  ></iframe>
                </div>
              </div>
              <div v-else class="img ratio ratio-1x1">
                <img
                  :src="
                    'https://tgear.eu/data/images/go/defaultgo_img' +
                    data.info.go_id +
                    '.png'
                  "
                  alt="alt"
                />
              </div>
            </div>
          </div>
          <div class="col-xl-3 col-gl-3 price-wrapper">
            <div>
              <div v-html="data.info.go_text" style="margin-bottom: 20px" />
              <div>
                <router-link :to="data.info.btn_link" class="download">{{
                  data.info.btn_text
                }}</router-link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section class="product-info">
      <div class="container">
        <div class="row">
          <div class="col">
            <fancy-blocks :content="content" :topLevel="false"></fancy-blocks>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import { DefaultHeader, FancyBlocks } from "@/components";

export default {
  name: "landing-page",

  components: {
    DefaultHeader,
    FancyBlocks,
  },

  created() {
    const slug = this.$route.params.slug;
    this.getDataByUrl(`/go/${slug}/`);
  },

  data() {
    return {};
  },

  methods: {
    ...mapActions({
      getDataByUrl: "data/getDataByUrl",
    }),
  },

  computed: {
    ...mapState("data", ["data"]),
    content() {
      return this.data.info.content;
    },
  },
};
</script>

<style lang="scss" scoped>
.gear-product {
  table {
    td {
      vertical-align: top;
    }
  }

  .description {
    h2 {
      color: #d16419;
      font-size: $big-title-font-size;
    }
  }

  .section-title {
    font-family: Apercu, Open Sans, Arial, Helvetica, sans-serif;
    font-size: $small-title-font-size;
    color: #4f4e4e;
    margin: 50px 0;
  }

  p {
    // font-size: $inline-font-size;
    color: #4f4e4e;
    font-family: $main-font;
    font-weight: normal;
    line-height: $inline-line-height;
  }

  .price-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .product-info {
    .back-btn {
      padding: 37px 0;
      color: #bebdbd;
      font-size: 1rem;
      font-weight: 300;
      display: inline-block;

      .fas {
        margin-right: 5px;
      }
    }

    .main-info-area {
      margin: 50px 0;
    }

    .img {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
    }

    h1 {
      color: #d16419;
      font-size: $big-title-font-size;
    }

    .desc {
      color: #4f4e4e;
      font-size: $inline-font-size;
      display: inline-block;
      margin-bottom: 39px;
    }

    p {
      // font-size: $small-title-font-size;
      margin-bottom: 20px;
      text-align: center;
      max-width: 734px;
      margin: 0 auto;
      margin-bottom: 150px;

      &.proberen {
        margin: 0 0 15px 0;

        // font-size: $inline-font-size;
        text-align: left;
      }
    }

    // TODO change padding top and bottom from .download class the green button. This should probably been done overaal site
    .download {
      background-color: #40a780;
      // font-size: $inline-font-size;
      color: #fff;
      padding: 10px 27px;
      border-radius: 4px;
      box-shadow: 0px 2px 2px #0000003d;
    }

    .price {
      color: #0088ce;
      font-size: $small-title-font-size;
      font-weight: bold;
    }

    .hr {
      border-bottom: 1px solid #bebdbd61;
    }

    .btn-more {
      color: #0088ce;
      font-size: $inline-font-size;
    }
  }

  .specifications {
    margin-bottom: 150px;
  }

  .section.help {
    padding: 100px 0 100px 0;

    .help-item {
      text-align: center;

      .image {
        margin-bottom: 70px;
      }

      .name {
        font-size: $small-title-font-size;
        font-weight: normal;
        color: #40a780;
        margin-bottom: 20px;
      }

      p {
        text-align: center;
      }
    }

    .link {
      color: #0088ce;
      font-family: $main-font;
      font-size: $inline-font-size;
      font-weight: normal;
    }
  }

  .section.downloads {
    margin-bottom: 100px;

    .section-title {
      margin-bottom: 20px;
      display: flex;
      align-items: center;

      img {
        margin-left: 15px;
      }
    }

    .modules-parts {
      font-size: $inline-font-size;
      color: #4f4e4e;
    }

    .downloads {
      color: #0088ce;
      font-size: $inline-font-size;
    }
  }

  .section.in-the-box {
    // padding-bottom: 50px;

    color: #4f4e4e;
  }

  .section.video {
    .embed-responsive {
      margin-bottom: 100px;
      @media (max-width: 768px) {
        margin-bottom: 50px;
      }
    }
  }

  .section.photo-gallery {
    // padding-top: 100px;

    .grid-layout {
      display: grid;
      grid-template-columns: repeat(auto-fill, minmax(15rem, 1fr));
      grid-gap: 40px;
      grid-auto-rows: minmax(15rem, auto);
      grid-auto-flow: dense;
      margin-bottom: 5px;
    }
    .grid-item {
      padding: 1rem;
      font-size: 14px;
      font-weight: bold;
      color: white;
      background-color: $primary;
      background-size: cover;
      background-position: center;
      grid-row-end: span 1;
      grid-column-end: span 1;

      a {
        display: flex;
        justify-content: flex-end;
        align-items: flex-start;
        width: 100%;
        height: 100%;
        color: red;
        text-align: end;
      }
    }

    .grid5items {
      .grid-item:nth-child(1) {
        grid-row-end: span 2;
        grid-column-end: span 2;
      }
    }
  }

  .img {
    width: 100%;
    object-fit: cover;

    img {
      width: 100%;
    }
  }
}

.wrapper-specifications {
  .section-title {
    margin: 50px 0;
  }

  .systems {
    display: flex;
    flex-direction: column;

    display: flex;
    line-height: $inline-line-height;
    color: #4f4e4e;
    font-size: $inline-font-size;
    line-height: $inline-line-height;
  }
}

.photo-slider {
  display: none;
}

@media (max-width: 768px) {
  .photo-slider {
    display: block;
  }

  .wrapper-specifications {
    .section-title {
      font-size: $smallest-title-font-size;
    }

    .systems {
      margin-bottom: 50px;
      margin-top: 0;

      line-height: 1.5rem;
      // font-size: 1rem;
    }
  }
}

@media (max-width: 768px) {
  .container {
    padding-left: 30px;
    padding-right: 30px;
  }

  .gear-product {
    .product-info {
      .desc {
        margin-bottom: 0;
      }

      .back-btn {
        padding: 15px 0;
      }
      h1 {
        font-size: $small-title-font-size;
      }

      .price {
        font-size: $inline-font-size;
      }

      .price-wrapper {
        align-items: center;
        display: flex;
        flex-direction: column;
        margin-bottom: 4rem;
      }

      .download {
        font-size: $navigation-font-size;
      }

      h2 {
        font-size: $smallest-title-font-size;
        margin-bottom: 35px;
        margin-top: 35px;
        font-weight: 800;
      }

      p {
        font-size: $navigation-font-size;
        margin-bottom: 35px;
        margin-top: 35px;
      }

      .yellow-title {
        color: #fdaa29;
      }

      .main-info-area {
        justify-items: center;
        align-items: center;
        display: flex;
        margin-bottom: 0;
        margin-top: 50px;
      }
    }

    .section-title {
      // font-size: 30px !important;
    }

    .section.help {
      padding-top: 65px;
      padding-bottom: 50px;

      .section-title {
        margin-bottom: 0;
      }

      .help-item {
        .name {
          font-size: $smallest-title-font-size;
        }

        .image {
          width: 200px;
          height: 200px;
          object-fit: cover;
          margin-left: auto;
          margin-right: auto;
          margin-top: 46px;

          img {
            width: 100%;
            height: 100%;
          }
        }

        p {
          font-size: $navigation-font-size;
        }
      }

      .link {
        font-size: $navigation-font-size;
      }
    }

    .section.downloads {
      margin-bottom: 50px;

      .downloads-wrapper {
        margin-bottom: 50px;
      }
    }

    .photo-gallery {
      display: none;
    }

    .photo-slider {
      display: flex;
    }

    .section.downloads .downloads,
    .section.downloads .modules-parts,
    .section.in-the-box ul li {
      font-size: $navigation-font-size;
    }

    .section.in-the-box {
      padding-bottom: 0;
    }

    .specifications {
      margin-bottom: 0;
    }
  }
}
</style>

