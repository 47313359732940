<template>
  <div class="header-sidebar-wrapper" :class="{ show: show }">
    <div class="head">
      <a href="#" class="back" @click.prevent="onClose">
        <i class="fas fa-arrow-left"></i>
      </a>
      <router-link :to="{ name: 'home' }" class="logo">
        <img src="/static/images/logo.svg" alt="logo" />
      </router-link>
      <div class="actions">
        <!-- <div>
          <img src="/static/images/basket-icon.svg" alt="cart-icon" />
        </div>-->
        <div class="lang">
          <locale-changer />
        </div>
      </div>
    </div>
    <div class="navs">
      <div v-for="(nav, index) in menu" :key="index">
        <router-link
          v-if="nav && nav.url"
          :class="`nav-item ${nav.button ? 'btn-green' : ''}`"
          :to="{ name: nav.url }"
          >{{ nav.title }}</router-link
        >
        <!-- <div class="children" v-if="nav.url == 'learning-lines'"> -->
        <!--   <router-link -->
        <!--     class="child" -->
        <!--     v-for="(child, index2) in education" -->
        <!--     :key="index2" -->
        <!--     :to="{ -->
        <!--       name: 'LearningLinesEducation', -->
        <!--       params: { education: child.slug }, -->
        <!--     }" -->
        <!--   > -->
        <!--     {{ child.title }}</router-link -->
        <!--   > -->
        <!-- </div> -->
      </div>
    </div>
    <div class="footer">
      <router-link
        :to="{ name: 'over-t-gear', params: { level: $route.query.level } }"
        >Over T Gear</router-link
      >
      <router-link
        :to="{ name: 'downloads', params: { level: $route.query.level } }"
        >Downloads</router-link
      >
      <router-link
        :to="{ name: 'ladderino', params: { level: $route.query.level } }"
        >Ladderino reference</router-link
      >
    </div>
  </div>
</template>

<script>
import LocaleChanger from "@/components/LocaleChanger.vue";

import { mapGetters } from "vuex";

export default {
  name: "header-sidebar",

  components: {
    LocaleChanger,
  },
  props: {
    navs: {
      type: Array,
      default: () => [],
    },
    show: {
      type: Boolean,
      default: false,
    },
  },

  watch: {
    show: (value) => {
      if (value) {
        document.body.style = "overflow: hidden";
        return;
      }
      document.body.style = "overflow: unset";
    },
  },

  mounted() {
    document.body.style = "overflow: unset";
    document.body.addEventListener("click", (e) => {
      let element = e.target;
      const isClickOver = element.closest(".header-sidebar-wrapper");
      if (element && element.classList.contains("fa-bars")) {
        return;
      }

      if (!isClickOver) {
        this.onClose();
      }
    });
  },

  methods: {
    onClose() {
      this.$emit("close");
    },
  },

  computed: {
    ...mapGetters(["education", "menu"]),
  },
};
</script>

<style lang="scss" scoped>
.header-sidebar-wrapper {
  position: fixed;
  height: 100vh;
  height: -webkit-fill-available;
  left: 0;
  top: 0;
  min-width: 287px;
  max-width: 287px;
  background-color: #f4f4f4;
  z-index: 998;
  transform: translateX(-100%);
  transition: transform 0.8s;
  display: flex;
  overflow: auto;
  flex-direction: column;

  .footer {
    margin-top: auto;
    width: 100%;
    background: #e9e9e9;
    padding: 30px;
    display: block;
    flex-direction: column;

    a {
      color: #4f4e4e;
      display: block;
      font-size: $button-font-size;
    }
  }

  &.show {
    transform: translateX(0);
    box-shadow: 0px 0px 60px #00000099;
  }

  .navs {
    padding: 36px 5px 36px 36px;
    display: block;
    flex-direction: column;

    .nav-item {
      color: #4f4e4e;
      font-size: $navigation-font-size;
      display: block;
      padding: 0.2rem 0;
      @media (max-width: 768px) {
        padding: 0.3rem 0;
      }

      &.btn-green {
        background: #40a780;
        color: #fff;
        padding: 3px 10px;
        border-radius: 5px;
        margin-top: 35px;
        display: inline-block;
      }
    }

    .children {
      display: flex;
      flex-direction: column;
      margin: 10px 0 10px 25px;

      .child {
        line-height: 1.5rem;
        width: 100%;
        color: #bebdbd;
        font-size: $button-font-size;
        display: block;
        padding: 0.2rem 0;
        @media (max-width: 768px) {
          padding: 0.3rem 0;
        }
      }
    }
  }

  .to-desktop {
    margin: 0 36px;
    background-color: #0088ce;
    display: inline-block;
    color: #fff;
    padding: 0.25rem 58px 5px 10px;
    margin-bottom: 15px;
  }

  .head {
    display: flex;
    padding: 54px 20px 0 20px;
    justify-content: space-between;
    align-items: center;

    .back {
      color: #333;
    }

    .actions {
      display: flex;
      justify-content: space-between;
      align-items: center;

      .lang {
        margin-left: 5px;
        font-weight: 600;
        color: #4f4e4e;
        font-size: 11px;
        text-transform: uppercase;
      }
    }
  }
}
</style>
