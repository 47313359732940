<template>
  <div class="action" v-if="data && data.info">
    <p>{{ data.info.footer_title }}</p>

    <router-link :to="data.info.contact_url" class="action-btn">{{
      data.info.contact_text
    }}</router-link>
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  name: "convinced",

  computed: {
    ...mapState("data", ["data"]),
  },
};
</script>

<style lang="scss" scoped>
.action {
  margin-top: 100px;

  p {
    font-family: $main-font;
    font-size: $inline-font-size;
    font-weight: normal;
    color: #4f4e4e;
  }

  .action-btn {
    color: #fff;
    background-color: #40a780;
    display: inline-block;
    padding: 5px 20px;
    border-radius: 3px;
    font-family: $main-font;
    font-weight: normal;
    font-size: $inline-font-size;
    margin-top: 30px;
    box-shadow: 0px 2px 2px #0000003d;
  }
}

@media (max-width: 768px) {
  .action {
    display: flex;
    justify-content: center;
    flex-direction: column;

    p {
      // font-size: 1rem;
      text-align: center;
    }

    .action-btn {
      font-size: $navigation-font-size;
      margin: 37px auto;
    }
  }
}
</style>
