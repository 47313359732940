<template>
  <div class="havo-vwo-wrapper" :class="{ show: show }">
    <div class="overlay" @click.prevent="onClickClose"></div>
    <div class="overlay-white"></div>
    <a href="#" class="close" @click.prevent="onClickClose"></a>
    <div class="popup">
      <div class="body">
        <h1>{{ $t("message.select_level") }}</h1>
        <div class="tags">
          <a
            @click="onClickTag(tag)"
            class="tag"
            :class="{ alone: tag.alone }"
            v-for="(tag, index) in tags"
            :key="index"
          >
            <i :class="`fas fa-graduation-cap`" v-if="tag.incompany == '0'"></i>
            <i :class="`fas fa-medal`" v-else></i>
            <span>{{ tag.level }}</span>
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

export default {
  name: "popup-havo-vwo",

  props: {
    show: {
      type: Boolean,
      default: false,
    },
    autoShow: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {};
  },

  i18n: {
    messages: {
      en: {
        message: {
          select_level: "Select the desired education level:",
        },
      },
      nl: {
        message: {
          select_level: "Voor welk type onderwijs ben je op zoek?",
        },
      },
    },
  },

  computed: {
    ...mapGetters(["currentTag", "tags"]),
  },

  methods: {
    ...mapActions({ changeCurrentTag: "changeCurrentTag", }),
    onClickTag(tag) {
      if(this.$route.query.level != tag.slug) {
        this.$router.replace({ query: { ...this.$route.query, level: tag.slug } });
      }
      this.changeCurrentTag(tag);
      this.$emit("close");
    },
    onClickClose() {
      this.changeCurrentTag(this.tags.filter((e) => e.default == true)[0]);
      this.$emit("close");
    },
  },

  mounted() {
    document.body.style = "overflow: unset";
  },

  watch: {
    show: (value) => {
      if (value) {
        document.body.style = "overflow: hidden";
        return;
      }
      document.body.style = "overflow: unset";
    },
  },
};
</script>

<style lang="scss" scoped>
.havo-vwo-wrapper {
  display: flex;
  transform: translateY(-100%);
  height: 100vh;
  z-index: -1;
  position: fixed;
  width: 100vw;
  left: 0;
  top: 0;
  justify-content: center;
  align-items: center;
  opacity: 0;
  transition: opacity 0.3s;

  &.show {
    display: flex;
    transform: translateY(0);
    opacity: 1;
    z-index: 999;
  }

  .overlay-white {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: -2;
    background: #fff;
  }

  .overlay {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: -1;
    background: #fdaa29;
    opacity: 0.44;
  }

  .close {
    position: absolute;
    right: 64px;
    top: 24px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 56px;
    height: 56px;
    border-radius: 50%;
    background-color: rgba(253, 170, 41, 0.3);
    z-index: 999;
    color: #fff;

    &:after,
    &:before {
      content: "";
      position: absolute;
      content: " ";
      height: 30px;
      width: 2px;
      background-color: #fff;
    }

    &:before {
      transform: rotate(45deg);
    }

    &:after {
      transform: rotate(-45deg);
    }
  }

  .popup {
    background-color: #fff;
    min-width: 682px;
    max-width: 682px;
    border-radius: 5px;
    padding: 63px;
    box-shadow: 6px 6px 6px #00000029;

    .tags {
      text-align: center;

      .tag {
        cursor: pointer;
        padding: 5px 14px;
        background-color: #f4f4f4;
        color: #4f4e4e;
        font-size: $inline-font-size;
        display: inline-block;
        border-radius: 5px;
        margin-right: 10px;
        margin-bottom: 10px;
        transition: all 0.2s;

        &.alone {
          display: block;
          width: fit-content;
          margin: 10px auto;
        }

        &:hover {
          cursor: pointer;
          background-color: #fdaa29;
          color: #fff;

          .fas {
            color: #4f4e4e;
          }
        }

        .fas {
          color: #d16419;
          margin-right: 7px;
        }
      }
    }

    h1 {
      font-family: Open Sans, Arial, sans-serif;
      text-align: center;
      color: #4f4e4e;
      font-size: $medium-title-font-size;
      font-weight: bold;
      margin-bottom: 34px;
    }

    .under {
      font-family: $main-font;
      text-align: center;
      margin-top: 21px;

      .sub {
        font-size: 1rem;

        a {
          font-weight: 300;
        }
      }

      & > a {
        font-size: $inline-font-size;
        font-weight: 400;
      }

      a {
        color: #0088ce;
      }
    }
  }
}

@media (max-width: 768px) {
  .havo-vwo-wrapper {
    .close {
      right: 12px;
      top: 14px;
    }

    .popup {
      margin-top: 84px;
      min-width: 582px;
      max-width: 582px;

      h1 {
        font-size: $inline-font-size;
      }

      .tags {
        .tag {
          // font-size: 18px;
        }
      }

      .under {
        .sub {
          a {
            // font-size: 13px;
          }
        }

        a {
          // font-size: 1rem;
        }
      }
    }
  }
}

@media (max-width: 425px) {
  .havo-vwo-wrapper {
    .popup {
      min-width: 350px;
      max-width: 350px;
    }
  }
}

@media (max-width: 320px) {
  .havo-vwo-wrapper {
    .popup {
      min-width: 300px;
      max-width: 300px;
    }
  }
}
</style>
