<template>
  <div class="slider-wrapper" @click="onClose" :class="{'show': show}">
    <a href="#" @click.stop.prevent="onClose" class="btn-close">
      <img src="/static/images/times-icon.png" alt="close-btn" />
    </a>
    <div class="slider" @click.stop.prevent>
      <div class="card">
        <div class="slides-wrapper">
          <a href="#" class="prev" @click.stop.prevent="onClickPrev" v-if="slides.length >= 2">
            <img src="/static/images/slider-arrow-left.png" alt="arrow" />
          </a>
          <a href="#" class="next" @click.stop.prevent="onClickNext" v-if="slides.length >= 2">
            <img src="/static/images/slider-arrow-right.png" alt="arrow" />
          </a>
          <div
            class="slides"
            ref="slides"
            :style="{'transform' : `translateX(-${active * (withSlide)}px)` }"
          >
            <div
              class="slide"
              :ref="`slide-${index}`"
              :style="{'min-width': withSlide ?  `${withSlide}px` : 'unset'}"
              v-for="(slide, index) in slides"
              :key="index"
            >
              <img :src="slide.url" alt="slide" />
              <p class="description-image">{{ slide.text }}</p>
            </div>
          </div>
        </div>
        <div class="previews" v-if="slides.length >= 2">
          <div
            class="shadow-slide"
            :style="{left: `${active * (withPreview + 30)}px`, width: `${withPreview}px`}"
          ></div>
          <div
            class="preview"
            :ref="`preview-${index}`"
            v-for="(slide, index) in slides"
            :key="index"
            @click="active = index"
          >
            <img :src="slide.url" alt="preview" width="200" height="200"/>
          </div>
        </div>

        <div class="dots" v-if="slides.length >= 2">
          <div
            class="dot"
            :class="{'active': index == active}"
            v-for="(slide, index) in slides"
            :key="index"
            @click="active = index"
          ></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import api from "@/api"

export default {
  name: "slider-wrapper",

  props: {
    url: {
      type: String,
      default: "",
    },
    show: {
      type: Boolean,
      default: false,
    },
    index: {
      type: Number,
      default: 0,
    },
  },

  data() {
    return {
      active: this.index,
      withPreview: 250,
      withSlide: 0,
      slides: [],
    };
  },

  watch: {
    index() {
      this.active = this.index;
    },
  },

  mounted() {
    api.getDataByUrl(this.url).then(({ data }) => {
      this.slides = data.slider;
    })
    this.readWidths();
    document.body.addEventListener("resize", () => {
      this.readWidths();
    });

    window.addEventListener("orientationchange", () => {
      this.readWidths();
    });
  },

  methods: {
    readWidths() {
      let $this = this;
      setTimeout(() => {
        if ($this.$refs[`preview-0`]) {
          const [el] = $this.$refs[`preview-0`];
          $this.withPreview = el.offsetWidth;
        }

        if ($this.$refs[`slides`]) {
          const el2 = $this.$refs[`slides`];
          $this.withSlide = el2.offsetWidth;
        }
      }, 100);
    },
    onClose() {
      this.$emit("close");
    },
    onClickPrev() {
      if (this.active != 0) {
        this.active--;
      }
    },
    onClickNext() {
      if (this.active != this.slides.length - 1) {
        this.active++;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.slider-wrapper {
  position: fixed;
  z-index: 999;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.3);
  display: flex;
  justify-content: center;
  align-items: center;
  display: none;
  opacity: 0;
  transform: 1s all;

  .dots {
    display: none;
    justify-content: center;
    margin-top: 15px;
    align-items: center;

    .dot {
      width: 8px;
      height: 8px;
      border-radius: 50%;
      cursor: pointer;
      background-color: #e9e9e9;
      margin-left: 15px;

      &.active {
        background-color: #000;
      }
    }
  }

  &.show {
    display: flex;
    opacity: 1;
  }

  .btn-close {
    position: absolute;
    right: 45px;
    top: 25px;
    width: 57px;
    min-height: 57px;
    height: 57px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    background: rgba(0, 0, 0, 0.6);
    color: #fff;
  }

  .previews {
    display: flex;
    justify-content: space-between;
    margin-top: 15px;
    position: relative;

    .shadow-slide {
      cursor: pointer;
      transition: left 0.3s;
      z-index: 2;
      border-radius: 5px;
      margin: 0 15px;
      background-color: rgba(0, 0, 0, 0.3);
      position: absolute;
      left: 0;
      top: 0;
      bottom: 0;
    }

    .preview {
      cursor: pointer;
      z-index: 1;
      background-color: #e9e9e9;
      border-radius: 5px;
      width: 100%;
      margin: 0 15px;
      display: flex;
      align-items: center;
      justify-content: center;
      z-index: 1;
      position: relative;

      img {
        object-fit: cover;
        align-items: center;
        justify-content: center;
      }
    }
  }

  .slider {
    max-width: calc(100vw - 20%);
    min-width: calc(100vw - 20%);
    position: relative;
    max-height: calc(100vh - 10%);
    overflow: auto;
  }

  .card {
    background: #fff;
    box-shadow: 6px 6px 6px #00000029;
    border-radius: 5px;
    opacity: 1;
    padding: 51px 36px;
    height: 100%;
    overflow: hidden;
  }

  .slides-wrapper {
    overflow: hidden;
    position: relative;
    z-index: 1;

    .prev,
    .next {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      z-index: 2;
    }

    .prev {
      left: 15px;
    }

    .next {
      right: 15px;
    }

    .slides {
      display: flex;
      transition: transform 0.8s;

      .slide {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        border-radius: 5px;
        background: #e9e9e9;
        overflow: hidden;
        position: relative;

        .description-image {
          margin-top: 5px;
          font-size: $navigation-font-size;
          color: #4f4e4e;
        }
      }
    }
  }
}

@media (max-width: 1024px) {
  .slider-wrapper {
    .previews {
      display: none;
    }

    .dots {
      display: flex;
    }

    .slides-wrapper {
      .slides {
        .slide {
          img {
            object-fit: contain;
            object-position: center center;
            height: 100%;
            width: 100%;
          }

          .description-image {
            display: none;
          }
        }
      }
    }
  }
}
</style>
