<template>
  <header class="header">
    <div class="logo-wrap">
      <router-link :to="{ name: 'home' }" class="logo">
        <img src="/static/images/logo.svg" alt="logo" />
      </router-link>
      <a href="#" @click.prevent="onToggle" class="toggle">
        <i class="fas fa-bars"></i>
      </a>
      <a
        href="#"
        class="btn-havo-vwo"
        :class="{ 'pulse-btn': isChangedTag }"
        v-if="currentTag.incompany"
        @click="onClickHavoVwo"
      >
        <i class="fas fa-graduation-cap" v-if="currentTag.incompany == '0'"></i>
        <i class="fas fa-medal" v-if="currentTag.incompany == '1'"></i>
        <span v-if="currentTag">{{ currentTag.level }}</span>
      </a>
    </div>
    <div class="logo mobile">
      <router-link :to="{ name: 'home' }" class="logo">
        <img src="/static/images/logo.svg" alt="logo" />
      </router-link>
    </div>
    <div class="navs desktop">
        <router-link
        class="nav-item"
        v-for="(nav, index) in menu"
        :key="index"
          :to="{ name: nav.url }"
          :class="{
            active: pathIsActive(nav),
            'header-item': true,
          }"
          >{{ nav.title }}</router-link
        >
        <!-- <div -->
        <!--   class="childrens" -->
        <!--   v-if="nav.url == 'learning-lines'" -->
        <!--   v-on:click.stop -->
        <!-- > -->
        <!--   <router-link -->
        <!--     class="child" -->
        <!--     v-for="(child, index) in education" -->
        <!--     :key="index" -->
        <!--     :to="{ -->
        <!--       name: 'LearningLinesEducation', -->
        <!--       params: { education: child.slug }, -->
        <!--     }" -->
        <!--   > -->
        <!--     {{ child.title }}</router-link -->
        <!--   > -->
        <!-- </div> -->

      <router-link
        :to="{
          path: data.info.header_buttonurl,
          query: { level: $route.query.level },
        }"
        class="action-btn"
        v-if="data.info"
        >{{ data.info.header_buttontext }}
      </router-link>

      <locale-changer />
    </div>
    <div class="navs mobile">
      <div class="mobile-empty"></div>
    </div>
    <div class="navs mobile">
      <!--} <router-link to="/cart" class="cart-nav-btn">
        <div>
          <img src="/static/images/basket-icon.svg" alt="cart-icon" />
        </div>
      </router-link>-->
    </div>
    <header-side-bar
      :navs="education"
      :show="isShowSideBar"
      @close="isShowSideBar = false"
    />
    <popup-havo-vwo :show="isShowHavoVwo" @close="onChangePopupHavovwo" />
  </header>
</template>

<script>
import HeaderSideBar from "./HeaderSideBar";
import PopupHavoVwo from "@/components/popups/HavoVwo";
import LocaleChanger from "@/components/LocaleChanger.vue";
import { mapGetters, mapActions, mapState } from "vuex";

export default {
  name: "LandingHeader",

  components: {
    HeaderSideBar,
    PopupHavoVwo,
    LocaleChanger,
  },

  data() {
    return {
      shows: {},
      showsTimes: {},
      showPopup: false,
      isShowSideBar: false,
      isChangedTag: false,
      langs: [
        {
          lang: "Nederlands",
          code: "nl",
        },
        {
          lang: "English",
          code: "en",
        },
      ],
    };
  },

  mounted() {
    if (
      ["LearningLines"].includes(this.$route.name) &&
      this.$route.path != "/learning-lines"
    ) {
      this.changedEffect();
    }
  },

  watch: {
    currentTag() {
      this.changedEffect();
    },
  },

  methods: {
    ...mapActions(["changeShowHavoVwo", "changeLang"]),
    ...mapActions({
      changeCurrentTag: "changeCurrentTag",
    }),
    changedEffect() {
      this.isChangedTag = true;
      setTimeout(() => {
        this.isChangedTag = false;
      }, 1500);
    },

    onChangePopupHavovwo() {
      this.changeShowHavoVwo(false);
    },
    onToggle() {
      this.isShowSideBar = !this.isShowSideBar;
    },
    onClickHavoVwo() {
      this.changeShowHavoVwo(true);
    },
    routeChange(url) {
      this.$router
        .push({
          name: url,
        })
        .catch(() => {});
    },

    pathIsActive(nav) {
      if(this.$route.name != "LearningLinesEducation") {
        return nav.url.indexOf(this.$route.name) + 1;
      }
      
      if(nav.url == "learning-lines") {
        return true;
      }
    }
  },

  computed: {
    ...mapState("data", ["data"]),
    ...mapGetters(["tags", "education", "menu"]),
    ...mapGetters(["currentTag", "isShowHavoVwo", "lang"]),
  },
};
</script>

<style lang="scss" scoped>
.action-btn {
  color: #fff;
  background-color: #40a780;
  padding: 0.3rem 0.8rem;
  border-radius: 0.3rem;
  margin-right: 0.4rem;
  font-size: 0.7rem;
}

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-family: "Open Sans", Arial, sans-serif;

  .mobile-empty {
    display: block;
    width: 69px;
    height: 30px;
  }

  .logo.mobile {
    margin-left: auto;
    margin-right: auto;
    display: none;
    transform: translateY(2px);
  }

  .logo-wrap {
    display: flex;
    align-items: center;

    .toggle {
      display: none;
    }

    .btn-havo-vwo {
      color: #ffffff;

      padding: 0.3rem 0.8rem;
      border-radius: 0.3rem;
      margin-right: 0.4rem;
      font-size: 0.7rem;

      text-transform: uppercase;
      background-color: #fdaa29;
      margin-left: 25px;

      .fas {
        color: #4f4e4e;
        margin-right: 0.3rem;
      }
    }
  }

  .navs {
    display: flex;
    align-items: center;

    .nav-item {
      padding: 0.3rem 0.5rem;
      position: relative;
      border-bottom: 0.25rem solid transparent;
      border-top: 0.25rem solid transparent;
      cursor: pointer;
      transition-delay: 5s;
      transition: border-bottom 0.5s linear;

        color: #bebdbd;
        font-size: 0.8rem;
        //transition-delay: 1s;
        transition: all 0.3s linear;

        &.active {
          color: #222221;
          position: relative;
        }

      &.active {
        border-bottom: 0.25rem solid #fdaa29;
      }

      &:not(.nav-item-btn):hover {
        border-bottom: 0.25rem solid #fdaa29;

        a.header-item {
          color: #222221;
        }
      }

      &:hover > .childrens {
        transform: translateY(10px) !important;
        opacity: 1;
      }

      .childrens {
        position: absolute;
        display: flex;
        flex-direction: column;
        // top: 40px;
        left: 0;
        min-width: 300px;
        background-color: #f4f4f4;
        padding: 10px;
        line-height: 30px;
        z-index: -1;
        transform: translateY(-200px);
        transition: all 0.3s linear;
        opacity: 0;

        .item-children {
          color: #c2c1c1;
        }

        &:after {
          content: "";
          position: absolute;
          left: 0;
          right: 0;
          top: -100px;
          height: 100px;
          z-index: 1;
          background: transporent;
        }

        a {
          color: #bebdbd;

          &:hover,
          &.active {
            color: #4f4e4e;
          }
        }
      }

      &:last-child {
        margin: 0;
      }

      &.nav-item-btn {
        a {
          background-color: #40a780;
          color: #fff;
          padding: 5px 30px;
          border-radius: 3px;
        }
      }
    }
  }

  .navs.mobile {
    display: none;

    .cart-nav-btn {
      margin-right: 0;
    }
  }

  .phone-nav-btn {
    background-color: #008ad8;
    height: 35px;
    margin: 0 15px;
    width: 35px;
    border-radius: 50%;
    display: flex;
    position: relative;

    img {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }

  .cart-nav-btn {
    margin: 0 12px 0 7px;
  }

  .desktop-nav-btn {
    margin: 0 12px 0 7px;
    display: flex;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    background: #008ad8;
    color: #fff;
    justify-content: center;
    align-items: center;
  }
}

@media (max-width: 1024px) {
  .header {
    padding: 7.5px 0;

    .logo-wrap {
      .btn-havo-vwo {
        transform: translateX(2.5px);
        width: 30px;
        height: 30px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;

        .fas {
          transform: translateX(2.5px);
        }

        span {
          display: none;
        }
      }
    }

    .navs {
      .nav-item {
        a {
          //   font-size: 15px;
        }
      }
    }

    .logo.mobile {
      display: block;
    }

    .logo-wrap {
      .toggle {
        color: #2e2e2d;
        display: inline-block;
      }

      .logo {
        display: none;
      }

      .btn-havo-vwo {
        width: 30px;
        height: 30px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;

        span {
          display: none;
        }
      }
    }

    .navs.desktop {
      display: none;
    }

    .navs.mobile {
      display: flex;
    }
  }
}
</style>
