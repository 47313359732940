<template>
  <div class="slider-wrapper">
    <div class="slider">
      <div class="card">
        <div class="slides-wrapper">
          <a href="#" class="prev" @click.stop.prevent="onClickPrev">
            <img src="/static/images/slider-arrow-left.png" alt="arrow" />
          </a>
          <a href="#" class="next" @click.stop.prevent="onClickNext">
            <img src="/static/images/slider-arrow-right.png" alt="arrow" />
          </a>
          <div
            class="slides"
            ref="slides"
            :style="{ transform: `translateX(-${active * withSlide}px)` }"
          >
            <div
              class="slide"
              :ref="`slide-${index}`"
              :style="{ 'min-width': withSlide ? `${withSlide}px` : 'unset' }"
              v-for="(slide, index) in slides"
              :key="index"
            >
              <img :src="slide.img_slide" alt="slide" />
            </div>
          </div>
        </div>
        <div class="previews" v-if="!minimize">
          <div
            class="shadow-slide"
            :style="{
              left: `${active * (withPreview + 30)}px`,
              width: `${withPreview}px`,
            }"
          ></div>
          <div
            class="preview"
            :ref="`preview-${index}`"
            v-for="(slide, index) in slides"
            :key="index"
            @click="active = index"
          >
            <img :src="slide.img_slide" alt="preview" />
          </div>
        </div>

        <div class="dots" :style="{ display: minimize ? 'flex' : 'none' }">
          <div
            class="dot"
            :class="{ active: index == active }"
            v-for="(slide, index) in slides"
            :key="index"
            @click="active = index"
          ></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "slider-wrapper",

  props: {
    slides: {
      type: Array,
      default: () => [],
    },
    show: {
      type: Boolean,
      default: false,
    },
    index: {
      type: Number,
      default: 0,
    },
    minimize: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      active: this.index,
      withPreview: 250,
      withSlide: 0,
    };
  },

  watch: {
    index() {
      this.active = this.index;
    },
  },

  mounted() {
    this.readWidths();
    document.body.addEventListener("resize", () => {
      this.readWidths();
    });

    window.addEventListener("orientationchange", () => {
      this.readWidths();
    });
  },

  methods: {
    readWidths() {
      let $this = this;
      setTimeout(() => {
        if ($this.$refs[`preview-0`]) {
          const [el] = $this.$refs[`preview-0`];
          $this.withPreview = el.offsetWidth;
        }

        if ($this.$refs[`slides`]) {
          const el2 = $this.$refs[`slides`];
          $this.withSlide = el2.offsetWidth;
        }
      }, 100);
    },
    onClose() {
      this.$emit("close");
    },
    onClickPrev() {
      if (this.active != 0) {
        this.active--;
      }
    },
    onClickNext() {
      if (this.active != this.slides.length - 1) {
        this.active++;
        this.$gtm.trackEvent({
          event: "interaction",
          category: "Slideshow",
          action: "NextSlide",
          label: "Slide to next",
          value: this.active,
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.slider-wrapper {
  width: 100%;

  .dots {
    display: none;
    justify-content: center;
    margin-top: 15px;
    align-items: center;

    .dot {
      width: 8px;
      height: 8px;
      border-radius: 50%;
      cursor: pointer;
      background-color: #e9e9e9;
      margin-left: 8px;

      &.active {
        background-color: #000;
      }
    }
  }

  .btn-close {
    position: absolute;
    right: 45px;
    top: 25px;
    width: 57px;
    min-height: 57px;
    height: 57px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    background: rgba(0, 0, 0, 0.6);
    color: #fff;
  }

  .previews {
    display: flex;
    justify-content: space-between;
    margin-top: 15px;
    position: relative;

    .shadow-slide {
      cursor: pointer;
      transition: left 0.3s;
      z-index: 2;
      border-radius: 5px;
      margin: 0 15px;
      background-color: rgba(0, 0, 0, 0.3);
      position: absolute;
      left: 0;
      top: 0;
      bottom: 0;
    }

    .preview {
      cursor: pointer;
      z-index: 1;
      background-color: #e9e9e9;
      border-radius: 5px;
      width: 100%;
      margin: 0 15px;
      display: flex;
      align-items: center;
      justify-content: center;
      z-index: 1;
      position: relative;

      img {
        object-fit: cover;
        align-items: center;
        justify-content: center;
      }
    }
  }

  .slider {
    position: relative;
  }

  .card {
    height: 100%;
    overflow: hidden;
  }

  .slides-wrapper {
    overflow: hidden;
    position: relative;
    z-index: 1;

    .prev,
    .next {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      z-index: 2;
    }

    .prev {
      left: 15px;
    }

    .next {
      right: 15px;
    }

    .slides {
      display: flex;
      transition: transform 0.8s;

      .slide {
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 5px;
        overflow: hidden;
      }
    }
  }
}

@media (max-width: 1024px) {
  .slider-wrapper {
    .previews {
      display: none;
    }

    .dots {
      display: flex;
    }

    .slides-wrapper {
      .slides {
        .slide {
          img {
            object-fit: contain;
            object-position: center center;
            height: 100%;
            width: 100%;
          }
        }
      }
    }
  }
}
</style>
