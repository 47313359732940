<template>
  <footer class="footer" v-if="data && data.info">
    <div class="container">
      <div class="row footer-title">
        <div class="col">
          <div class="logo-gray gray-line">
            <img src="/static/images/gray-logo.svg" alt="logo-footer" />
          </div>
        </div>
        <div class="col">
          <div class="gray-line"></div>
        </div>
      </div>
      <div class="row footer-content">
        <div class="col-xl-6 col-lg-6 col-md-6 footer-text">
          <div v-html="data.info.footer_text" style=" ;" />
        </div>
        <div class="col-xl-6 col-lg-6 col-md-6 footer-addresses">
          <h3 class="bold">{{ data.info.contact_text }}</h3>
          <ul>
            <router-link
              :to="{ name: 'contact', params: { locale: $root.$i18n.locale } }"
            >
              <li>
                <img src="/static/images/letter-icon.svg" alt="map-icon" />
                {{ data.info.footer_email }}
              </li>
            </router-link>
            <a :href="`tel:${data.info.footer_phone}`">
              <li>
                <img src="/static/images/phone-icon.svg" alt="phone-icon" />
                {{ data.info.footer_phone }}
              </li>
            </a>
            <li class="address">
              <img src="/static/images/map-icon.svg" walt="map-icon" />
              {{ data.info.footer_address }}
            </li>
          </ul>
          <h3 class="bold">Follow us</h3>
          <div class="socials">
            <a :href="data.info.footer_linkedin" target="_blank" class="social">
              <img src="/static/images/social-linkedin.svg" alt="linkedin" />
            </a>
            <a :href="data.info.footer_insta" target="_blank" class="social">
              <img src="/static/images/social-instagram.svg" alt="instagram" />
            </a>
            <a :href="data.info.footer_youtube" target="_blank" class="social">
              <img src="/static/images/social-youtube.svg" alt="youtube" />
            </a>
            <a :href="data.info.footer_facebook" target="_blank" class="social">
              <img src="/static/images/social-facebook.svg" alt="facebook" />
            </a>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col d-flex justify-content-center">
          <span class="copyright"
            >Copyright 2021 T Gear All rights reserved</span
          >
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
import { mapState } from "vuex";

export default {
  name: "default-footer",

  computed: {
    ...mapState("data", ["data"]),
  },
};
</script>

<style lang="scss">
.footer-content {
  .footer-text {
    & > p {
      &:first-child {
        margin-bottom: 1rem;
      }
    }
  }

  p {
    font-size: $navigation-font-size;
    font-weight: normal;
    color: #4f4e4e;
    line-height: 2rem;
  }
}

@media (max-width: 768px) {
  .footer-content {
    .footer-text {
      color: #4f4e4e;

      a {
        color: #4f4e4e;
      }

      & > p {
        &:first-child {
          margin-bottom: 0;
        }
      }
    }
  }
  .footer {
    p {
      // font-size: 1rem;
    }

    strong {
      display: none;
    }
  }
}
</style>

<style lang="scss" scoped>
.footer {
  background-color: #f4f4f4;
  padding: 20px 0;
  margin-top: auto;
  color: #4f4e4e;
  font-size: $navigation-font-size;

  .logo-gray {
    padding-bottom: 10px;
  }

  .gray-line {
    border-bottom: 1px solid #4f4e4e;
    height: 100%;
  }

  .footer-content {
    padding-top: 20px;
    font-family: $main-font;

    p {
      font-size: $navigation-font-size;
      font-weight: normal;
      color: #4f4e4e;
    }
  }

  h3 {
    font-family: $main-font;
    font-weight: normal;
    margin-bottom: 15px;

    &.bold {
      font-weight: bold;
    }
  }

  ul {
    margin-bottom: 20px;
  }

  li {
    font-size: $navigation-font-size;
    font-family: $main-font;
    font-weight: normal;
    color: #4f4e4e;
    display: flex;
    align-items: center;
    align-items: flex-start;
    margin-bottom: 10px;

    img {
      margin-right: 10px;
    }

    a {
      color: #4f4e4e;
      font-size: $navigation-font-size;
      font-family: $main-font;
      font-weight: normal;
    }
  }

  .socials {
    .social {
      margin-right: 15px;
      width: 30px;
      height: 30px;
      object-fit: cover;
      display: inline-block;

      img {
        width: 100%;
        height: 100%;
      }
    }
  }

  .copyright {
    margin-top: 100px;
    font-size: $button-font-size;
    color: #4f4e4e;
    font-family: $main-font;
    font-weight: normal;
  }
}

@media (max-width: 768px) {
  .footer {
    .footer-content {
      padding-top: 0;
    }

    .footer-addresses {
      margin-top: 15px;
    }

    li {
      // font-size: 1rem;
      img {
        width: 20px;
        height: 20px;
      }

      &.address {
        max-width: 170px;
      }
    }

    .footer-title {
      display: none;
    }

    h3 {
      display: none;
    }

    .socials {
      .social {
        width: 20px;
        height: 20px;
      }
    }

    .copyright {
      margin-top: 20px;
    }
  }

  .container {
    padding-left: 30px;
    padding-right: 30px;
  }
}

@media (max-width: 320px) {
  .container {
    padding-left: 15px;
    padding-right: 15px;
  }
}
</style>
