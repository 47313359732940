<template>
  <div class="accordion-body">
    <p v-html="text"></p>

    <div class="previews row">
      <router-link
        v-for="(book, index) in data.books"
        :key="'book' + index"
        :to="{ name: 'book-details', params: { slug: book.slug } }"
        :class="colClass"
      >
        <accordion-body-item
          :title="book.title"
          :img="book.image"
          color="green"
        >
        </accordion-body-item>
      </router-link>

      <router-link
        v-for="(software, index) in data.software"
        :key="'software' + index"
        :to="{ name: 'software-details', params: { slug: software.slug } }"
        :class="colClass"
      >
        <accordion-body-item
          :title="software.title"
          :img="software.image"
          color="blue"
        >
        </accordion-body-item>
      </router-link>
    </div>

    <div class="previews row">
      <router-link
        v-for="(gear, index) in data.gear"
        :key="'gear' + index"
        :to="{ name: 'gear-details', params: { slug: gear.slug } }"
        :class="colClass"
      >
        <accordion-body-item
          :title="gear.title"
          :img="gear.image"
          color="orange"
        >
        </accordion-body-item>
      </router-link>
    </div>
  </div>
</template>

<script>
import AccordionBodyItem from "./AccordionBodyItem";

export default {
  name: "accordino-body",

  components: {
    AccordionBodyItem,
  },

  props: {
    data: {
      type: Object,
      default: () => [],
    },
    text: {
      type: String,
      default: "",
    },
    id: {
      type: String,
      default: "",
    },
  },

  computed: {
    colClass() {
      return "col-sm-6";
    },
  },

  methods: {
    //    onClickPreview(item) {
    //      this.$emit("show-slider", item, this.id);
    //    },
  },
};
</script>

<style lang="scss" scoped>
.previews {
  width: 100%;
}
</style>
