<template>
  <div class="select-wrapper" :class="{ value: payload, invalid: !localValid }">
    <span
      v-if="properties.label"
      class="label"
      :class="{ active: active || payload, value: payload }"
    >
      {{ properties.label[lang] }}
      <span v-if="properties.required">*</span>
    </span>
    <select
      v-model="payload"
      @focus="onFocus"
      @blur="onBlur"
      :name="id"
    >
      <option
        :value="option.id"
        v-for="(option, index) in properties.options.list"
        :key="index"
      >
        {{ option[lang] }}
      </option>
    </select>
  </div>
</template>

<script>
export default {
  name: "select-field",

  props: {
    id: {
      type: String,
    },
    properties: {
      type: Object,
    },
    value: {
      type: Boolean,
      default: true,
    },
  },

  data() {
    return {
      payload: '',
      active: false,
      localValid: true,
    };
  },

  computed: {
    lang() {
      if(this.$i18n.locale == "nl") {
        return "nl";
      }
      return "gb"
    }
  },

  watch: {
    'properties.validate': function () {
      this.validate();
    }
  },

  methods: {
    onFocus() {
      this.active = true;
    },
    onBlur() {
      this.active = false;
    },
    validate() {
      if(this.properties.required) {
        if(this.payload != 1) {
          this.$emit("input", false);
          this.localValid = false;
        } else {
          this.$emit("input", true);
          this.localValid = true;
        }
      }
    }
  },
};
</script>

<style lang="scss" scoped>
.select-wrapper {
  position: relative;
  background-color: #e9e9e9;
  z-index: 1;
  margin-top: 30px;
  border-bottom: 3px solid #bebdbd;
  // padding-right: 15px;

  &.invalid {
    border-color: #ff1a00;
  }

  &.value {
    border-color: #fdaa29;
    // background-color: #fffbf4;

    select {
      color: #4f4e4e;
      background: url("/static/images/select-arrow-down.png") #fffbf4 no-repeat
        98.5% !important;
      background: url("/static/images/select-arrow-down.png") #fffbf4 no-repeat
        calc(100% - 10px) !important;
    }
  }

  select {
    z-index: 2;
    width: 100%;
    border: none;
    background-color: transparent;
    color: #bebdbd;
    font-size: $inline-font-size;
    border-radius: 0;
    padding: 13px 30px 13px 20px;
    min-height: 58px;

    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background: url("/static/images/select-arrow-down.png") #e9e9e9 no-repeat
      98.5% !important;
    background: url("/static/images/select-arrow-down.png") #e9e9e9 no-repeat
      calc(100% - 10px) !important;

    &::-ms-expand {
      display: none;
    }

    &:focus {
      outline: none;
    }
  }

  .label {
    z-index: 1;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 15px;
    color: #bebdbd;
    font-size: $inline-font-size;
    transition: 0.3s all;

    &.active {
      top: -15px;
      font-size: 1rem;
    }

    &.value {
      color: #40a780;
    }
  }
}

@media (max-width: 768px) {
  .select-wrapper {
    select,
    .label {
      // font-size: 18px;

      option {
        // font-size: 18px;
      }
    }
  }
}
</style>
