<template>
  <div class="preview">
    <div class="img">
      <img :src="img" alt />
    </div>
    <h2 class="title" :class="{ [color]: true }">{{ title }}</h2>
  </div>
</template>

<script>
export default {
  name: "accordino-body-item",

  props: {
    img: {
      type: String,
      default: "",
    },
    title: {
      type: String,
      default: "",
    },
    color: {
      type: String,
      default: "",
    },
  },

  methods: {
    //    onClickPreview(item) {
    //      this.$emit("show-slider", item, this.id);
    //    },
  },
};
</script>

<style lang="scss" scoped>
.preview {
  cursor: pointer;
  margin-right: 1rem;

  .img {
    overflow: hidden;
    background-color: #fff;
    margin: 19px 0;

    width: 213.3px;
    height: 213.3px;

    img {
      width: 100%;
      height: 100%;
      // object-fit: cover;
      object-position: center center;

      object-fit: contain;
    }
  }

  .title {
    font-family: $main-font;
    font-size: $inline-font-size;
    font-weight: 300;

    &.green {
      color: #40a780;
    }

    &.orange {
      color: #d16419;
    }

    &.blue {
      color: #0088ce;
    }
  }
}

@media (min-width: 1200px) {
  .preview {
    .img {
      width: 270.033px;
      height: 270.033px;
    }
  }
}
@media (min-width: 1600px) {
  .preview {
    .img {
      width: 266.833px;
      height: 266.833px;
    }
  }
}

@media (max-width: 1024px) {
  .previews {
    flex-direction: column;
  }
}

@media (max-width: 992px) {
  .preview {
    .img {
      width: 236.5px;
      height: 236.5px;
    }
  }
}
@media (max-width: 768px) {
  .preview {
    .img {
      width: 149px;
      height: 149px;
    }
  }
}

@media (max-width: 576px) {
  .preview {
    text-align: center;
    .img {
      width: 10rem;
      height: 10rem;
      margin: 0.4rem auto;
    }
  }
}
</style>
