import api from "@/api"

const data = {
  namespaced: true,

  state: {
    data: {}
  },

  getters: {
    data: (state) => state.data,
  },

  mutations: {
    setData(state, data) {
      state.data = data
    }
  },

  actions: {
    getDataByUrl({ commit }, url) {
      api.getDataByUrl(url).then(({ data }) => {
        commit('setData', data)
      })
    },
    setData({commit}, data) {
      commit('setData', data)
    }
  },
}

export default data
