<template>
  <div class="filters-wrapper">
    <div class="container">
      <div class="row">
        <div class="col">
          <h1 class="label desktop">
            <span class="img"></span>
            {{ isChangedFilter ? $tc("filtered") : $tc("filter") }}
            <div v-if="isChangedFilter">
              <a href="#" class="clear-filter" @click.prevent="onClearFilter">{{
                $tc("wipefilter")
              }}</a>
            </div>
          </h1>
          <div class="filter-mobile-container mobile">
            <div class="container">
              <div class="row">
                <div class="col">
                  <h1
                    @click="onClickFilter"
                    class="label mobile"
                    :class="{ blue: isChangedFilter }"
                  >
                    <span class="img"></span>
                    <span>{{
                      isChangedFilter ? $tc("filtered") : $tc("wipefilter")
                    }}</span>
                    <span
                      v-if="isChangedFilter"
                      class="reset-filter"
                      @click.stop.prevent="onClear"
                    >
                      <i class="fas fa-times"></i>
                    </span>
                  </h1>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row filters-row" v-if="data.education">
        <div class="col-4">
          <div class="title">{{ $tc("learnline") }}</div>
          <div class="filters">
            <div
              class="filter-item"
              v-for="(filter, index) in data.education"
              :key="index"
            >
              <checkbox
                v-model="value['learnline'][filter.id]"
                :id="`filter-${filter.title}`"
                @input="emit"
                >{{ filter.title }}</checkbox
              >
            </div>
          </div>
        </div>
        <div class="col-8" v-if="data.module">
          <div class="title">Module</div>
          <div class="row">
            <div class="col-6">
              <div class="filters">
                <div
                  class="filter-item"
                  v-for="(filter, index) in data.module.slice(0, !more ? 3 : 5)"
                  :key="index"
                >
                  <checkbox
                    v-model="value['module'][filter.id]"
                    :id="`filter-${filter.title}`"
                    @input="emit"
                  >
                    {{ filter.title }}</checkbox
                  >
                </div>
              </div>
            </div>
            <div class="col-6">
              <div class="filters">
                <div
                  class="filter-item"
                  v-for="(filter, index) in data.module.slice(
                    !more ? 3 : 5,
                    !more ? 5 : data.module.length
                  )"
                  :key="index"
                >
                  <checkbox
                    :id="`filter-${filter.title}`"
                    v-model="value['module'][filter.id]"
                    @input="emit"
                    >{{ filter.title }}</checkbox
                  >
                </div>
                <div class="filter-item" v-if="!more">
                  <a href="#" class="btn-more" @click.prevent="onClickMore">{{
                    $tc("more")
                  }}</a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <filter-mobile
      ref="filter-mobile"
      :show="isShowMobileFilter"
      @filter="onFilter"
      @close="onCloseMobileFilter"
    />
  </div>
</template>

<script>
import FilterMobile from "./FilterMobile";
import CheckBox from "../inputs/CheckBox";
import { filter as filterMixin } from "@/mixins";
import { mapState } from "vuex";

export default {
  name: "filters",

  components: {
    FilterMobile,
    checkbox: CheckBox,
  },

  mixins: [filterMixin],

  data() {
    return {
      isShowMobileFilter: false,
    };
  },

  i18n: {
    messages: {
      nl: {
        more: "Meer",
        filtered: "Resultaten gefilterd",
        filter: "Filter de resultaten",
        wipefilter: "wis alle filters",
      },
      en: {
        more: "Show more",
        filtered: "Results filtered",
        filter: "Filter results",
        wipefilter: "Delete all filters",
      },
    },
  },

  methods: {
    onClear() {
      this.onClearFilter();
      this.$refs["filter-mobile"].clear();
    },
    onCloseMobileFilter() {
      this.isShowMobileFilter = false;
    },
    emit() {
      this.$emit("change", this.checked);
    },
    onFilter(value) {
      this.value = value;
      this.isShowMobileFilter = false;
      this.$emit("change", this.checked);
    },
  },

  computed: {
    ...mapState("data", ["data"]),
    isChangedFilter() {
      if (this.value["learnline"] === {} && this.value["module"] === {})
        return false;

      for (let learnlineId in this.value["learnline"]) {
        if (this.value["learnline"][learnlineId]) return true;
      }

      for (let moduleId in this.value["module"]) {
        if (this.value["module"][moduleId]) return true;
      }

      return false;
    },
  },
};
</script>

<style lang="scss" scoped>
.filters-wrapper {
  padding-bottom: 50px;

  .clear-filter {
    color: #0088ce;
    font-size: $inline-font-size;
    font-weight: 500;
    margin-left: 40px;
  }

  .btn-more {
    color: #0088ce;
    font-size: $inline-font-size;
    margin-left: 40px;
  }

  h1 {
    font-size: $smallest-title-font-size;
    font-weight: 300;
    color: #4f4e4e;
    margin: 37px 0;
  }

  .filter-mobile-container {
    background-color: #f4f4f4;
    width: 100vw;
    position: fixed;
    bottom: 0;
    left: 0;
    z-index: 2;
  }

  .label {
    display: flex;
    align-items: center;

    .reset-filter {
      margin-left: auto;
      color: #4f4e4e;
    }

    .img {
      background: url("/static/images/filter-icon.png") no-repeat center center;
      width: 35px;
      height: 35px;
      margin-right: 15px;
    }

    &.mobile {
      display: none;
    }

    &.mobile {
      &.blue {
        background-color: #0088ce;
        color: #fff;
        padding: 10px 35px;
        margin-left: -15px;
        margin-right: -15px;

        .img {
          width: 35px;
          height: 35px;
          margin-right: 15px;
          background: url("/static/images/filter-icon-mobile.png") no-repeat
            center center;
        }
      }

      margin: 0;
      padding: 10px;
    }
  }

  .title {
    background-color: #e9e9e9;
    border-radius: 5px;
    padding: 10px 38px;
    color: #4f4e4e;
    font-weight: 600;
  }

  .filters {
    display: flex;
    flex-direction: column;
    margin: 20px 0;

    .filter-item {
      font-size: 1.2rem;
      color: #4f4e4e;
      display: flex;
      align-items: flex-start;
      user-select: none;
    }
  }
}

@media (max-width: 768px) {
  .filters-wrapper {
    padding: 0;

    .label {
      &.mobile {
        display: flex;
      }

      &.desktop {
        display: none;
      }
    }

    h1 {
      margin: 15px 0;
      font-size: $navigation-font-size;
      display: flex;
      align-items: center;

      img {
        margin-right: 15px;
      }
    }

    .filters-row {
      display: none;
    }
  }
}
</style>
