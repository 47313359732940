<template>
  <div class="page ladderino-wrapper">
    <div class="main header">
      <div class="container">
        <default-header />
      </div>
    </div>

    <section class="section title">
      <div class="container">
        <div class="row">
          <div class="col">
            <back-btn />
            <h1>{{ data.info.title }}</h1>
            <p>
              {{ data.info.subtitle }}
              <br />
              <span>{{ data.info.header }}</span>
            </p>
          </div>
        </div>
      </div>
    </section>

    <section class="section conditions">
      <div class="container">
        <div class="row">
          <div class="col">
            <h1>Conditions</h1>
          </div>
        </div>
        <div class="row">
          <div
            class="col-xl-6 col-lg-6"
            v-for="(condition, index) in data.conditions"
            :key="index"
          >
            <div class="condition">
              <div class="image">
                <img :src="condition.image" :alt="condition.name" />
              </div>
              <div class="info">
                <h2>{{ condition.name }}</h2>
                <p>{{ condition.text }}</p>

                <p class="examples">
                  <a
                    v-for="example in condition.examples"
                    v-bind:key="example.link"
                    :href="example.link"
                    style="display: block"
                  >
                    {{ example.name }}
                    <i class="fas fa-chevron-right"></i><br />
                  </a>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section class="section actions">
      <div class="container">
        <div class="row">
          <div class="col">
            <h1>Actions</h1>
          </div>
        </div>
        <div class="row">
          <div
            class="col-xl-6 col-lg-6"
            v-for="(action, index) in data.actions"
            :key="index"
          >
            <div class="action">
              <div class="image">
                <img :src="action.image" alt="image" />
              </div>
              <div class="info">
                <h2>{{ action.name }}</h2>
                <p>{{ action.text }}</p>
                <p class="examples">
                  <a
                    v-for="example in action.examples"
                    v-bind:key="example.link"
                    :href="example.link"
                  >
                    {{ example.name }}
                    <i class="fas fa-chevron-right"></i><br />
                  </a>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section>
      <default-footer />
    </section>
  </div>
</template>

<script>
import { DefaultFooter, BackBtn, DefaultHeader } from "@/components";
import { mapActions, mapState } from "vuex";

export default {
  name: "ladderino",

  components: {
    DefaultHeader,
    BackBtn,
    DefaultFooter,
  },

  metaInfo() {
    return {
      title: this.data.info.title,
      description: this.data.info.subtitle,
    };
  },

  data() {
    return {};
  },

  created() {
    this.getDataByUrl("/ladderino-reference/");
  },

  computed: {
    ...mapState("data", ["data"]),
  },

  methods: {
    ...mapActions({
      getDataByUrl: "data/getDataByUrl",
    }),
  },
};
</script>

<style lang="scss" scoped>
.header {
  background: #f4f4f4;
}

.examples {
  a {
    display: block;
  }
  line-height: 1rem !important;
}

.section {
  &.title {
    margin-bottom: 100px;

    h1 {
      color: #0088ce;
      font-size: $big-title-font-size;
    }

    p {
      font-size: $inline-font-size;
      color: #4f4e4e;

      span {
        color: #40a780;
      }
    }
  }

  &.actions,
  &.conditions {
    padding-top: 50px;

    h1 {
      font-size: $medium-title-font-size;
      color: #fdaa29;
    }

    .action,
    .condition {
      display: flex;
      padding-top: 25px;
      padding-bottom: 50px;

      .image {
        margin-right: 15px;
        border-radius: 0.2em;

        img {
          width: 60px;
        }
      }

      .info {
        h2 {
          color: #40a780;
          font-size: $small-title-font-size;
          font-weight: 400;
        }

        p {
          font-size: $inline-font-size;
          color: #4f4e4e;
          line-height: $inline-line-height;
        }

        a {
          color: #0088ce;
          font-size: 1rem;
          margin-top: 15px;
          display: inline-block;
        }
      }
    }
  }

  &.conditions {
    background-color: #f4f4f4;
  }

  &.actions {
    margin-bottom: 80px;
  }
}

@media (max-width: 768px) {
  .container {
    padding-left: 2rem;
    padding-right: 2rem;
  }

  .section {
    &.title {
      margin-bottom: 34px;

      h1 {
        font-size: $small-title-font-size;
      }

      p {
        font-size: $navigation-font-size;
      }
    }

    &.actions,
    &.conditions {
      h1 {
        font-size: $smallest-title-font-size;
      }

      .action,
      .condition {
        padding-bottom: 20px;

        .info {
          h2 {
            font-size: $navigation-font-size;
            line-height: 20px;
            margin-bottom: 10px;
          }

          p,
          a {
            // font-size: 1rem;
            line-height: 20px;
          }
        }
      }
    }
  }
}
</style>
