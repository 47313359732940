<template>
  <div class="page product-details-wrapper">
    <div class="main header">
      <div class="container">
        <default-header />
      </div>
    </div>

    <component
      v-if="data.item"
      :product="data.item"
      :is="getComponentByCatagory()"
    />

    <section class="section modules">
      <router-link :to="{ name: 'voordelen' }">
        <div class="container">
          <div class="row">
            <div class="col">
              <convinced-section />
            </div>
          </div>
        </div>
      </router-link>
    </section>

    <default-footer />
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import { DefaultHeader, DefaultFooter, ConvincedSection } from "@/components";

import ProductBook from "./ProductBook";
import ProductGear from "./ProductGear";
import ProductSoftware from "./ProductSoftware";

export default {
  name: "product-details",

  components: {
    DefaultHeader,
    DefaultFooter,
    ConvincedSection,

    ProductBook,
    ProductGear,
    ProductSoftware,
  },

  created() {
    const type = this.$route.meta.type;
    const slug = this.$route.params.slug;
    this.getDataByUrl(`/${type}/${slug}/`);
  },

  data() {
    return {};
  },

  methods: {
    ...mapActions({
      getDataByUrl: "data/getDataByUrl",
    }),

    getComponentByCatagory() {
      const category = this.$route.meta.type;
      switch (category) {
        case "book":
          return "ProductBook";
        case "gear":
          return "ProductGear";
        case "software":
          return "ProductSoftware";
      }
    },
  },

  computed: {
    ...mapState("data", ["data"]),
  },
};
</script>


<style lang="scss">
@media (max-width: 768px) {
  .product-info {
    .image {
      padding: 0;
      height: 300px;
    }

    .download {
      font-size: $navigation-font-size;
    }
  }
}
</style>

<style lang="scss" scoped>
.header {
  background: #f4f4f4 0% 0% no-repeat padding-box;
}

.section.downloads {
  margin-bottom: 100px;

  .downloads {
    color: #0088ce;
  }
}

.section.modules {
  .modules-parts {
    max-width: 600px;
    font-size: $inline-font-size;
    color: #4f4e4e;
  }
}

.section.systems {
  margin: 153px 0;
}

.action {
  margin-left: 100px;
}

.systems {
  display: flex;
  flex-direction: column;

  .system {
    display: flex;
    line-height: $inline-line-height;

    .title,
    .value {
      width: 100%;
      color: #4f4e4e;
      font-size: $inline-font-size;
    }

    .value {
      margin-left: auto;
    }
  }
}

.section {
  .section-title {
    color: #4f4e4e;
    font-family: Apercu, Open Sans, Arial, Helvetica, sans-serif;
    font-size: $medium-title-font-size;
    font-weight: bold;
    margin-bottom: 20px;
    display: flex;
    align-items: center;

    img {
      margin-left: 15px;
    }
  }
}

.section.modules {
  padding-bottom: 100px;
}

.section.help {
  padding: 100px 0 100px 0;

  .section-title {
    margin-bottom: 80px;
    font-size: $medium-title-font-size;
    color: #4f4e4e;
  }

  .help-item {
    text-align: center;

    .image {
      margin-bottom: 70px;
    }

    .name {
      font-size: $small-title-font-size;
      font-weight: normal;
      color: #40a780;
      margin-bottom: 20px;
    }

    p {
      color: #4f4e4e;
      font-size: $inline-font-size;
      text-align: center;
      font-family: $main-font;
      font-weight: normal;
      line-height: $inline-line-height;
    }
  }

  .link {
    color: #0088ce;
    font-family: $main-font;
    font-size: $inline-font-size;
    font-weight: normal;
  }
}

.product-info {
  .back-btn {
    padding: 37px 0;
    color: #bebdbd;
    font-size: 1rem;
    font-weight: 300;
    display: inline-block;

    .fas {
      margin-right: 5px;
    }
  }

  &.lesmateriaal {
    color: #40a780;
  }

  &.gear {
    color: #d16419;
  }

  &.software {
    color: #0088ce;
  }

  h1 {
    font-size: $big-title-font-size;
  }

  .desc {
    color: #4f4e4e;
    font-size: $inline-font-size;
  }

  .image {
    height: 700px;
    padding: 100px 0;
    display: flex;
    justify-content: center;

    img {
      object-fit: contain;
      object-position: center center;
      height: 100%;
    }
  }

  h2,
  p {
    color: #4f4e4e;
    text-align: center;
    max-width: 570px;
    margin: 0 auto;
  }

  h2 {
    font-size: $medium-title-font-size;
    font-weight: 800;
  }

  p {
    font-size: $small-title-font-size;
  }

  .download {
    background-color: #40a780;
    font-size: $inline-font-size;
    color: #fff;
    padding: 5px 27px;
    border-radius:0.25rem;
  }
}

@media (max-width: 768px) {
  .container {
    padding-left: 30px;
    padding-right: 30px;
  }

  .product-info {
    h1 {
      font-size: $small-title-font-size;
    }

    .desc {
      font-size: $navigation-font-size;
    }

    .image {
      padding: 0;
      height: 300px;
    }

    h2 {
      font-size: $smallest-title-font-size;
    }

    p {
      font-size: $navigation-font-size;
    }

    .download {
      font-size: $navigation-font-size;
    }
  }

  .section.in-the-box {
    padding-bottom: 50px;

    ul {
      li {
        // font-size: 1rem;
        color: #4f4e4e;
      }
    }
  }

  .section.help {
    padding-top: 65px;
    padding-bottom: 50px;

    .section-title {
      margin-bottom: 0;
    }

    .help-item {
      .name {
        font-size: $smallest-title-font-size;
      }

      .image {
        width: 200px;
        height: 200px;
        object-fit: cover;
        margin-left: auto;
        margin-right: auto;
        margin-top: 46px;

        img {
          width: 100%;
          height: 100%;
        }
      }

      p {
        font-size: $navigation-font-size;
      }
    }

    .link {
      font-size: $navigation-font-size;
    }
  }

  .section.downloads {
    margin-bottom: 50px;
  }

  .section.systems {
    margin-bottom: 50px;
    margin-top: 0;

    .system {
      flex-direction: column;

      .title {
        color: #bebdbd;
      }

      .title,
      .value {
        line-height: 1.5rem;
        // font-size: 1rem;
      }

      .value {
        margin-left: 25px;
      }
    }
  }

  .section.slider {
    margin-bottom: 50px;
  }

  .section.help,
  .section.in-the-box,
  .section.systems,
  .section.downloads,
  .section.modules {
    .section-title {
      font-size: $smallest-title-font-size;
    }
  }

  .section.modules {
    padding-bottom: 50px;

    .modules-parts {
      // font-size: 1rem;
    }
  }

  .action {
    margin-left: unset;
  }
}
</style>
