<template>
  <form-page api-url="demo" :form-id="5" />
</template>

<script>
import { FormPage } from "@/pages";
export default {
  name: "demo",

  components: {
    FormPage,
  },

  data() {
    return {};
  },
};
</script>
