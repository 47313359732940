<template>
  <div class="wrapper-specifications">
    <section class="section systems" v-if="options && options.length">
      <div class="container">
        <div class="row">
          <div class="col">
            <h1 class="section-title">Specificaties</h1>
          </div>
        </div>
        <div class="row">
          <div class="col-8">
            <div class="systems">
              <div class="system" v-for="(opt, index) in options" :key="index">
                <span class="title">{{ opt.title }}:</span>
                <span class="value">{{ opt.value }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
export default {
  name: "specificaties",

  props: {
    options: {
      type: Array,
      default: () => [],
    },
  },
};
</script>

<style lang="scss" scoped>
.wrapper-specifications {
  .section-title {
    font-size: $small-title-font-size;
    color: #4f4e4e;
    margin: 50px 0;
  }

  .systems {
    display: flex;
    flex-direction: column;

    .system {
      display: flex;
      line-height: $inline-line-height;

      .title,
      .value {
        width: 100%;
        color: #4f4e4e;
        font-size: $inline-font-size;
        line-height: $inline-line-height;
      }

      .value {
        margin-left: auto;
      }
    }
  }
}

@media (max-width: 768px) {
  .wrapper-specifications {
    .section-title {
      font-size: $smallest-title-font-size;
    }

    .systems {
      margin-bottom: 50px;
      margin-top: 0;

      .system {
        flex-direction: column;

        .title {
          color: #bebdbd;
        }

        .title,
        .value {
          line-height: 1.5rem;
          // font-size: 1rem;
        }

        .value {
          margin-left: 25px;
        }
      }
    }
  }
}
</style>