import axios from 'axios'
import config from '@/config'
import VueI18n from '@/i18n'

const instance = axios.create({
  baseURL: config.apiUrl,
});

// const getBaseUrl = async () => {
//   let lang = localStorage.getItem('lang')
//   return lang ? lang : 'eu'
// }

instance.interceptors.request.use(async config => {
  //config.baseURL = `http://tgear.tux`;
  const loc  = VueI18n.locale;
  config.baseURL = `https://tgear.eu/${loc}/api`;
  return config;
},
  error => Promise.reject(error));

export default instance
