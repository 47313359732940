<template>
  <div class="page home">
    <div class="main header">
      <div class="container">
        <default-header />
        <popup-havo-vwo :show="isShowHavoVwo" @close="closePopup" />
      </div>
    </div>

    <section class="main-section" v-if="data.education">
      <div class="container">
        <div class="row">
          <div class="col-2 col-2 llp-wrapper">
            <span class="learning-lines-progress"></span>
          </div>
          <div class="col">
            <h2>{{ $tc("learnline") }}</h2>
            <h1>{{ data.education.title }}</h1>
            <p>{{ data.education.intro }}</p>
            <span class="current-tag">
              {{ $tc("need") }}
              <a href="#" @click.prevent="onClickTag">
                <i
                  :class="`fas fa-graduation-cap`"
                  v-if="currentTag.incompany == '0'"
                ></i>
                <i
                  :class="`fas fa-medal`"
                  v-if="currentTag.incompany == '1'"
                ></i>
                {{ currentTag.level }}
              </a>
            </span>
          </div>
        </div>
      </div>
    </section>

    <section class="learning-accordion" v-if="data.modules">
      <learning-accordion :items="data.modules" />
    </section>

    <section class="convinced-section">
      <div class="container">
        <div class="row">
          <div class="col offset-xl-2">
            <convinced-section />
          </div>
        </div>
      </div>
    </section>

    <default-footer />
  </div>
</template>

<script>
import {
  DefaultFooter,
  DefaultHeader,
  ConvincedSection,
  learningAccordion,
} from "@/components";

import api from "@/api/index";
import PopupHavoVwo from "@/components/popups/HavoVwo";
import { common as commonMixin } from "@/mixins";
import { mapActions, mapState, mapGetters } from "vuex";

export default {
  name: "learning-lines",

  components: {
    DefaultFooter,
    DefaultHeader,
    ConvincedSection,
    learningAccordion,
    PopupHavoVwo,
  },

  i18n: {
    messages: {
      nl: {
        learnline: "Leerlijn",
        need: "Je ziet wat je nodig hebt voor:",
      },
      en: {
        learnline: "Learn pathway",
        need: "You see what you need for:",
      },
    },
  },

  metaInfo() {
    return {
      title: this.data.info.title + " - " + this.data.education.title,
      description: this.data.info.title2,
    };
  },

  data() {
    return {};
  },

  mounted() {
    if (this.$store.state.app.tags != {}) {
      this.autoShowHavoVwo();
    }

    this.getData();
  },

  mixins: [commonMixin],

  methods: {
    ...mapActions(["changeShowHavoVwo", "autoShowHavoVwo"]),
    ...mapActions({
      changeCurrentTag: "changeCurrentTag",
      setData: "data/setData",
    }),
    onClickTag() {
      this.changeShowHavoVwo(true);
    },
    closePopup() {
      this.changeShowHavoVwo(false);
      this.getData();
    },
    getData() {
      if (this.currentTag.level != "") {
        let { education } = this.$route.params;

        api
          .getDataByUrl(
            "/learning-lines/" + education + "/" + this.currentTag.id + "/"
          )
          .then(
            function (response) {
              let education = response.data.education;
              let totalDuration = 0;

              response.data.modules.forEach(function (module) {
                totalDuration += parseInt(module.standardduration);
              });
              response.data.modules.push({
                id: "12738491723497123984",
                title: education.endtitle,
                subtitle: education.endtext,
                standardduration: totalDuration,
              });
              this.setData(response.data);
            }.bind(this)
          );
      }
    },
  },

  watch: {
    "$store.state.app.tags": function () {
      this.autoShowHavoVwo();
    },
    "$store.state.app.currentTag": function () {
      this.getData();
    },
    getFullPath() {
      this.getData();
    },
  },

  computed: {
    ...mapGetters(["currentTag", "isShowHavoVwo", "lang"]),
    ...mapState("data", ["data"]),
    ...mapState(["apiUrl"]),
    getFullPath() {
      return this.$route.path;
    },
  },
};
</script>

<style lang="scss" scoped>
.header {
  background: #f4f4f4 0% 0% no-repeat padding-box;
}

.main-section {
  background: url("https://tgear.eu/data/images/learn/imageheader5.jpg")
    no-repeat center center;
  background-color: #4f4e4e;
  background-size: cover;
  min-height: 539px;
  display: flex;
  align-items: center;

  h1,
  h2,
  p {
    font-family: $main-font;
  }

  h1 {
    font-size: $big-title-font-size;
    font-weight: bold;
    letter-spacing: -1.12px;
    color: #fdaa20;
    margin: 20px 0;
  }

  h2 {
    font-size: $small-title-font-size;
    letter-spacing: -0.72px;
    color: #e9e9e9;
    font-weight: normal;
  }

  p {
    font-size: $inline-font-size;
    font-weight: normal;
    letter-spacing: 0px;
    color: #e9e9e9;
    margin-bottom: 25px;
  }

  .current-tag {
    color: #bebdbd;
    font-size: $inline-font-size;

    a {
      background-color: #f4f4f4;
      color: #4f4e4e;
      border-radius: 5px;
      padding: 8px 20px;
      margin-left: 15px;

      .fas {
        color: #d16419;
      }
    }
  }
}

.convinced-section {
  padding-bottom: 100px;
}

.llp-wrapper {
  position: relative;

  .learning-lines-progress {
    position: absolute;
    width: 5px;
    background-color: #fff;
    height: 600px;
    left: 70%;
    top: 35%;
    transform: translateX(-50%);

    &:after {
      content: "";
      display: block;
      position: absolute;
      width: 25px;
      height: 25px;
      border: 5px solid #fff;
      border-radius: 50%;
      top: -21px;
      left: 50%;
      transform: translateX(-50%);
    }
  }
}

@media (max-width: 768px) {
  .container {
    padding-left: 30px;
    padding-right: 30px;
  }

  .main-section {
    h2 {
      font-size: $smallest-title-font-size;
    }

    h1 {
      font-size: $small-title-font-size;
      // color: red;
    }

    p {
      font-size: $navigation-font-size;
    }

    .current-tag {
      // font-size: 18px;
      display: flex;
      flex-direction: column;
      align-items: flex-start;

      a {
        // font-size: 1rem;
        padding: 5px 15px;
        margin-left: 0;
        margin-top: 5px;
      }
    }
  }

  .llp-wrapper {
    .learning-lines-progress {
      top: 30%;
    }
  }
}

@media (min-width: 1200px) {
  .llp-wrapper {
    .learning-lines-progress {
      top: 40%;
    }
  }
}
</style>
