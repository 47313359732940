<template>
  <div class="page downloads">
    <div class="main header">
      <div class="container">
        <default-header />
      </div>
    </div>

    <section class="section download">
      <div class="container">
        <div class="row">
          <div class="col">
            <back-btn />
            <h1>
              {{ data.info.title }}
              <div class="download-icon">
                <img src="/static/images/download-icon.png" alt="img" />
              </div>
            </h1>
            <p class="intro">
              {{ data.info.intro }}
            </p>
            <a href="#">{{ data.info.payoff }}</a>
          </div>
        </div>
      </div>
    </section>

    <section class="section software">
      <div class="container">
        <div class="row">
          <div class="col">
            <div class="softwares">
              <div
                class="software"
                v-for="(software, index) in data.software"
                :key="index"
              >
                <div class="row mobile">
                  <div class="col">
                    <h2 class="d-flex align-items-center">
                      <div class="download-image-icon">
                        <img
                          v-if="software.download_icon"
                          :src="software.download_icon"
                          alt="alt"
                        />
                      </div>
                      {{ data.info.software1title }}
                    </h2>
                  </div>
                </div>

                <div class="row">
                  <!-- logo image  -->
                  <div class="col-2">
                    <div
                      class="download-image-icon desktop d-flex align-items-center"
                    >
                      <img
                        v-if="software.download_icon"
                        :src="software.download_icon"
                        alt="alt"
                      />
                    </div>
                  </div>

                  <!-- content right side of image  -->
                  <div class="col-12 col-md-10">
                    <h2 class="desktop">
                      {{ software.title }}
                    </h2>
                    <p class="mt-3" v-html="software.download_subtitle"></p>
                    <div>
                      <a :href="software.software_url" class="btn-download">{{
                        $tc("download")
                      }}</a>
                    </div>

                    <!-- <a href="3"> -->
                    <!--   {{ $tc("versions") }} -->
                    <!--   <i class="fas fa-chevron-right"></i> -->
                    <!-- </a> -->
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <div class="section arduion-ide mt-5">
      <div class="container">
        <div class="row">
          <div class="col">
            <h1>{{ data.info.title3 }}</h1>
          </div>
        </div>
        <div class="row">
          <p class="col-xl-6 col-lg-6" v-html="data.info.content2"></p>
        </div>
        <div class="border-bottom"></div>
      </div>
    </div>

    <div class="section manuals">
      <div class="container">
        <div class="row">
          <div class="col">
            <h1>{{ data.info.title4 }}</h1>
          </div>
        </div>
        <div class="row">
          <p class="col" v-html="data.info.intro2"></p>
        </div>
        <div class="row manuals">
          <div
            class="manual col-xl-6 col-lg-6 d-flex align-items-start mt-lg-3"
            v-for="(gear, index) in data.gear"
            :key="index"
          >
            <div class="row">
              <div class="col-12 col-md-4">
                <!-- image left  -->
                <div class="d-flex">
                  <div class="img">
                    <img :src="gear.image" alt="gear" />
                  </div>
                  <div class="d-flex justify-content-center title-wrapper">
                    <h2 class="title mobile">{{ gear.title }}</h2>
                  </div>
                </div>
              </div>
              <div class="col-12 col-md-8">
                <!-- titel en link rechts  -->
                <div class="manual-text-container">
                  <h2 class="title desktop">{{ gear.title }}</h2>
                  <div class="links">
                    <div v-for="(link, index2) in gear.manuals" :key="index2">
                      <a :href="link.link" target="_blank">
                        {{ link.name }}
                        <i class="fas fa-chevron-right"></i>
                      </a>
                    </div>
                  </div>
                  <!-- <div class="d-flex"> -->
                  <!--   <a href="#" class="more"> -->
                  <!--     Andere versies -->
                  <!--     <i class="fas fa-chevron-right"></i> -->
                  <!--   </a> -->
                  <!-- </div> -->
                </div>
              </div>
            </div>

            <!-- image left  -->
            <!-- <div class="d-flex">
              <div class="img">
                <img :src="gear.image" alt="gear" />
              </div>
              <div class="d-flex justify-content-center title-wrapper">
                <h2 class="title mobile">{{ gear.title }}</h2>
              </div>
            </div> -->

            <!-- titel en link rechts  -->
            <!-- <div>
              <h2 class="title desktop">{{ gear.title }}</h2>
              <div class="links">
                <div v-for="(link, index2) in gear.manuals" :key="index2">
                  <a :href="link.file">
                    {{ link.name }}
                    <i class="fas fa-chevron-right"></i>
                  </a>
                </div>
              </div> -->
            <!-- <div class="d-flex"> -->
            <!--   <a href="#" class="more"> -->
            <!--     Andere versies -->
            <!--     <i class="fas fa-chevron-right"></i> -->
            <!--   </a> -->
            <!-- </div> -->
            <!-- </div> -->
          </div>
          <!-- <div class="col-xl-6 col-lg-6"> -->
          <!--   <div class="manuals"> -->
          <!--     <div class="manual" v-for="(manual, index) in manuals.slice(4, 8)" :key="index"> -->
          <!--       <div class="d-flex"> -->
          <!--         <div class="img"> -->
          <!--           <img :src="manual.image" alt="alt" /> -->
          <!--         </div> -->
          <!--         <div class="d-flex justify-content-center title-wrapper"> -->
          <!--           <h2 class="title mobile">{{ manual.name }}</h2> -->
          <!--         </div> -->
          <!--       </div> -->
          <!--       <div> -->
          <!--         <h2 class="title desktop">{{ manual.name }}</h2> -->
          <!--         <div class="links"> -->
          <!--           <div v-for="(link, index2) in manual.links" :key="index2"> -->
          <!--             <a :href="link.link"> -->
          <!--               {{ link.title }} -->
          <!--               <i class="fas fa-chevron-right"></i> -->
          <!--             </a> -->
          <!--           </div> -->
          <!--         </div> -->
          <!--         <div class="d-flex"> -->
          <!--           <a href="#" class="more"> -->
          <!--             Andere versies -->
          <!--             <i class="fas fa-chevron-right"></i> -->
          <!--           </a> -->
          <!--         </div> -->
          <!--       </div> -->
          <!--     </div> -->
          <!--   </div> -->
          <!-- </div> -->
        </div>
      </div>
    </div>

    <default-footer />
  </div>
</template>

<script>
import { DefaultHeader, DefaultFooter, BackBtn } from "@/components";
import { mapActions, mapState } from "vuex";

import { common as commonMixin } from "@/mixins";

export default {
  name: "downloads",

  components: {
    DefaultHeader,
    BackBtn,
    DefaultFooter,
  },

  data() {
    return {};
  },

  metaInfo() {
    return {
      title: this.data.title,
      description: this.data.intro,
    };
  },

  i18n: {
    messages: {
      nl: {
        download: "Download gratis",
        versions: "Andere versies",
      },
      en: {
        download: "Download for free",
        versions: "Different versions",
      },
    },
  },

  created() {
    this.getDataByUrl("/download/");
  },

  mixins: [commonMixin],

  mounted() {},

  methods: {
    ...mapActions({
      getDataByUrl: "data/getDataByUrl",
    }),
  },

  computed: {
    ...mapState("data", ["data"]),
  },
};
</script>

<style lang="scss" scoped>
.page {
  .header {
    background: #f4f4f4 0% 0% no-repeat padding-box;
  }

  .mobile {
    display: none !important;
  }

  .section.download {
    margin-bottom: 40px;

    .back {
      padding: 70px 0 40px 0;

      a {
        color: #bebdbd;
        font-size: 14px;

        .fas {
          margin-right: 5px;
        }
      }
    }

    h1 {
      color: #0088ce;
      font-size: $big-title-font-size;
      display: flex;
      align-items: center;
      .download-icon {
        display: flex;
        img {
          margin-left: 10px;
        }
      }
    }

    .intro {
      line-height: $inline-line-height;
    }

    a,
    p {
      font-size: $inline-font-size;
    }

    p {
      color: #4f4e4e;
      line-height: 26px;
    }

    a {
      color: #40a780;
    }
  }

  .section.software {
    .software {
      border-bottom: 1px solid #bebdbd61;
      padding-bottom: 50px;
      padding-top: 50px;

      .image {
        background-color: #f4f4f4;
        border-radius: 5px;
        padding: 10px;
        display: flex;
        align-items: center;
        height: 100%;
        justify-content: center;
      }

      h2 {
        color: #0088ce;
        font-size: $small-title-font-size;
        font-weight: 300;
        justify-content: center;
        display: flex;
        justify-content: flex-start;

        img {
          margin-right: 15px;
        }
      }

      .download-image-icon {
        background-color: #f4f4f4;
        border-radius: 0.25rem;
        width: 100%;
        // height: 5rem;
        img {
          width: 100%;
        }
      }

      p {
        font-size: $inline-font-size;
        font-weight: 400;
        color: #4f4e4e;
      }

      .btn-download {
        font-size: $inline-font-size;
        color: #fff;
        padding: 0.25rem 25px;
        border-radius: 5px;
        background-color: #40a780;
        display: inline-block;
        margin-top: 30px;

        box-shadow: 0px 2px 2px #0000003d;
      }

      a {
        color: #0088ce;
        font-size: $inline-font-size;
        display: inline-block;
        margin-top: 5px;
      }
    }
  }

  .section.arduion-ide {
    margin-bottom: 50px;

    .container {
      // border-bottom: 1px solid #bebdbd61;
      // padding-bottom: 50px;
    }
    .border-bottom {
      padding-top: 50px;
      border-bottom: 1px solid #bebdbd61;
    }

    h1 {
      font-size: $small-title-font-size;
      color: #40a780;
      font-weight: 400;
    }

    p {
      color: #4f4e4e;
      font-size: $inline-font-size;
    }

    a {
      color: #0088ce;
      font-size: $inline-font-size;
      display: inline-block;
      margin-top: 5px;
    }
  }

  .section.manuals {
    margin-bottom: 150px;

    h1 {
      font-size: $small-title-font-size;
      color: #40a780;
      font-weight: 400;
    }

    p {
      color: #4f4e4e;
      font-size: $inline-font-size;
    }

    a {
      color: #0088ce;
      font-size: $inline-font-size;
      display: inline-block;
      margin-top: 5px;
    }

    .manuals {
      margin-top: 30px;

      .manual {
        display: flex;
        min-height: 220px;
        align-items: center;
        border-bottom: 1px solid #bebdbd61;
        height: 100%;

        img {
          width: 100%;
        }
        .manual-text-container {
          // height: 100%;
          // display: flex;
          // flex-direction: column;
          // justify-content: center;
        }

        .title.mobile {
          display: none;
        }

        h2 {
          color: #d16419;
          font-weight: 300;
          // font-size: $smallest-title-font-size;
          font-size: $small-title-font-size;
        }

        a {
          font-size: 0.9rem;
          display: flex;
          align-items: baseline;
          .fas {
            margin-left: auto;
            padding-left: 0.5rem;
          }
        }

        a.more {
          color: #bebdbd;
          margin-left: auto;
          margin-top: 30px;
          margin-bottom: 20px;
        }
      }
    }
  }
}

@media (max-width: 992px) {
  .page {
    .section.manuals {
      .manuals {
        .manual {
          .manual-text-container {
            height: 100%;
            display: flex;
            flex-direction: column;
            justify-content: center;
          }
        }
      }
    }
  }
}

@media (max-width: 768px) {
  .container {
    padding-left: 30px;
    padding-right: 30px;
  }

  .page {
    .section.download {
      .back {
        padding: 15px 0;
      }

      h1 {
        font-size: $small-title-font-size;
        // font-size: $big-title-font-size;
        margin-bottom: 50px;
      }

      a,
      p {
        font-size: $navigation-font-size;
        // font-size: $small-title-font-size;
      }

      .intro {
        line-height: 26px;
      }
    }

    .mobile {
      display: block !important;
    }

    .desktop {
      display: none !important;
    }

    .section.manuals {
      h1 {
        // font-size: $smallest-title-font-size;
        font-weight: bold;
      }

      a,
      p {
        font-size: $navigation-font-size;
      }

      a {
        margin-top: 10px;
      }

      .manuals {
        .manual {
          flex-direction: column;

          .img {
            width: 40%;
          }

          .title-wrapper {
            align-items: center;

            h2 {
              margin-top: 0 !important;
            }
          }

          .links {
            transform: translateY(-5px);
            div {
              &:first-child {
                margin-top: 0;
              }
            }

            margin-bottom: 1rem;

            a {
              display: inline-block;
              width: 100%;
              display: flex;
              align-items: center;
              margin-top: 0;
              margin-right: 5px;

              .fas {
                margin-left: auto;
              }
            }
          }

          .title.desktop {
            display: none;
          }

          .title.mobile {
            display: block;
            font-size: $navigation-font-size;
            margin-top: 20px;
          }

          a {
            // font-size: 14px;
          }

          a.more {
            margin-bottom: 25px;
          }
        }
      }
    }

    .section.software {
      .software {
        h2 {
          // font-size: $smallest-title-font-size;
          margin: 10px 0;
          font-weight: 600;
        }

        p {
          font-size: $navigation-font-size;
          line-height: 26px;
        }

        a {
          font-size: $navigation-font-size;
        }

        .download-image-icon {
          width: 20%;
          img {
            width: 100%;
            margin-right: 0;
          }
          background-color: transparent;
        }

        .btn-download {
          padding: 2px 15px;
          font-size: $navigation-font-size;
        }
      }
    }

    .section.arduion-ide {
      h1 {
        // font-size: $smallest-title-font-size;
        font-weight: bold;
        color: #0088ce;
      }

      a,
      p {
        font-size: $navigation-font-size;
      }

      a {
        margin-top: 10px;
      }
    }
  }
}

@media (max-width: 992px) {
  .page {
    .section.manuals {
      .manuals {
        .manual {
          min-height: unset;
        }
      }
    }
  }
}
</style>
