<template>
  <div>
    <div
      class="checkbox"
      v-for="checkbox in properties.options.list"
      :key="checkbox.id"
    >
      <div
        class="checkbox-custom-input-wrapper"
        :class="{ 'checkbox-custom-input-wrapper--checked': payload.includes(checkbox.id) }"
        v-if="!checkbox.deleted"
      >
        <input
          type="checkbox"
          class="checkbox-hidden-input"
          :value="checkbox.id"
          v-model="payload"
          :checked="payload.includes(checkbox.id)"
          :name="id + '[]'"
          :id="`cb${checkbox.id}${id}`"
        />
        <span
          v-if="payload.includes(checkbox.id)"
          class="checkbox-custom-input checkbox-custom-input--checked"
        >
          &#x2713;
          <!-- tickmark -->
        </span>
      </div>
      <label class="label" :for="`cb${checkbox.id}${id}`"
        v-if="!checkbox.deleted"
      >
        {{ checkbox[lang] }}
      </label>
    </div>
  </div>
</template>

<script>
export default {
  name: "checkbox-field",

  props: {
    id: {
      type: String,
      default: "",
    },
    properties: {
      type: Object,
    },
    value: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      payload: [],
    };
  },

  computed: {
    lang() {
      if (this.$i18n.locale == "nl") {
        return "nl";
      }
      return "gb";
    },
  },

  methods: {
    onInput(event) {
      this.$emit("input", event.target.checked);
    },
  },
};
</script>
