import config from '@/config'

const common = {
  methods: {
    getColor(title) {
      return {
        Elektrotechniek: "blue",
        "Industriele automatisering": "yellow",
        Werktuigbouwkunde: "green"
      }[title];
    },
    getImage(url) {
      return `${url}`.replace(/.[\w]+$/, ".png");
    },
    getAdvantageImage(url) {
      return `${config.apiUrl}/${url}`
    }
  }
}

export default common