import Vue from 'vue'
import VueRouter from 'vue-router'
import i18n from '@/i18n'
import VueMeta from 'vue-meta'

import {
  Home,
  Lang,
  LearningLines,
  LearningLinesCommon,
  WhatDoINeed,
  Advantages,
  Downloads,
  OverTGear,
  Ladderino,
  LandingPage,
  PageNotFound,
  Contact,
  Demo,
  ProductDetails
} from '@/pages'

Vue.use(VueRouter)
Vue.use(VueMeta);

const routes =
  [
    {
      path: '/:locale?',
      component: Lang,
      children: [
        {
          path: '',
          name: 'home',
          component: Home,
          meta: {
            index: 1,
          }
        },
        {
          path: 'learning-pathways',
          name: 'learning-lines',
          component: LearningLinesCommon,
          meta: {
            index: 2
          }
        },
        {
          path: 'learning-pathways/:education/',
          name: 'LearningLinesEducation',
          component: LearningLines,
          meta: {
            index: 3
          }
        },
        {
          path: 'what-do-i-need',
          name: 'what-do-i-need',
          component: WhatDoINeed,
          meta: {
            index: 4
          },
        },
        {
          path: 'book/:slug/',
          name: 'book-details',
          component: ProductDetails,
          meta: {
            type: 'book',
            index: 5
          }
        },
        {
          path: 'gear/:slug/',
          name: 'gear-details',
          component: ProductDetails,
          meta: {
            type: 'gear',
            index: 5
          }
        },
        {
          path: 'software/:slug/',
          name: 'software-details',
          component: ProductDetails,
          meta: {
            type: 'software',
            index: 5
          }
        },
        {
          path: 'advantages',
          name: 'voordelen',
          component: Advantages,
          meta: {
            index: 5
          }
        },
        {
          path: 'downloads',
          name: 'downloads',
          component: Downloads,
          meta: {
            index: 5
          }
        },
        {
          path: 'about-t-gear',
          name: 'over-t-gear',
          component: OverTGear,
          meta: {
            index: 5
          }
        },
        {
          path: 'ladderino',
          name: 'ladderino',
          component: Ladderino,
          meta: {
            index: 5
          }
        },
        {
          path: 'contact',
          name: 'contact',
          component: Contact,
          meta: {
            index: 5
          }
        },
        {
          path: 'demo',
          name: 'demo',
          component: Demo,
          meta: {
            index: 5
          }
        },
        {
          path: 'go/:slug/',
          name: 'go',
          component: LandingPage,
          meta: {
            index: 5
          }
        }
      ]
    },
    {
      path: '*',
      name: 'PageNotFound',
      component: PageNotFound,
      meta: {
        index: 5
      }
    }
  ]

const router = new VueRouter({
  base: process.env.BASE_URL,
  mode: 'history',
  hashbag: true,
  routes,
  scrollBehavior: (to, from, savedPosition) => {
    if (to.hash) {
      return {
        selector: to.hash
      }
    }
    if (savedPosition) {
      return savedPosition
    } else {
      if (to.path != from.path) {
        return { x: 0, y: 0 };
      }
    }
  },
})

router.beforeEach((to, from, next) => {
  const hasLang = to.params && to.params.locale;

  let lang = i18n.locale ? i18n.locale.toLowerCase() : 'nl';
  let langCorrect = true;

  if (hasLang) {
    lang = to.params.locale.toLowerCase();
  }

  // Make sure the language is valid
  if (!['en', 'nl'].includes(lang)) {
    langCorrect = false;
    lang = i18n.locale;
  }

  i18n.locale = lang;

  const query = Object.assign({});
  query.level = from.query.level;

  if (!hasLang || !langCorrect) {
    return next({path: `/${lang}${to.fullPath}`, query: { level: from.query.level }});
  }

  return next({query: query});
})

export default router
