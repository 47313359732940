<template>
  <div class="page home">
    <div class="main header">
      <div class="container">
        <default-header />
      </div>
    </div>

    <section class="main-section" ref="main" v-if="data.info">
      <div class="stack">
        <div class="videocontainer">
          <video class="vid" video autobuffer autoplay mute loop v-if="data && data.info && data.info.headervideo">>
            <source id="mp4" :src="data.info.headervideo" type="video/mp4" />
          </video>  
        </div>
        <div class="textcontainer">
          <div class="container">
            <div class="header-intro">
              <h2>{{ data.info.toptitle }}</h2>
              <h1>{{ data.info.title }}</h1>
              <p>
                {{ data.info.subtitle }}
              </p>
              <span class="current-tag">
                {{ $tc("youneed") }}
                <a href="#" @click.prevent="onClickTag">
                  <i
                    :class="`fas fa-graduation-cap`"
                    v-if="currentTag.incompany == '0'"
                  ></i>
                  <i
                    :class="`fas fa-medal`"
                    v-if="currentTag.incompany == '1'"
                  ></i>
                  {{ currentTag.level }}
                </a>
              </span>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section class="filter-with-tabs">
      <!--   <filters @change="onFilter" /> -->
      <div class="container">
        <div class="row tabs-row">
          <div class="col" v-for="(tab, index) in tabs" :key="index">
            <div
              class="tab"
              :class="{ [tab.class]: true, active: activeTab.name == tab.name }"
              @click="tabClicked(tab)"
            >
              {{ tab.name }}
            </div>
          </div>
        </div>
      </div>
    </section>

    <section>
      <div class="container">
        <div class="row" ref="main">
          <div
            class="col-xl-4 col-lg-4"
            v-for="(product, index) in productsCategory"
            :key="index"
          >
            <product-item
              :data="product"
              :color="activeTab.color"
              :productType="activeTab.slug"
            />
          </div>
          <div
            class="col-12"
            v-if="!productsCategory || !productsCategory.length"
          >
            <h1 class="records-not-found">{{ $tc("noresults") }}</h1>
          </div>
        </div>
      </div>
    </section>

    <section class="convinced-section">
      <div class="container">
        <div class="row">
          <div class="col offset-xl-1">
            <convinced-section />
          </div>
        </div>
      </div>
    </section>

    <default-footer />
  </div>
</template>

<script>
import {
  DefaultFooter,
  DefaultHeader,
  // Filters,
  ProductItem,
  ConvincedSection,
} from "@/components";

import { common as commonMixin } from "@/mixins";
import { mapActions, mapState, mapGetters } from "vuex";

export default {
  name: "what-do-i-need",

  components: {
    DefaultFooter,
    DefaultHeader,
    ConvincedSection,
    ProductItem,
    // Filters,
  },

  mounted() {
    const { tab } = this.$route.query;
    if (tab) {
      this.activeTab = this.tabs.find((t) => t.slug == tab);
    } else {
      const defaultTab = this.tabs[1];
      this.$router.replace({
        query: { ...this.$route.query, tab: defaultTab.slug },
      });
      this.activeTab = defaultTab;
    }

    this.getData();

    if (this.$store.state.app.tags != {}) {
      this.autoShowHavoVwo();
    }
  },

  metaInfo() {
    return {
      title: this.data.info.title,
      description: this.data.info.subtitle,
    };
  },

  data() {
    return {
      tabs: [
        {
          name: this.$tc("book"),
          class: "green",
          color: "#40A780",
          slug: "book",
        },
        {
          name: "Gear",
          class: "orange",
          color: "#D16419",
          slug: "gear",
        },
        {
          name: "Software",
          class: "blue",
          color: "#0088CE",
          slug: "software",
        },
      ],
      activeTab: {},
      filter: { module: [], learnline: [] },
    };
  },

  mixins: [commonMixin],

  methods: {
    ...mapActions({ getDataByUrl: "data/getDataByUrl" }),
    ...mapActions(["changeShowHavoVwo", "autoShowHavoVwo"]),

    tabClicked(tab) {
      this.$router.replace({ query: { ...this.$route.query, tab: tab.slug } });
      this.activeTab = tab;
    },

    onFilter(filter) {
      this.filter = filter;
      this.$forceUpdate();
    },

    onClickTag() {
      this.changeShowHavoVwo(true);
    },

    arrayHasAtleastOne(needle, haystack) {
      return needle.some((needle) => haystack.includes(needle));
    },

    getData() {
      let url = "/what-do-i-need/";
      if (this.currentTag.id) {
        url += this.currentTag.id + "/";
      }
      this.getDataByUrl(url);
    },
    headerImage() {
      return "https://tgear.eu/data/images/whatdoineed/imageheader26.jpg";
    },
  },

  i18n: {
    messages: {
      nl: {
        youneed: "Je ziet wat je nodig hebt voor: ",
        noresults: "Geen resulaten voor deze selectie",
        book: "Lesmateriaal",
      },
      en: {
        youneed: "You see what you need for:",
        noresults: "No results for this selection",
        book: "Books",
      },
    },
  },

  watch: {
    "$store.state.app.tags": function () {
      this.autoShowHavoVwo();
    },
    "$store.state.app.currentTag": function () {
      this.getData();
    },
  },

  computed: {
    ...mapGetters(["currentTag", "tags"]),
    ...mapState("data", ["data"]),

    productsCategory: function () {
      if (!this.data[this.activeTab.slug]) {
        return [];
      }

      if (this.filter.module.length == 0 && this.filter.learnline.length == 0) {
        return this.data[this.activeTab.slug];
      }

      let returnArr = [];
      this.data[this.activeTab.slug].forEach((product) => {
        if (
          this.arrayHasAtleastOne(this.filter.module, product.module_id) ||
          this.arrayHasAtleastOne(this.filter.learnline, product.learnline_id)
        ) {
          returnArr.push(product);
        }
      });
      return returnArr;
    },
  },
};
</script>

<style lang="scss" scoped>
.header {
  background: #f4f4f4 0% 0% no-repeat padding-box;
}

.main-section {
  background-image: url("https://tgear.eu/data/images/whatdoineed/imageheader26.jpg");
  background-size: cover;
  background-position: center center;
  background-color: #e9e9e9;
  display: flex;
  min-height: 538px;
  align-items: center;

  h1,
  h2,
  p {
    font-family: "Apercu Pro", "Open Sans", Arial, sans-serif;
  }

  h1 {
    font-size: $big-title-font-size;
    font-weight: bold;
    letter-spacing: -1.12px;
    color: #fdaa20;
    margin: 20px 0;
  }

  h2 {
    font-size: $small-title-font-size;
    letter-spacing: -0.72px;
    color: #e9e9e9;
    font-weight: normal;
  }

  p {
    font-size: $inline-font-size;
    font-weight: normal;
    letter-spacing: 0px;
    color: #e9e9e9;
    margin-bottom: 25px;
  }

  .current-tag {
    color: #bebdbd;
    font-size: $inline-font-size;

    a {
      background-color: #f4f4f4;
      color: #4f4e4e;
      border-radius: 5px;
      padding: 8px 20px;
      margin-left: 15px;

      .fas {
        color: #d16419;
      }
    }
  }
}

.filter-with-tabs {
  padding-top: 2rem;
  background-color: #f4f4f4;

  .tab {
    padding: 10px 20px;
    font-size: 1.2rem;
    font-weight: 300;
    background-color: #e9e9e9;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    cursor: pointer;
    text-align: center;

    &.green {
      color: #40a780;
    }

    &.blue {
      color: #0088ce;
    }

    &.orange {
      color: #d16419;
    }

    &.active {
      background-color: #fff;
    }
  }
}

.convinced-section {
  margin-bottom: 100px;
}

.records-not-found {
  padding: 25px 0;
  font-size: $inline-font-size;
  font-weight: normal;
  color: #4f4e4e;
  text-align: center;
}

@media (max-width: 1024px) {
}

@media (max-width: 768px) {
  .main-section {
    // background: url("/static/images/what-do-i-need-main-mobile.png") no-repeat
    //   top center fixed;
    background-color: #4f4e4e;
    background-size: cover;
    background-position: center center;
    transform: translateY(-54px);
    min-height: calc(100vh);
    display: flex;
  }

  .container {
    padding-left: 30px;
    padding-right: 30px;
  }

  .tabs-row {
    &:first-child {
      margin-left: -30px;
    }

    .tab {
      margin-top: 5px;
    }

    &:last-child {
      margin-right: -30px;
    }
  }

  .main-section {
    h2 {
      font-size: $smallest-title-font-size;
    }

    h1 {
      font-size: $small-title-font-size;
    }

    p {
      font-size: $navigation-font-size;
    }

    .current-tag {
      // font-size: $inline-font-size;
      display: flex;
      flex-direction: column;
      align-items: flex-start;

      a {
        //        font-size: 1rem;
        padding: 5px 15px;
        margin-left: 0;
        margin-top: 5px;
      }
    }
  }

  .filter-with-tabs {
    padding-top: 55px;
    margin-top: -55px;
    .tab {
      font-size: $navigation-font-size;
      padding: 5px 20px;
    }
  }

  .convinced-section {
    margin-bottom: 50px;
  }
}

@media (max-width: 768px) {
  .container {
    .current-tag {
      // font-size: 18px;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
    }
  }
}

@media (max-width: 425px) {
  .tabs-row {
    .col {
      padding: 0;

      &:first-child {
        padding-right: 5px;
      }

      &:last-child {
        padding-left: 5px;
      }
    }
  }

  .filter-with-tabs {
    .tab {
      // font-size: 1rem;
      padding: 5px;
      min-width: 105px;
    }
  }
}

.header-intro {
  margin-left: 100px;
  max-width: 100%;
  @media (max-width: 768px) {
    margin-left: 0;
  }
}
</style>
