import 'core-js/stable';
import Vue from 'vue'
import App from './App.vue'
import router from './router'
import VueGtm from '@gtm-support/vue2-gtm';
import store from './store'
import vSelect from 'vue-select'
import i18n from './i18n'
import {VueMasonryPlugin} from 'vue-masonry';
import VueLazyLoad from 'vue-lazyload'

Vue.use(VueGtm, {
  id: 'GTM-5X9LKZ3', // Your GTM single container ID or array of container ids ['GTM-xxxxxx', 'GTM-yyyyyy'] or array of objects [{id: 'GTM-xxxxxx', queryPararms: { gtm_auth: 'abc123', gtm_preview: 'env-4', gtm_cookies_win: 'x'}}, {id: 'GTM-yyyyyy', queryParams: {gtm_auth: 'abc234', gtm_preview: 'env-5', gtm_cookies_win: 'x'}}]
  enabled: true, // defaults to true. Plugin can be disabled by setting this to false for Ex: enabled: !!GDPR_Cookie (optional)
  debug: true, // Whether or not display console logs debugs (optional)
  loadScript: true, // Whether or not to load the GTM Script (Helpful if you are including GTM manually, but need the dataLayer functionality in your components) (optional)
  vueRouter: router, // Pass the router instance to automatically sync with router (optional)
  trackOnNextTick: false // Whether or not call trackView in Vue.nextTick
});


Vue.use(VueLazyLoad)
Vue.use(VueMasonryPlugin);
Vue.component('v-select', vSelect)

Vue.config.productionTip = false

export default new Vue({
  i18n,
  router,
  store,
  render: h => h(App)
}).$mount('#app')
