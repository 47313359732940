<template>
  <div class="page home">
    <div class="main header">
      <div class="container">
        <default-header />
      </div>
    </div>

    <section class="main-section">
      <div class="stack">
        <div class="videocontainer">
          <video class="vid" video autobuffer autoplay mute loop v-if="data && data.info && data.info.headervideo">>
            <source id="mp4" :src="data.info.headervideo" type="video/mp4" />
          </video>
        </div>
        <div class="textcontainer">
          <div class="container">
            <div class="header-intro">
              <div v-if="data && data.info">
                <h2>{{ data.info.subtitle }}</h2>
                <h1>{{ data.info.title }}</h1>
                <p v-html="data.info.intro"></p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section class="learn-lines-section">
      <div class="container">
        <div class="row">
          <div class="col" v-if="data && data.info">
            <h1>{{ data.info.title2 }}</h1>
            <p v-html="data.info.content"></p>
          </div>
        </div>
        <div class="row mb-4">
          <div
            class="col-xl-4 col-lg-4 item"
            v-for="(item, index) in data.education"
            :key="index"
          >
            <box-item
              :sub-title="item.title"
              title="Leerlijn"
              :image="item.image.image"
              :color="item.color"
              :fgcolor="item.fgcolor"
              :rounded="false"
              :url="item.url"
              :slug="item.slug"
              :pending="item.pending == '1'"
            />
          </div>
        </div>
      </div>
    </section>

    <section class="convinced-section">
      <div class="container">
        <div class="row">
          <div class="col offset-xl-1">
            <convinced-section />
          </div>
        </div>
      </div>
    </section>

    <default-footer />
  </div>
</template>

<script>
import {
  DefaultHeader,
  BoxItem,
  ConvincedSection,
  DefaultFooter,
} from "@/components";

import { common as commonMixin } from "@/mixins";
import { mapActions, mapState, mapGetters } from "vuex";

export default {
  name: "learning-lines-common",

  components: {
    DefaultHeader,
    ConvincedSection,
    BoxItem,
    DefaultFooter,
  },

  metaInfo() {
    return {
      title: this.data.info.title,
      description: this.data.info.intro,
    };
  },

  data() {
    return {};
  },

  created() {
    this.getDataByUrl("/learning-lines/");
  },

  mounted() {},

  mixins: [commonMixin],

  methods: {
    ...mapActions({
      getDataByUrl: "data/getDataByUrl",
    }),
  },

  computed: {
    ...mapGetters(["currentTag", "tags"]),
    ...mapState("data", ["data"]),
    ...mapState(["apiUrl"]),
  },
};
</script>

<style lang="scss" scoped>
.header {
  background: #f4f4f4 0% 0% no-repeat padding-box;
}

.main-section {
  background: url("https://tgear.eu/data/images/learn/imageheader5.jpg")
    no-repeat center center;
  background-size: cover;
  min-height: 539px;

  align-items: center;
  color: #4f4e4e;
  .container {
    display: flex;
  }
  h1,
  h2,
  p {
    font-family: $main-font;
  }

  h1 {
    font-size: $big-title-font-size;
    font-weight: bold;
    letter-spacing: -1.12px;
    color: #fdaa20;
    margin: 20px 0;
  }

  h2 {
    font-size: $small-title-font-size;
    letter-spacing: -0.72px;
    color: #e9e9e9;
    font-weight: normal;
  }

  p {
    font-size: $inline-font-size;
    font-weight: normal;
    letter-spacing: 0px;
    color: #e9e9e9;
    max-width: 428px;
  }
}

.learn-lines-section {
  margin-top: 100px;

  h1,
  p {
    font-family: $main-font;
    color: #4f4e4e;
  }

  h1 {
    font-size: $big-title-font-size;
    font-weight: bold;
  }

  p {
    font-size: $inline-font-size;
    font-weight: normal;
    margin-top: 27px;
    margin-bottom: 50px;
  }
}

.convinced-section {
  padding-bottom: 100px;
}
@media (max-width: 992px) {
  .learn-lines-section {
    .item {
      margin-bottom: 26px;
    }
  }
}

@media (max-width: 768px) {
  .container {
    padding-left: 30px;
    padding-right: 30px;
  }

  .main-section {
    h2 {
      font-size: $smallest-title-font-size;
    }

    h1 {
      font-size: $small-title-font-size;
    }

    p {
      font-size: $navigation-font-size;
    }
  }

  .learn-lines-section {
    margin-top: 65px;

    h1 {
      font-size: $small-title-font-size;
    }
    p {
      font-size: $navigation-font-size;
    }

    .item {
      margin-bottom: 26px;
    }
  }
}
@media (max-height: 500px) {
  .main-section {
    min-height: 100vh;
  }
}

.header-intro {
  margin-left: 100px;
  max-width: 100%;
  @media (max-width: 768px) {
    margin-left: 0;
  }
}
</style>
