<template>
  <div class="page work-book-product">
    <section class="product-info">
      <div class="container">
        <div class="row">
          <div class="col">
            <a href="#" class="back-btn" @click.prevent="$router.go(-1)">
              <i class="fas fa-chevron-left"></i>
              {{ $tc("back") }}
            </a>
            <h1>{{ product.title }}</h1>
            <span class="desc"
              >{{ $tc("learnline") }} {{ product.learnlineName }}</span
            >
          </div>
        </div>
        <div class="row">
          <div class="col-xl-6 col-lg-6">
            <div class="wrapper-slider">
              <slider :slides="product.slides" :minimize="true" />
            </div>
          </div>
          <div class="col-xl-6 col-lg-6">
            <div class="info">
              <div>
                <span class="price">€ {{ product.price }}</span>
              </div>
              <div class="mb-3 d-flex flex-column align-items-start action">
                <p>{{ $tc("convinced") }}</p>
                <router-link :to="{ name: 'contact' }" class="download">{{
                  $tc("convinced_btn")
                }}</router-link>
              </div>
              <div class="hr"></div>
              <div class="description">
                <h2>{{ $tc("description") }}</h2>
                <p v-html="product.description" />
                <div class="d-flex">
                  <a :href="product.chapter_download" class="ml-auto btn-more">
                    {{ $tc("chapter") }}
                    <i class="fas fa-chevron-right"></i>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="row">
          <fancy-blocks class="col" :content="product.content" />
        </div>
      </div>
    </section>

    <section class="section specifications">
      <div class="container">
        <div class="row">
          <div class="col">
            <div class="wrapper-specifications">
              <section class="section systems">
                <div class="row">
                  <div class="col">
                    <h1 class="section-title">{{ $tc("specifications") }}</h1>
                  </div>
                </div>
                <div class="row">
                  <div class="col-12">
                    <div class="systems responsive-table">
                      <p v-html="product.specs" />
                    </div>
                  </div>
                </div>
              </section>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import { Slider /*SpecificationsProduct*/ } from "@/components";

import FancyBlocks from "@/components/FancyBlocks";

import { mapActions } from "vuex";
export default {
  name: "work-book-product",

  components: {
    Slider,
    "fancy-blocks": FancyBlocks,
    //SpecificationsProduct,
  },

  i18n: {
    messages: {
      nl: {
        convinced: "Overtuigd? Neem contact op:",
        convinced_btn: "Neem contact op",
        chapter: "lees een hoofdstuk",
      },
      en: {
        convinced: "Convinced? Contact us:",
        convinced_btn: "Contact us",
        chapter: "read a chapter",
      },
    },
  },

  metaInfo() {
    return {
      title: this.product.title,
      description: this.product.intro,
      meta: [
        // Twitter Card
        { name: "twitter:card", content: "summary" },
        { name: "twitter:title", content: this.product.title },
        { name: "twitter:description", content: this.product.intro },
        // image must be an absolute path
        { name: "twitter:image", content: this.product.image },
        // Facebook OpenGraph
        { property: "og:title", content: this.product.title },
        { property: "og:site_name", content: "T Gear" },
        { property: "og:type", content: "website" },
        { property: "og:image", content: this.product.image },
        { property: "og:description", content: this.product.intro },
      ],
    };
  },

  data() {
    return {};
  },

  props: {
    product: {
      type: Object,
      default: () => {},
    },
  },

  methods: {
    ...mapActions({
      getDataByUrl: "data/getDataByUrl",
    }),
  },
};
</script>

<style lang="scss" scoped>
.work-book-product {
  .product-info {
    .back-btn {
      padding: 37px 0;
      color: #bebdbd;
      font-size: 1rem;
      font-weight: 300;
      display: inline-block;

      .fas {
        margin-right: 5px;
      }
    }

    h1 {
      color: #40a780;
      font-size: $big-title-font-size;
    }

    .desc {
      color: #4f4e4e;
      font-size: $inline-font-size;
      display: inline-block;
      margin-bottom: 39px;
    }

    p {
      line-height: $inline-line-height;
      font-size: $inline-font-size;
      color: #4f4e4e;
      margin-bottom: 20px;
    }

    h2 {
      color: #4f4e4e;
      font-size: $small-title-font-size;
      font-weight: 400;
      margin-bottom: 15px;
      margin-top: 25px;
    }

    .download {
      background-color: #40a780;
      font-size: $inline-font-size;
      color: #fff;
      padding: 5px 27px;
      border-radius: 0.25rem;
      box-shadow: 0px 2px 2px #0000003d;
    }

    .price {
      color: #0088ce;
      font-size: $small-title-font-size;
    }

    .hr {
      border-bottom: 1px solid #bebdbd61;
    }

    .btn-more {
      color: #0088ce;
      font-size: $inline-font-size;
    }
  }

  .specifications {
    margin-bottom: 150px;
  }

  @media (max-width: 768px) {
    .container {
      padding-left: 30px;
      padding-right: 30px;
    }

    .product-info {
      .back-btn {
        padding: 15px 0;
      }

      h1 {
        font-size: $small-title-font-size;
        margin-bottom: 15px;
      }

      .desc {
        font-size: $navigation-font-size;
      }

      .price {
        font-size: $inline-font-size;
        text-align: center;
        display: inline-block;
        width: 100%;
        margin: 15px 0;
      }

      .download {
        margin: 0 auto;
        display: inline-block;
      }

      .btn-more {
        font-size: $navigation-font-size;
      }
    }

    .specifications {
      margin-bottom: 0px;
    }
  }
}

.wrapper-specifications {
  .section-title {
    font-size: $small-title-font-size;
    color: #4f4e4e;
    margin-top: 50px;
    margin-bottom: 15px;
  }

  .systems {
    display: flex;
    flex-direction: column;

    display: flex;
    line-height: $inline-line-height;
    color: #4f4e4e;
    font-size: $inline-font-size;
    line-height: $inline-line-height;
  }
}

@media (max-width: 768px) {
  .wrapper-specifications {
    .section-title {
      font-size: $small-title-font-size;
    }

    .systems {
      margin-bottom: 50px;
      margin-top: 0;

      line-height: 1.5rem;
      // font-size: 1rem;
    }
  }
}
</style>
