<template>
  <div class="locale-changer">
    <span v-for="(lang, i) in langs" :key="`Lang${i}`">
      <a
        v-if="$root.$i18n.locale != lang.lang"
        @click.prevent="switchLocale(lang.lang)"
        class="lang-nav-btn"
        >{{ lang.name }}</a
      ></span
    >
  </div>
</template>

<script>
export default {
  name: "locale-changer",
  data() {
    return {
      langs: [
        {
          lang: "nl",
          name: "NL",
        },
        {
          lang: "en",
          name: "EN",
        },
      ],
    };
  },
  methods: {
    switchLocale(locale) {
      if (this.$i18n.locale !== locale) {
        let query = this.$route.query;
        this.$i18n.locale = locale;
        const to = this.$router.resolve({ params: { locale }, query: query });
        this.$router.push(to.location);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.lang-nav-btn {
  font-size: 1rem;
  color: #bebdbd;
  font-weight: bold;
  text-transform: uppercase;
  cursor: pointer;
  transition: all 0.3s linear;
}

.lang-nav-btn:hover {
  color: #222221;
}
</style>
