<template>
  <div class="textarea">
    <div
      class="textarea-wrapper"
      :class="{ value: payload, invalid: !localValid }"
    >
      <span
        v-if="properties.label"
        class="label"
        :class="{ active: active || payload, value: payload }"
      >
        {{ properties.label[lang] }}
        <span v-if="properties.required">*</span>
      </span>
      <textarea
        type="text"
        v-model="payload"
        @focus="onFocus"
        @blur="onBlur"
        :placeholder="properties.placeholder[lang]"
        :name="id"
      ></textarea>
    </div>
  </div>
</template>

<script>
export default {
  name: "input-field",

  props: {
    id: {
      type: String,
    },
    properties: {
      type: Object,
    },
    value: {
      type: Boolean,
      default: true,
    },
  },

  data() {
    return {
      payload: "",
      active: false,
      localValid: true,
    };
  },

  computed: {
    lang() {
      if (this.$i18n.locale == "nl") {
        return "nl";
      }
      return "gb";
    },
  },

  watch: {
    "properties.validate": function () {
      this.validate();
    },
  },

  methods: {
    onFocus() {
      this.active = true;
    },

    onBlur() {
      this.active = false;
    },

    validate() {
      if (this.properties.required && this.payload == "") {
        this.$emit("input", false);
        this.localValid = false;
        return;
      }
      this.$emit("input", true);
      this.localValid = true;
    },
  },
};
</script>

<style lang="scss" scoped>
.textarea {
  padding-top: 30px;
}

.textarea-wrapper {
  position: relative;
  height: 100%;
  background-color: #e9e9e9;
  z-index: 1;
  border-bottom: 3px solid #bebdbd;
  min-height: 333px;

  &.invalid {
    border-color: #ff1a00;
  }

  &.value {
    border-color: #fdaa29;
    background-color: #fffbf4;

    textarea {
      color: #4f4e4e;
    }
  }

  textarea {
    z-index: 2;
    width: 100%;
    border: none;
    background-color: transparent;
    color: #bebdbd;
    font-size: $inline-font-size;
    border-radius: 0;
    padding: 13px 20px;
    resize: none;
    height: 100%;

    &:focus {
      outline: none;
    }
  }

  .label {
    z-index: -1;
    position: absolute;
    top: 15px;
    left: 15px;
    color: #bebdbd;
    font-size: $inline-font-size;
    transition: 0.3s all;

    &.active {
      top: -25px;
      font-size: 1rem;
    }

    &.value {
      color: #40a780;
    }
  }
}

@media (max-width: 768px) {
  .textarea-wrapper {
    textarea,
  //  .label {
      // font-size: 18px;
   // }

    textarea {
      min-height: 176px;
    }
  }
}
</style>
